// src/components/CustomXAxisTick.tsx
import { format } from 'date-fns';
import React from 'react';

interface CustomXAxisTickProps {
  x: number; 
  y: number; 
  payload: {
    value: string;
    index: number 
  };
  highlightedDate: string | null; 
  isMonth: boolean
}

const CustomXAxisTick: React.FC<CustomXAxisTickProps> = ({ x, y, payload, highlightedDate, isMonth }) => {
  const isActive = highlightedDate === payload.value;
  
  const displayValue = isMonth 
  ? `T ${format(payload.value, 'MM')}` 
  : format(payload.value, 'dd/MM');  

  return (
    <text 
      x={x - 20} // đặt chính giữa cột
      y={y} 
      fill={isActive ? '#06BD7D' : '#8B929C'} 
    >
      {displayValue}
    </text>
  );
};

export default CustomXAxisTick;
