import React, { useEffect, useRef } from 'react';

interface IconInputProps {
  placeholder: string;
  icon: JSX.Element;
  value: string;
  type: string;
  unit?: string | React.ReactNode;
  error?: string;
  name: string;
  maxLength?: number;
  readOnly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onClick?: () => void;
}

const IconInput: React.FC<IconInputProps> = ({
  placeholder,
  icon,
  value,
  name,
  type,
  unit,
  error,
  maxLength,
  onChange,
  onClick,
  onBlur,
  readOnly,
  onKeyDown
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleFocus = () => {
      if (inputRef.current) {
        setTimeout(() => {
          inputRef.current?.scrollIntoView({ 
            behavior: 'smooth', 
            block: 'center' 
          });
        }, 100);
      }
    };

    const currentInput = inputRef.current;
    currentInput?.addEventListener('focus', handleFocus);

    return () => {
      currentInput?.removeEventListener('focus', handleFocus);
    };
  }, []);

  const isNumericType = type === 'number';

  return (
    <div className="relative" onClick={onClick}>
      <input
        ref={inputRef}
        className={`peer py-4 mb-4 pl-11 w-full rounded-2xl focus:outline-none text-base font-bold
          placeholder:font-normal bg-emerald-50
          ${error 
            ? 'border border-red-600 focus:ring-red-600' 
            : 'focus:ring-1 focus:ring-green-500'
          }`}
        type={type}
        inputMode={isNumericType ? 'numeric' : undefined} // Đảm bảo bàn phím số trên thiết bị di động
        pattern={isNumericType ? '\\d*' : undefined} // Giới hạn nhập liệu chỉ là số nếu là kiểu 'number'
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        name={name}
        maxLength={maxLength}
        readOnly={readOnly}
        onWheel={(e) => e.currentTarget.blur()} // Ngăn việc cuộn trang khi nhập số trên Safari
      />
      <span className="absolute top-3.5 right-4 text-base font-semibold leading-6">
        {unit}
      </span>
      {icon}
      {error && (
        <p className="text-red600 text-sm pb-4 font-medium leading-5">
          {error}
        </p>
      )}
    </div>
  );
};

export default IconInput;
