import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TabsChild, TabChild } from "../TabChild/TabChild";
import { Download, Search } from "../../assets/Icons";
import ListStatement from "./ListStatement";
import { formatDateReport, generateRefNo, openBrowser } from "../../appCommon";
import { DateProps } from "../../types/Transaction";
import { useDispatch, useSelector } from "react-redux";
import { listTransactionSelector } from "../../redux/selector/ListTransactionSelector/ListTransaction";
import {
  getListTransaction,
  getListTransactionReset,
} from "../../redux/actions/ListTransactionActions/ListTransaction";
import LoadingPage from "../Loading/Loading";
import { LoadingSelector } from "../../redux/selector/LoadingSelector";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import {
  DEFAULT_LIMIT,
  EXTRACTION,
  TAB_ALL,
  TAB_TRANSACTION,
  TODAY,
} from "../../appConstants";
import { exportTranscation } from "../../api/Transaction";
import { getListCustomerReset, getListStatementCustomer } from "../../redux/actions/CustomerAction/CustomerAction";
import { listCustomerLoading, listStatementCustomer } from "../../redux/selector/CustomerSelector/Customer";

interface PropsStatementPage {
  contractBank: string;
  contractAccountNbr: string;
  contractCustomerName: string;
  isHidden: boolean;
  scrollContainerRef: React.RefObject<HTMLElement>
}

const StatementPageComponent: React.FC<PropsStatementPage> = ({
  contractBank,
  contractAccountNbr,
  contractCustomerName,
  isHidden,
  scrollContainerRef
}) => {
  const [type, setType] = useState<string>(TAB_ALL);
  const [selectDate, setSelectDate] = useState<string>("");
  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const searchInputRef = useRef(searchInput);
  const isFirstLoad = useRef(true);
  const dispatch = useDispatch();
  const dataListTransaction = useSelector(listTransactionSelector) || {};
  const lisDataStatementCustomer = useSelector(listStatementCustomer);
  const isLoading = useSelector(LoadingSelector);
  const isLoadingCustomer = useSelector(listCustomerLoading);
  const informationMerchant = JSON.parse(
    sessionStorage.getItem("informationMerchant") ?? ""
  );

  useEffect(() => {
    searchInputRef.current = searchInput;
  }, [searchInput]);

  const transactionData = useMemo(() => {
    return isHidden ? lisDataStatementCustomer : dataListTransaction;
  }, [isHidden, dataListTransaction, lisDataStatementCustomer]);

  const loadingPage = useMemo(() => {
    return isHidden ? isLoadingCustomer : isLoading;
  }, [isHidden, isLoading, isLoadingCustomer]);

  const fetchCustomerTransaction = useCallback(
    (isNewPage: boolean = false) => {
      const fromToDate: DateProps = formatDateReport(selectDate, TODAY);

      const searchObject = {
        customerAccount: informationMerchant?.merchantAccountNbr,
        fromDate: fromToDate.fromDate,
        toDate: fromToDate.toDate,
        skip: page,
        limit: DEFAULT_LIMIT,
        transactionType: type,
        extraction: EXTRACTION.YES.name,
        searchInput: searchInputRef.current,
        ...(isHidden && {
          contractBank,
          contractAccountNbr,
          contractCustomerName,
        }),
      };
      if (isHidden) {
        dispatch(getListStatementCustomer(searchObject));
      } else {
        dispatch(getListTransaction(searchObject, isNewPage));
      }
    },
    [selectDate, type, page, isHidden]
  );

  useInfiniteScroll({
    isLoading,
    onLoadMore: () => setPage((prevPage) => prevPage + DEFAULT_LIMIT - 5),
    offset: 100,
    containerRef: scrollContainerRef
  });

  useEffect(() => {
    if (isFirstLoad.current) {
      dispatch(getListTransactionReset());
      dispatch(getListCustomerReset());
      fetchCustomerTransaction(false);
      isFirstLoad.current = false;
    } else {
      // Reset data when changing tabs or date
      setPage(1);
      fetchCustomerTransaction(true);
    }
  }, [selectDate, type]);

  useEffect(() => {
    if (!isFirstLoad.current && page > 1) {
      fetchCustomerTransaction(true);
    }
  }, [page]);

  const handleExportExcel = async () => {
    const fromToDate: DateProps = formatDateReport(selectDate, TODAY);
 
    const searchObject = {
      customerAccount: informationMerchant?.merchantAccountNbr,
      fromDate: fromToDate.fromDate,
      toDate: fromToDate.toDate,
      skip: 1,
      limit: DEFAULT_LIMIT - 5,
      transactionType: type,
      extraction: EXTRACTION.YES.name,
      ...(isHidden && {
        contractBank,
        contractAccountNbr,
        contractCustomerName,
      }),
    };

    const { config } = await exportTranscation(searchObject);
    const stringifiedSearchObject = Object.fromEntries(
      Object.entries(searchObject).map(([key, value]) => [key, String(value)])
    );
    
    const token = typeof config?.headers?.Authorization === 'string'
      ? config?.headers?.Authorization.split(' ')[1]
      : undefined;

    const refNo = generateRefNo();
    
    const url = `${config.url}?${new URLSearchParams(stringifiedSearchObject).toString()}&token=${token}&refNo=${refNo}`;

    openBrowser(url);  

  };

  const handleTabs = (value: number) => {
    setSelectDate(TODAY);
    isFirstLoad.current = true;
    switch (value) {
      case 0:
        setType(TAB_TRANSACTION.ALL.name);
        break;
      case 1:
        setType(TAB_TRANSACTION.INCOME.name);
        break;
      case 2:
        setType(TAB_TRANSACTION.EXPRNDITURE.name);
        break;
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchInput(value);
    setPage(1);

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    const delay = value === "" ? 300 : 800;
    debounceTimeoutRef.current = setTimeout(() => {
      fetchCustomerTransaction();
    }, delay);
  };

  const handleSelect = (value: string) => {
    setSelectDate(value);
    setPage(1);
  };

  return (
    <div>
      <div className="sticky top-[77px] z-30 bg-white">
        <div className="relative px-3">
          <input
            type="text"
            placeholder="Tìm kiếm giao dịch"
            value={searchInput}
            onChange={handleSearchChange}
            className="px-4 py-3 bg-green200 w-full rounded-2xl pl-10 focus:outline-none"
          />
          <Search className="absolute top-3 left-6" />
        </div>
      </div>
      <div className="relative">
        <TabsChild onTabClick={handleTabs}>
          <TabChild label={TAB_TRANSACTION.ALL.lable}>
            <ListStatement
              transactions={transactionData}
              onSelectValue={handleSelect}
            />
          </TabChild>
          <TabChild label={TAB_TRANSACTION.INCOME.lable}>
            <ListStatement
              transactions={transactionData}
              onSelectValue={handleSelect}
            />
          </TabChild>
          <TabChild label={TAB_TRANSACTION.EXPRNDITURE.lable}>
            <ListStatement
              transactions={transactionData}
              onSelectValue={handleSelect}
            />
          </TabChild>
        </TabsChild>
        <div
          className={`fixed right-2 z-30 flex justify-end items-center text-green500 px-3 mb-6 
            ${isHidden ? 'top-48' : 'top-36'}`}
          onClick={handleExportExcel}
        >
          <Download /> Tải excel
        </div>
      </div>
      <LoadingPage isLoading={loadingPage} />
    </div>
  );
};

export default StatementPageComponent;
