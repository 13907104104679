import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Features from "./Features";
import {
  Notification,
  Setting,
  Person,
  ArrowUp,
  ArrowDown,
  Receipt,
} from "../../assets/Icons/index";
import { convertNumber } from "../../appCommon";
import PromotionProgram from "./PromotionProgram";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import CustomDropdown from "../../components/FilterSelect/FilterSelect";
import { DEFAULT_TIME, FILTER_SELECT } from "../../appConstants";
import { GET_HOME_PAGE, getHomePage } from "../../redux/actions/HomePageActions/HomePage";
import { HomePageSelector, HomePagLoading } from "../../redux/selector/HomePageSelector/HomePage";
import HomeLoader from "./HomeLoader";
import ScrollToTop from "../../components/ScrollPage/ScrollTop";
import { getInformationMerchant } from "../../redux/actions/StoreSetupActions/StoreSetup";
import { dataInformationMerchantSelector } from "../../redux/selector/StoreSetupSelector/StoreSetup";

const HomePage: React.FC = () => {
  const dataHomePage = useSelector(HomePageSelector);
  const [timeSelector, setTimeSelector] = useState(DEFAULT_TIME);
  const dataMerchant = useSelector(dataInformationMerchantSelector); 
  const naviage = useNavigate();
  const dispatch = useDispatch();
  
  const fetchHomePage = useCallback(() => {
    dispatch({type: GET_HOME_PAGE});
    
    if (!dataHomePage || timeSelector) {
      const selectedItem = FILTER_SELECT.find(item => item?.name === timeSelector)?.unti || DEFAULT_TIME;

      const searchObject = {
        period: selectedItem
      };
      
      dispatch(getHomePage(searchObject));
    }
  }, [timeSelector, dataHomePage, dispatch]);
  
  useEffect(() => {
     fetchHomePage(); 
    }, [timeSelector]);  
    
  useEffect(() => {
    dispatch(getInformationMerchant());
  }, [])

  if (!dataHomePage) return <HomeLoader />;

  return (
    <div className="my-6 not-italic overflow-x-hidden">
      <ScrollToTop/>
      <header className="flex items-center justify-between mb-5 mx-6">
        <div className="not-italic font-normal">
          <p className="text-xs uppercase w-[250px] text-gray500 leading-4 break-words">
            {dataMerchant?.merchantName}
          </p>
          <p className="text-lg font-bold w-[250px] leading-7 break-words">
            {dataMerchant?.merchantName}
          </p>
        </div>
        <div className="flex items-center">
          <button className="mr-2.5">
            <Notification />
          </button>
          <button onClick={() => naviage("/store-setup")}>
            <Setting />
          </button>
        </div>
      </header>
      <main>
        <section className="mx-6 bg-green200 rounded-2xl p-5">
          <div className="border-b border-gray100 pb-4 flex justify-between">
            <div className="flex flex-col">
              <span className="text-sm text-gray500 mb-1">Tổng doanh thu</span>
              <span className="flex text-2xl font-bold">
                <span className="text-sm pr-1">đ</span>
                {convertNumber(dataHomePage?.merchantRevenue) || 0}
              </span>
            </div>
            <div className="">
              <CustomDropdown
                valueSelect={(value: string) => setTimeSelector(value)}
                minDay={false}
                isSelectTime={false}
                filterOption={FILTER_SELECT}
              />
            </div>
          </div>
          <div className="flex justify-between pt-4">
            <div>
              <span className="text-xs">Tổng chi phí</span>
              <div className="flex">
                <ArrowUp />
                <span className="flex text-base font-bold pl-1.5 text-[#EB2D4B]">
                  <span className="text-xs pr-0.5">đ</span>
                  {convertNumber(dataHomePage?.merchantExpense) || 0}
                </span>
              </div>
            </div>
            <div>
              <span className="text-xs">Tổng lợi nhuận</span>
              <div className="flex">
                <ArrowDown />
                <span className="flex text-base font-bold pl-1.5 text-[#06BD7D]">
                  <span className="text-xs pr-0.5">đ</span>
                  {convertNumber(dataHomePage?.merchantProfit) || 0}
                </span>
              </div>
            </div>
          </div>
        </section>
        <section className="flex mx-6 justify-between mt-4">
          <div className="flex flex-col bg-green200 rounded-2xl w-[50%] px-5 py-4">
            <span className="text-sm leading-5 text-gray500 pb-1">
              Số giao dịch
            </span>
            <div className="flex">
              <Receipt className="w-5 h-5 mr-1" />
              <span className="text-base font-bold leading-6">
                {convertNumber(dataHomePage?.merchantOrderNbr) || 0} đơn
              </span>
            </div>
          </div>
          <div className="flex flex-col bg-green200 rounded-2xl w-[50%] px-5 py-4 ml-3">
            <span className="text-sm leading-5 text-gray500 pb-1">
              Số khách hàng
            </span>
            <div className="flex">
              <Person className="w-5 h-5 mr-1" />
              <span className="text-base font-bold leading-6">
                {convertNumber(dataHomePage?.merchantCustomerNbr) || 0} khách
              </span>
            </div>
          </div>
        </section>
        <section className="mt-6 mx-6">
          <Features />
        </section>
        <section className="mt-6 mb-24">
          <PromotionProgram />
        </section>
      </main>
      <footer className="mt-10">
        <Footer />
      </footer>
    </div>
  );
};

export default HomePage;
