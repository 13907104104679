// import axios from "axios";
import { generateRefNo } from "../appCommon";
import { QuickReportSearchObject } from "../types/HomeTypes";
import api from "./AxiosInterceptor";
const config = require("../appConfig");
const patchApi = config.API_ENPOINT + "/report/v1.0";

export const getQuickReportApi = async (searchObject: QuickReportSearchObject) => {  
    const refNo = generateRefNo();
    const searchObjectRefNo = { ...searchObject, refNo }; 

    const url = `${patchApi}/quick-report`;
    if (searchObject) {
        const response = await api.get(url, {params: searchObjectRefNo});        
        return response?.data; 
    }
}
