import { format, isSameDay, parse } from "date-fns";
import { VALUE_UNKNOWN } from "../appConstants";

export const formatDate = (date: {
  year: string;
  month: string;
  day: string;
}): string => {
  const { year, month, day } = date;
  const dateObj = new Date(Number(year), Number(month) - 1, Number(day)); // month trong JS bắt đầu từ 0
  return format(dateObj, "dd/MM/yyyy");
};

export const formatDateAM = (value: string) => {
  const date = parse(value, "dd/MM/yyyy HH:mm:ss", new Date());
  if (isNaN(date.getTime())) {
    return VALUE_UNKNOWN;
  }

  const today = new Date();
  if (isSameDay(date, today)) {
    return format(date, "HH:mm a");
  } else {
    return format(date, "HH:mm dd/MM/yyyy");
  }
};

export const formatDateParams = (value: Date) => {
  return format(value, "yyyy-MM-dd");
};

export const formatDateTime = (value: string) => {
  const date = new Date(value);
  if (isNaN(date.getTime())) {
    return "Invalid Date"; // hoặc trả về một chuỗi mặc định
  }
  return format(date, "HH:mm dd/MM/yyyy");
};

export const formatTransactionTime = (txnTime: string): string => {
  try {
    // Parse the date string from DD/MM/YYYY HH:mm:ss
    const [datePart, timePart] = txnTime.split(' ');
    const [day, month, year] = datePart.split('/');
    const [hours, minutes] = timePart.split(':');
    
    // Pad numbers with leading zeros if needed
    const formattedHours = hours.padStart(2, '0');
    const formattedMinutes = minutes.padStart(2, '0');
    const formattedDay = day.padStart(2, '0');
    const formattedMonth = month.padStart(2, '0');
    
    // Return formatted string
    return `${formattedHours}:${formattedMinutes} ${formattedDay}/${formattedMonth}/${year}`;
  } catch (error) {
    console.error('Error formatting transaction time:', error);
    return ''; // Return empty string or some default value in case of error
  }
}

