import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  LeftBlack,
  BorderEllipe,
  BackGroundEllipe,
  Start22,
  Start23,
  Start24,
  Start25,
  Check,
  Copy,
  Clock,
} from "../../assets/Icons";
import { toast } from "react-toastify";
import { getBankTitle } from "../../components/Mapping/MappingBankName/MappingBankName";
import { formatTime, truncateNote } from "../../appCommon";
import { useGlobalState } from "../../components/GlobalState/GlobalState";

const DetailTransactionPage: React.FC = () => {
  const { getState, setState } = useGlobalState();
  const navigate = useNavigate();
  const location = useLocation();

  const hasNavigated = useRef(false);
  const isCustomer: boolean = true;
  useEffect(() => {
    if (window.history.state && window.history.state.idx > 0) {
      hasNavigated.current = true; // Người dùng đến từ trang khác
    }
  }, []);
  const { 
    statementId, 
    name, 
    stk, 
    bankName, 
    content, 
    sign, 
    money, 
    time, 
    isHidden
  } = location.state || {};
  
  const copyTextToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(statementId);
      toast.success("Sao chép thành công")
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };
  
  const handleBack = () => {
    // Luôn truyền state khi navigate, dùng replace nếu đến từ history
    setState("resetPage", true)
    navigate("/transaction", {
      state: { 
        resetPage: false ,
          isHidden: isHidden,
          contractBank: bankName,
          contractAccountNbr: stk,
          contractCustomerName: name
      },
      replace: hasNavigated.current
    });
  };

  // Kiểm tra nếu không có state được truyền vào
  useEffect(() => {
    if (!location.state) {
      navigate("/transaction", { replace: true });
    }
  }, [location.state, navigate]);
  
  return (
    <>
      <div className="bg-gary25 ">
        <header className="flex justify-start items-center p-4 mb-8 pt-8">
          <div
            className="px-2 flex justify-center items-center "
            onClick={handleBack}
          >
            <LeftBlack />
          </div>
          <div className="text-xl font-extrabold flex justify-center content-center text-center w-full mr-9">
            Chi tiết giao dịch
          </div> 
        </header>
        <main className="w-full px-4 mt-12 pb-5">
          <div className="relative w-full shadow-detail rounded-bl-[32px] rounded-br-[32px] bg-white px-4 pt-[60px] pb-6">
            <div className="absolute top-[1.7%] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <div className="relative">
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <BorderEllipe />
                  <BackGroundEllipe className="absolute top-[-1.5px] left-[-4.5px] opacity-75" />
                  <Check className="absolute top-[25px] left-[25px] opacity-50" />
                </div>
                <div className="absolute top-[20px] left-[45px]">
                  <Start22 />
                </div>
                <div className="absolute top-[25px] right-[41px]">
                  <Start23 />
                </div>
                <div className="absolute bottom-[20px] right-[45px]">
                  <Start24 />
                </div>
                <div className="absolute bottom-[23px] left-[50px]">
                  <Start25 />
                </div>
              </div>
            </div>
            <div className="mb-6">
              <div
                className="flex justify-center items-center text-gray600"
                onClick={copyTextToClipboard}
              >
                # Mã FT: {statementId} <Copy />
              </div>
              <div className="flex justify-center items-center gap-2">
                <p className="text-[40px] font-extrabold ">
                  {sign === "D" ? "-" : "+"}
                  {money}
                </p>
                <p className="text-xl font-extrabold ">đ</p>
              </div>
              <div className="flex justify-center items-center gap-2 text-gray600">
                <Clock /> {formatTime(time)}
              </div>
              <div className="pt-3.5 mx-2 border-b border-gray100"></div>
            </div>
            <div className="mb-3.5">
              <div className="flex justify-between items-start pb-4 mb-4 border-b border-gray100">
                <p className="text-base text-gray">Chủ tài khoản</p>
                <p
                  className={`text-base font-semibold ${
                    sign !== "D" ? "text-green500" : "text-[#15141F]"
                  }`}
                >
                  {name}
                </p>
              </div>
              <div className="flex justify-between items-start pb-4 mb-4 border-b border-gray100">
                <p className="text-base text-gray">Số tài khoản</p>
                <p className="text-base font-semibold text-[#15141F]">{stk}</p>
              </div>
              <div className="flex justify-between items-start pb-4 mb-4 border-b border-gray100">
                <p className="text-base text-gray">Ngân hàng</p>
                <p className="text-base font-semibold text-[#15141F]">
                   {getBankTitle(bankName, "vi")}
                </p>
              </div>
              <div className="flex justify-between items-start pb-4 mb-4">
                <p className="text-base text-gray">Nội dung</p>
                <p className="max-w-[200px] text-base font-semibold text-[#15141F] line-clamp-2 text-end">
                  {truncateNote(content)}
                </p>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default DetailTransactionPage;
