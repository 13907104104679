import { takeEvery, call, put } from "redux-saga/effects";
import {
  GET_LIST_TRANSACTION,
  GET_LIST_TRANSACTION_NEWEST,
  getListTransactionNewestSuccess,
  getListTransactionSuccess,
} from "../../actions/ListTransactionActions/ListTransaction";
import { getListTranscation, getListTranscationNewest } from "../../../api/Transaction";
import { setLoading } from "../../actions/LoadingAction";
import { ResponseData } from "../../../types/Transaction";

function* fetchListTransaction(action: any) {
  try {
    yield put(setLoading(true));
    const apiToCall = 
    action.type === GET_LIST_TRANSACTION_NEWEST 
      ? getListTranscationNewest 
      : getListTranscation;
    const res: ResponseData = yield call(apiToCall, action.payload);
    
    if (res?.data?.length === 0 && action.isNewPage) {
      return;
    }
    if (action.type === GET_LIST_TRANSACTION) {
      yield put(getListTransactionSuccess(res?.data, action.isNewPage, action.selectDate));
    } else {
      yield put(getListTransactionNewestSuccess(res?.data, action.isNewPage, action.selectDate));
    }
    
  } catch (error) {
    yield put(setLoading(false));
  } finally {
    yield put(setLoading(false));
  }
}

export default function* ListTransactionSaga() {
  yield takeEvery(GET_LIST_TRANSACTION, fetchListTransaction);
  yield takeEvery(GET_LIST_TRANSACTION_NEWEST, fetchListTransaction);
}