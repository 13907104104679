import { ErrorMessage, StoreInfo } from "../../types/StoreInfor";

  
  export const validateStoreInfo = (
    dataForm: StoreInfo,
    setErrorMessage: React.Dispatch<React.SetStateAction<ErrorMessage>>
  ) => {
  
    const errorState: ErrorMessage = {
      errorStoreName: "",
      errorStoreAddress: "",
      errorStorePhone: "",
    };
  
    let hasError = false; 
  
    if (!dataForm.name) {
      errorState.errorStoreName = "Tên cửa hàng là giá trị bắt buộc";
      hasError = true; 
    }
  
    if (!dataForm.address) {
      errorState.errorStoreAddress = "Địa chỉ là giá trị bắt buộc";
      hasError = true; 
    }
  
    if (!dataForm.phone) {
      errorState.errorStorePhone = "Số điện thoại là giá trị bắt buộc";
      hasError = true; 
    } else if (!/^[0-9]+$/.test(dataForm.phone)) {
      errorState.errorStorePhone = "Số điện thoại chỉ được chứa các chữ số";
      hasError = true;
    }  else if (!/^(0\d{9,11})$/.test(dataForm.phone)) {
      errorState.errorStorePhone = "Số điện thoại bắt đầu bằng 0 có tối thiểu 9 và đa 12 ký tự";
      hasError = true; 
    }
  
    // Cập nhật trạng thái lỗi nếu có lỗi
    setErrorMessage(errorState);
  
    return hasError;
  };