import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface MenuItemProps {
  path: string;
  label: string;
  icon: React.ReactNode;
}

const MenuItem: React.FC<MenuItemProps> = ({ path, label, icon }) => {
  const location = useLocation();
  
  // Kiểm tra xem mục menu có đang được chọn hay không
  const isActive = location.pathname === path;
  
  return (
    <li className={`peer flex flex-col items-center ${isActive ? 'text-green500' : 'text-gary200'}`}>
      <Link to={path} className="flex flex-col items-center">
        <div className="icon">
          {React.cloneElement(icon as React.ReactElement, { isActive })}
        </div>
        <span className={`text-xs font-semibold leading-5 pt-0.5 ${isActive ? 'text-green500' : 'text-gary200'}`}>
          {label}
        </span>
      </Link>
    </li>
  );
};

export default MenuItem;
