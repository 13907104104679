import { generateRefNo } from "../appCommon";
import api from "./AxiosInterceptor";
const config = require("../appConfig");
const patchApi = config.API_ENPOINT + "/customer/v1.0";

export const updateInforCustomerApi = async (searchObject: any) => {  
    const refNo = generateRefNo();
    const searchObjectRefNo = { ...searchObject, refNo }; 

    const url = patchApi + "/update";
    const response = await api.put(url, searchObjectRefNo);       

    return response.data; 

}