import React from 'react';
import { ErrorLaptop } from "../../assets/Images/index";

const SessionExpiration : React.FC = () => {

    const handleLogout = () => {
        window?.ReactNativeWebView?.postMessage(
            JSON.stringify({
                type: "GO_BACK",
            })
        );
    };

  return (
    <div className='px-6 py-8 not-italic flex flex-col justify-center'>
        <div className='relative'>
            <img src={ErrorLaptop} alt="" />
            <div className='flex flex-col justify-center text-center absolute top-[90%]'>
                <div className=''>
                    <h3 className='text-2xl font-extrabold leading-8 pb-1'>
                        Phiên đăng nhập đã hết hạn
                    </h3>
                    <p className='text-base font-medium leading-6 text-gray500 px-12'>
                        Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại
                    </p>
                </div>    
                <button 
                    className='mt-8 py-3.5 text-center bg-green500 text-white w-full text-base font-bold rounded-2xl leading-6'
                    onClick={handleLogout}
                >
                    Đăng nhập lại
                </button>
            </div>
        </div>
    </div>
  )
}

export default SessionExpiration 
