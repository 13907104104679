import React, { useEffect, useState } from 'react'
import { listCustomerDetailSelector, listCustomerLoading, listCustomerSelector, updateListCustomerSelector } from '../../redux/selector/CustomerSelector/Customer'
import { useDispatch, useSelector } from 'react-redux'
import { SearchParams } from '../../types/Customer';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../appConstants';
import { getListCustomerDetail, updateListCustomer } from '../../redux/actions/CustomerAction/CustomerAction';
import MappingBankLogo from '../../components/Mapping/MappingBankLogo/MappingBankLogo';
import { Download, Edit, Payment, PhoneGray } from '../../assets/Icons';
import { convertNumber } from '../../appCommon';
import { useNavigate } from 'react-router-dom';
import LoadingPage from '../../components/Loading/Loading';
import { InformaitonSelector } from '../../redux/selector/InforCustomerSelector/InforCustomerSelector';

interface PropsInformationCustomerDetail {
  customerId: string;
  lastStatementDate: number;
  handleExportExcel: () => void;
}

const InformationCustomerDetail: React.FC<PropsInformationCustomerDetail> = ({
  customerId,
  lastStatementDate,
  handleExportExcel,
}) => {
  const detailCustomer = useSelector(listCustomerDetailSelector) || {};
  const dataCustomerDetail = detailCustomer?.result[0];
  const idCustomerLocalStorage = JSON.parse(localStorage.getItem("customer") ?? "");
  const isLoading = useSelector(listCustomerLoading);
  const informaition = useSelector(InformaitonSelector);
  const listCustomer = JSON.parse(localStorage.getItem("listCustomer") ?? "");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchListCustomer = () => {   
    const searchObject: SearchParams = {
      skip: DEFAULT_SKIP,
      limit: DEFAULT_LIMIT,
      searchInput: "",
      customerId: idCustomerLocalStorage?.customerId ?? customerId
    };

    dispatch(getListCustomerDetail(searchObject));
  }; 

  useEffect(() => {
      fetchListCustomer();
  }, [])

  useEffect(() => {
    fetchListCustomer();
  }, [informaition])

  useEffect(() => {
    if (dataCustomerDetail) {
      const updatedListCustomer = listCustomer?.result?.map((customer: any) =>
        customer?.customerId === dataCustomerDetail?.customerId 
          ? dataCustomerDetail 
          : customer
      ) || [];

    dispatch(updateListCustomer(updatedListCustomer));
    
    }
  }, [dataCustomerDetail, listCustomer])
    
  return (
    <div>
      <section>
      <div className="px-6 pt-[24px] mb-4">
        <div className="bg-green200 text-green500 rounded w-fit p-1 mb-[4px] font-bold">
          {dataCustomerDetail?.customerGroup}
        </div>
        <div className="flex justify-between items-center mb-2">
          <div className="flex gap-2 justify-between items-center">
            <p className="text-xl font-extrabold">{dataCustomerDetail?.customerName}</p>
            <p className="text-base font-extrabold text-gray">
              {dataCustomerDetail?.customerNickname && `(${dataCustomerDetail?.customerNickname})`}
            </p>
          </div>
          <p
            onClick={() => {
              navigate("/info-customer", {state: {customerNickname: dataCustomerDetail?.customerNickname}});
            }}
          >
            <Edit />
          </p>
        </div>
        <div className="flex items-center justify-between">
          <p className="flex items-center gap-2 text-gray">
            <PhoneGray /> {dataCustomerDetail?.customerPhoneNumber}
          </p>
          <div
            className="flex justify-end items-center text-green500"
            onClick={handleExportExcel}
          >
            <Download /> Tải excel
          </div>
        </div>
        </div>
        <div className="px-6">
          <div className="bg-green200 rounded-2xl p-4 mb-[8px]">
            <p>Tổng doanh thu</p>
            <p className="text-lg font-bold">
              {" "}
              {convertNumber(dataCustomerDetail?.customerIncome)}
              <span className="text-lg font-bold">đ</span>
            </p>
          </div>
        </div>
        <div className="px-6">
          <div className="flex gap-[8px] text-xs font-semibold text-gray500 p-3">
            <Payment /> Đơn hàng đặt gần đây {lastStatementDate} ngày trước
          </div>
        </div>
        <div className="">
          <div className="flex justify-between items-center px-6">
            <p className="text-lg font-extrabold">Tài khoản ngân hàng</p>
          </div>
          <div className="pl-6">
            <div className="flex flex-row gap-[12px] overflow-x-auto mb-[24px]">
              <div className="py-[8px] px-[12px] rounded-2xl border border-[#F0F1F5]">
                <div className="flex gap-2 items-start">
                  <div className="flex justify-center items-start">
                    <MappingBankLogo
                      bankCode={dataCustomerDetail?.customerBank ?? "ic_bank_default"}
                    />
                  </div>
                  <div className="flex justify-between item-start border-gray100">
                    <div>
                      <p className="font-bold text-nowrap">{dataCustomerDetail?.customerName}</p>
                      <p className="text-gray text-nowrap">
                        STK:
                        {dataCustomerDetail?.customerAccountNbr}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoadingPage isLoading={isLoading} />
      </section>
    </div>
  )
}

export default InformationCustomerDetail
