import { InformationMerchant, InformationMerchantTypes, StoreSetupState } from "../../../types/StoreInfor";
import { 
    GET_INFORMATION_MERCHANT_FAIL, 
    GET_INFORMATION_MERCHANT_SUCCESS, 
    UPDTAE_INFORMATION_MERCHANT_FAIL, 
    UPDTAE_INFORMATION_MERCHANT_SUCCESS 
} from "../../actions/StoreSetupActions/StoreSetup";

const initialState: StoreSetupState = {
    data: {} as InformationMerchant,
    loading: true
};

const StoreSetupReducer = (state = initialState, action: InformationMerchantTypes): StoreSetupState => {
    switch (action.type) {
        case GET_INFORMATION_MERCHANT_SUCCESS:
            return {
                ...state,
                data: action?.payload,
                loading: false
            };
        case UPDTAE_INFORMATION_MERCHANT_SUCCESS:
            return {
                ...state,
                data: action?.payload,
            };
            case GET_INFORMATION_MERCHANT_FAIL:
            case UPDTAE_INFORMATION_MERCHANT_FAIL:
                return {
                    ...state,
                }
        default:
            return state; 
    }
};

export default StoreSetupReducer;