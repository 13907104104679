import React from "react";
import { ReportNoDataImage } from "../../assets/Images/index";
import { DATE_SELECTOR, TAB_REPORT } from "../../appConstants";
import CustomDropdown from "../../components/FilterSelect/FilterSelect";

interface ReportNoDataProps {
  title: string;
  value: number;
}

const ReportNoData: React.FC<ReportNoDataProps> = ({ title, value }) => {
  const isTabRevenue = value === TAB_REPORT.REVENUE.code;
  return (
    <div className="flex flex-col px-6 space-y-8">
      <div className="pb-4 flex justify-between">
        <div className="flex flex-col">
          <span className="text-sm text-gray500 mb-1">{title}</span>
          {isTabRevenue ? (
            <span className="flex text-2xl font-bold">
              <span className="text-sm pr-1">đ</span>0
            </span>
          ) : (
            <span className="flex text-2xl font-extrabold">
              0<span className="text-sm pl-1 pt-2">đơn</span>
            </span>
          )}
        </div>
        <CustomDropdown
          valueSelect={(value: string) => console.log(value)}
          minDay={true}
          isSelectTime={false}
          filterOption={DATE_SELECTOR}
        />
      </div>
      <div className="flex flex-col justify-center items-center w-full text-center">
        <img src={ReportNoDataImage} alt="" />
        <p className="text-base text-gray500 leading-6 font-medium">
          Chưa có dữ liệu để hiển thị
        </p>
      </div>
    </div>
  );
};

export default ReportNoData;
