import React, { useEffect, useRef, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { TIME_SELECTOR } from '../../../appConstants';
import { format } from 'date-fns';
import CustomTooltip from './CustomTooltip';
import { ActivePayload, CustomBarChartProps } from '../../../types/CustomChart';
import CustomXAxisTick from './CustomXAxisTick';
import CustomLegend from './CustomLegend';

const CustomBarChart: React.FC<CustomBarChartProps> = ({ dataReport }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [chartKey, setChartKey] = useState<number>(0);
  const data = dataReport?.pnlList || [];
  const isMonth = dataReport?.type === TIME_SELECTOR.MONTHS.type;

  const [highlightedDate, setHighlightedDate] = useState<string | null>(null); 

  const handleScroll = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!chartRef.current) return;

    const chartWidth = chartRef.current.clientWidth; 
    const scrollWidth = chartRef.current.scrollWidth; 
    const scrollTriggerOffset = 150; 

    const clickPosition = event.clientX; 

    if (clickPosition < scrollTriggerOffset || clickPosition > chartWidth - scrollTriggerOffset) {
      let newScroll = clickPosition + chartRef.current.scrollLeft - (chartWidth / 2);

      if (newScroll < 0) newScroll = 0;
      if (newScroll > scrollWidth - chartWidth) newScroll = scrollWidth - chartWidth;

      chartRef.current.scrollTo({ left: newScroll, behavior: 'smooth' });
    }
  };

  const chartWidth = Math.max(data.length * 90, 320); // Tính chiều rộng, tối thiểu 320px

  const handleChartClick = (state: { activePayload?: ActivePayload[] }) => {
    if (state.activePayload && state.activePayload.length > 0) {
      const { payload } = state.activePayload[0];
      const selectedDate = payload.txnDate; 
      setHighlightedDate(selectedDate); 
    }
  };

  const handleMouseLeave = () => {
    setHighlightedDate(null);
  };
    
  const resetChartState = () => {
    if (highlightedDate) {
      setChartKey((prevKey) => prevKey + 1);
      setHighlightedDate(null)
    }
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (chartRef.current && !chartRef.current.contains(event.target as Node)) {
      resetChartState();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [highlightedDate]);

  return (
    <div className='flex flex-col'>
      <div 
        className="relative overflow-x-auto scrollbar scrollbar-thumb-white"
        ref={chartRef}
        onClick={handleScroll}
        onMouseLeave={handleMouseLeave}
      >
        <ResponsiveContainer width={chartWidth} height={300} key={chartKey}>
          <BarChart 
            data={data} 
            barGap={0}
            onClick={handleChartClick} 
          >
            <XAxis
              dataKey="txnDate"
              interval={0}
              axisLine={false}
              tickSize={0}
              tickMargin={16}
              tickFormatter={(value) => {
                const date = new Date(value);
                return isMonth 
                  ? `T ${format(date, 'MM')}` 
                  : format(date, 'dd/MM'); 
              }}
              tick={(props) => <CustomXAxisTick {...props} highlightedDate={highlightedDate} isMonth={isMonth}/>} 
            />
            <Tooltip 
              content={<CustomTooltip isMonth={isMonth}/>} 
              cursor={false}
              trigger="click"
            />
            {/* <Legend 
              content={<CustomLegend />}
              align="center"
              verticalAlign="bottom"
              wrapperStyle={{ paddingTop: 15 }}  
            /> */}
            <Bar
              dataKey="profitAmount"
              fill="#06BD7D"
              radius={[8, 10, 0, 0]}
              barSize={36}
            />
            <Bar
              dataKey="lossAmount"
              fill="#B2EBD7"
              radius={[8, 10, 0, 0]}
              barSize={36}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className='flex justify-center w-full'>
        <ResponsiveContainer width="100%" height={20} key={chartKey}>
          <BarChart data={data} maxBarSize={0}>
            <Legend 
              content={<CustomLegend />}
              align="center"
              verticalAlign="bottom"
              wrapperStyle={{ position: 'absolute', width: '100%', bottom: 0 }}
            />
            <Bar dataKey="profitAmount" fill="#06BD7D" />
            <Bar dataKey="lossAmount" fill="#B2EBD7" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default CustomBarChart;
