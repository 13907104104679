import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getListGroup } from "../../redux/actions/ListGroupActions/ListGroup";
import { listGroupSelector } from "../../redux/selector/ListGroupSelector/ListGroup";

import LoadingPage from "../Loading/Loading";
import { GroupCustomerProp } from "../../types/Customer";
import { LoadingSelector } from "../../redux/selector/LoadingSelector";

const GroupCustomer: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listGroups = useSelector(listGroupSelector);
  const isLoading = useSelector(LoadingSelector);

  const fetchListGroup = () => {
    const searchObject = {}; // Pass the current page

    dispatch(getListGroup(searchObject));
  };

  useEffect(() => {
    fetchListGroup();
  }, []);

  return (
    <>
      <div className=" px-3">
        <p className="text-lg text-base font-bold">Tất cả</p>
        <span className="text-gray">{listGroups?.length} nhóm khách hàng </span>
      </div>
      {listGroups?.map((group: GroupCustomerProp, index: number) => (
        <div
          key={index}
          className="rounded-2xl p-4 bg-green200 mt-4 mx-3"
          onClick={() =>
            navigate("/detail-ground-customer", {
              state: { name: group.name },
            })
          }
        >
          <p className="text-base font-semibold">{group.name}</p>
          <p className="text-xs font-[400] text-[#575E6B]">
            {group.memberNumber} khách hàng
          </p>
        </div>
      ))}
      <LoadingPage isLoading={isLoading} />
    </>
  );
};

export default GroupCustomer;
