import React from "react";
import {
  Notification,
  Setting,
  Person,
  ArrowUp,
  ArrowDown,
  Receipt,
} from "../../assets/Icons/index";
import Footer from "../../components/Footer/Footer";
import { ErrorImage } from "../../assets/Images";

const HomeLoader: React.FC = () => {
  return (
    <div className="p-6 not-italic">
      <div className="flex items-center justify-between mb-5">
        <div className="animate-pulse flex flex-col space-y-2">
          <div className="w-32 h-4 bg-custom-gradient rounded-xl"></div>
          <div className="w-40 h-5 bg-custom-gradient rounded-xl"></div>
        </div>
        <div>
          <button className="mr-2.5">
            <Notification />
          </button>
          <button>
            <Setting />
          </button>
        </div>
      </div>
      <div className="bg-green200 rounded-2xl p-5">
        <div className="border-b border-gray100 pb-4 flex justify-between">
          <div className="flex flex-col">
            <span className="text-sm text-gray500 mb-1">
              Lợi nhuận tạm tính
            </span>
            <div className="w-40 h-5 animate-pulse bg-custom-gradient rounded-xl"></div>
          </div>
        </div>
        <div className="flex justify-between pt-4">
          <div>
            <span className="text-xs">Tổng chi phí</span>
            <div className="flex items-center">
              <ArrowUp />
              <div className="w-20 h-3.5 animate-pulse bg-custom-gradient rounded-xl pl-1"></div>
            </div>
          </div>
          <div>
            <span className="text-xs">Tổng lợi nhuận</span>
            <div className="flex items-center">
              <ArrowDown />
              <div className="w-20 h-3.5 animate-pulse bg-custom-gradient rounded-xl pl-1"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <div className="flex flex-col bg-green200 rounded-2xl w-[50%] px-5 py-4">
          <span className="text-sm leading-5 text-gray500 pb-1">
            Số giao dịch
          </span>
          <div className="flex items-center">
            <Receipt className="w-5 h-5 mr-1" />
            <div className="w-20 h-3.5 animate-pulse bg-custom-gradient rounded-xl pl-1"></div>
          </div>
        </div>
        <div className="flex flex-col bg-green200 rounded-2xl w-[50%] px-5 py-4 ml-3">
          <span className="text-sm leading-5 text-gray500 pb-1">
            Số khách hàng
          </span>
          <div className="flex items-center">
            <Person className="w-5 h-5 mr-1" />
            <div className="w-20 h-3.5 animate-pulse bg-custom-gradient rounded-xl pl-1"></div>
          </div>
        </div>
      </div>
      <div className="mt-6 shadow-modalShadow p-4">
        <div className="flex justify-between items-center">
          <h5 className="text-lg font-extrabold leading-7">Tất cả giao dịch</h5>
          <button className="text-base font-medium leading-6 text-green500">
            Xem tất cả
          </button>
        </div>
        <div className="mt-6">
          <div className="flex">
            <div>
              <img src={ErrorImage} alt="" />
            </div>
            <div className="flex justify-between w-[90%] border-b border-gray100 pb-4">
              <div className="flex items-center">
                <div className="flex flex-col space-y-0.5 ml-1">
                  <div className="w-20 h-3.5 animate-pulse bg-custom-gradient rounded-xl"></div>
                  <div className="w-14 h-3.5 animate-pulse bg-custom-gradient rounded-xl"></div>
                </div>
              </div>
              <div className="flex flex-col space-y-0.5 items-end">
                <div className="w-14 h-3.5 animate-pulse bg-custom-gradient rounded-xl"></div>
                <div className="w-20 h-3.5 animate-pulse bg-custom-gradient rounded-xl"></div>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <div>
              <img src={ErrorImage} alt="" />
            </div>
            <div className="flex justify-between w-[90%] border-b border-gray100 py-4">
              <div className="flex items-center">
                <div className="flex flex-col space-y-0.5 ml-1">
                  <div className="w-20 h-3.5 animate-pulse bg-custom-gradient rounded-xl"></div>
                  <div className="w-14 h-3.5 animate-pulse bg-custom-gradient rounded-xl"></div>
                </div>
              </div>
              <div className="flex flex-col space-y-0.5 items-end">
                <div className="w-14 h-3.5 animate-pulse bg-custom-gradient rounded-xl"></div>
                <div className="w-20 h-3.5 animate-pulse bg-custom-gradient rounded-xl"></div>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <div>
              <img src={ErrorImage} alt="" />
            </div>
            <div className="flex justify-between w-[90%] border-b border-gray100 py-4">
              <div className="flex items-center">
                <div className="flex flex-col space-y-0.5 ml-1">
                  <div className="w-20 h-3.5 animate-pulse bg-custom-gradient rounded-xl"></div>
                  <div className="w-14 h-3.5 animate-pulse bg-custom-gradient rounded-xl"></div>
                </div>
              </div>
              <div className="flex flex-col space-y-0.5 items-end">
                <div className="w-14 h-3.5 animate-pulse bg-custom-gradient rounded-xl"></div>
                <div className="w-20 h-3.5 animate-pulse bg-custom-gradient rounded-xl"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <Footer />
      </div>
    </div>
  );
};

export default HomeLoader;
