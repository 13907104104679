import { FailRespone } from "../../../types/Customer";
import {
  SearchStatementProp,
  TransactionProps,
} from "../../../types/Transaction";

export const GET_LIST_TRANSACTION = "GET_LIST_TRANSACTION";
export const GET_LIST_TRANSACTION_SUCCESS = "GET_LIST_TRANSACTION_SUCCESS";
export const GET_LIST_TRANSACTION_FAIL = "GET_LIST_TRANSACTION_FAIL";

export const GET_LIST_TRANSACTION_RESET = "GET_LIST_TRANSACTION_RESET";
export const GET_LIST_TRANSACTION_NEWEST = "GET_LIST_TRANSACTION_NEWEST";
export const GET_LIST_TRANSACTION_NEWEST_SUCCESS = "GET_LIST_TRANSACTION_NEWEST_SUCCESS";

export const SAVE_TIME_SELECTOR = "SAVE_TIME_SELECTOR";

export const getListTransaction = (
  payload: SearchStatementProp, 
  isNewPage: boolean = false, 
  selectDate: string | undefined = undefined
) => {
  return {
    type: GET_LIST_TRANSACTION,
    payload,
    isNewPage,
    selectDate
  };
};

export const getListTransactionSuccess = (
  payload: TransactionProps[], 
  isNewPage: boolean = false, 
  selectDate: string | undefined = undefined
) => {
  return {
    type: GET_LIST_TRANSACTION_SUCCESS,
    payload,
    isNewPage,
    selectDate
  };
};

export const getListTransactionFail = (payload: FailRespone) => {
  return {
    type: GET_LIST_TRANSACTION_FAIL,
    payload,
  };
};

export const getListTransactionReset = () => {
  return {
    type: GET_LIST_TRANSACTION_RESET,
  };
};

export const getListTransactionNewest = (
  payload: SearchStatementProp, 
  selectDate: string | undefined = undefined
) => {
  return {
    type: GET_LIST_TRANSACTION_NEWEST,
    payload,
    selectDate
  };
};
export const getListTransactionNewestSuccess = (
  payload: TransactionProps[], 
  isNewPage: boolean = false, 
  selectDate: string | undefined = undefined
) => {
  return {
    type: GET_LIST_TRANSACTION_NEWEST_SUCCESS,
    payload,
    isNewPage,
    selectDate
  };
};

export const saveTimeSelector = (payload: string) => {
  return {
    type: SAVE_TIME_SELECTOR,
    payload
  }
}
