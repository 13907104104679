import React, { useEffect, useRef } from 'react'
import { IconLeft } from "../../assets/Icons/index";
import { useNavigate } from 'react-router-dom';

const TermsAndPolicy: React.FC = () => {
  const hasNavigated = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (window.history.state && window.history.state.idx > 0) {
      hasNavigated.current = true;
    }
  }, []);

  const handleBack = () => { // back lại trang trước
    if (hasNavigated.current) {
      navigate(-1); 
    } else {
      navigate("/store-setup"); 
    }
  };
  return (
    <div className='p-6 not-italic'>
       <header>
          <button onClick={handleBack}>
            <IconLeft/>
          </button>
        </header>
        <main>
            <section className='mt-4 flex flex-col space-y-6 not-italic'>
                <h2 className='text-2xl font-extrabold leading-8'>Điều khoản và chính sách</h2>
                <section>
                  <h3 className='font-bold text-lg leading-7'>1. Giới thiệu</h3>
                  <p className='text-base font-medium mt-2 leading-6 text-gray500'>
                    Ứng dụng miniapp mSeller (sau đây gọi tắt là "ứng dụng") do Ngân hàng TMCP Quân đội (MB Bank) 
                    phát triển và cung cấp. Ứng dụng là công cụ quản lý dòng tiền thông minh dành cho các hộ kinh doanh.
                  </p>
                </section>
                <section>
                  <h3 className='font-bold text-lg leading-7'>2. Quy định chung</h3>
                  <p className='text-base font-medium mt-2 leading-6 text-gray500'>
                    Việc sử dụng ứng dụng có nghĩa là bạn đã đồng ý với tất cả các điều khoản và điều kiện 
                    được nêu trong bản Điều khoản Sử dụng này. <br />
                    MB Bank có quyền thay đổi, sửa đổi, bổ sung hoặc xóa bỏ bất kỳ điều khoản nào trong điều khoản 
                    Sử dụng này mà không cần thông báo trước. Việc tiếp tục sử dụng ứng dụng sau khi có thay đổi, 
                    sửa đổi, bổ sung hoặc xóa bỏ bất kỳ điều khoản nào có nghĩa là bạn đã đồng ý với những thay đổi đó.
                  </p>
                </section>
                <section>
                  <h3 className='font-bold text-lg leading-7'>3. Điều kiện sử dụng</h3>
                  <ul className="list-disc pl-6 text-base font-medium mt-2 leading-6 text-gray500">
                    <li>Bạn phải đủ 18 tuổi hoặc được sự đồng ý của cha mẹ/người giám hộ để sử dụng ứng dụng.</li>
                    <li>Bạn phải là chủ cửa hàng và đã sử dụng dịch vụ QR thanh toán của MB Bank</li>
                    <li>Bạn chịu trách nhiệm bảo mật thông tin tài khoản của mình, bao gồm mật khẩu, thông tin đăng nhập, ...</li>
                    <li>Bạn không được phép sử dụng ứng dụng cho bất kỳ mục đích bất hợp pháp nào.</li>
                    <li>Bạn không được phép sao chép, phân phối, sửa đổi hoặc khai thác ứng dụng mà không có sự cho phép của MB Bank.</li>
                  </ul>
                </section>
                <section>
                  <h3 className='font-bold text-lg leading-7'>4. Trách nhiệm</h3>
                  <p className='text-base font-medium mt-2 leading-6 text-gray500'>
                    MB Bank không chịu trách nhiệm cho bất kỳ thiệt hại nào phát sinh do việc sử dụng hoặc 
                    không sử dụng được ứng dụng. <br />
                    MB Bank không chịu trách nhiệm cho bất kỳ thông tin nào được cung cấp bởi người dùng, 
                    cũng như cho bất kỳ thiệt hại nào phát sinh từ việc sử dụng thông tin đó.
                  </p>
                </section>
                <section>
                  <h3 className='font-bold text-lg leading-7'>5. Bảo mật thông tin</h3>
                  <p className='text-base font-medium mt-2 leading-6 text-gray500'>
                    MB Bank cam kết bảo mật thông tin cá nhân của người dùng theo quy định của pháp luật. <br />
                    Thông tin cá nhân của bạn sẽ được bảo mật theo chính sách bảo mật của MB Bank.
                  </p>
                </section>
                <section>
                  <h3 className='font-bold text-lg leading-7'>6. Quyền sở hữu trí tuệ</h3>
                  <p className='text-base font-medium mt-2 leading-6 text-gray500'>
                    Ứng dụng và nội dung của ứng dụng là tài sản trí tuệ của MB Bank. <br />
                    Bạn không được phép sử dụng, sao chép, phân phối, sửa đổi hoặc khai thác bất kỳ tài sản 
                    trí tuệ nào của MB Bank mà không có sự cho phép của MB Bank.
                  </p>
                </section>
                <section>
                  <h3 className='font-bold text-lg leading-7'>7. Chấm dứt sử dụng</h3>
                  <p className='text-base font-medium mt-2 leading-6 text-gray500'>
                    MB Bank có quyền chấm dứt việc sử dụng ứng dụng của bạn mà không cần thông báo trước trong 
                    trường hợp bạn vi phạm điều khoản sử dụng này.
                  </p>
                </section>
                <section>
                  <h3 className='font-bold text-lg leading-7'>8. Luật áp dụng và giải quyết tranh chấp</h3>
                  <p className='text-base font-medium mt-2 leading-6 text-gray500'>
                    Điều khoản sử dụng này được điều chỉnh và giải thích theo pháp luật Việt Nam. <br />
                    Bất kỳ tranh chấp nào phát sinh từ việc sử dụng ứng dụng sẽ được giải quyết theo quy định của 
                    pháp luật Việt Nam.
                  </p>
                </section>
                <section>
                  <h3 className='font-bold text-lg leading-7'>9. Liên hệ</h3>
                  <p className='text-base font-medium mt-2 leading-6 text-gray500'>
                    Nếu bạn có bất kỳ câu hỏi nào liên quan đến điều khoản sử dụng này, vui lòng liên hệ với 
                    MB Bank qua các kênh thông tin chính thức của MB Bank.
                  </p>
                </section>
                <section>
                  <h3 className='font-bold text-lg leading-7'>10. Lưu ý</h3>
                  <p className='text-base font-medium mt-2 leading-6 text-gray500'>
                    Điều khoản sử dụng này được dịch từ tiếng việt sang tiếng anh. Trong trường hợp có bất kỳ sự 
                    mâu thuẫn nào giữa bản tiếng Việt và bản tiếng Anh, bản tiếng Việt sẽ là bản chính thức. <br />
                    MB Bank có quyền sửa đổi, bổ sung hoặc thay đổi các điều khoản của Điều khoản Sử dụng này bất kỳ 
                    lúc nào mà không cần thông báo trước. Việc tiếp tục sử dụng ứng dụng sau khi có thay đổi có nghĩa 
                    là bạn đã đồng ý với những thay đổi đó. <br />
                    Bằng cách sử dụng ứng dụng, bạn đồng ý với tất cả các điều khoản và điều kiện được nêu trong 
                    điều khoản sử dụng này.
                  </p>
                </section>
            </section>
        </main>
    </div>
  )
}

export default TermsAndPolicy
