// Tabs.tsx
import React, { ReactNode } from "react";

interface TabProps {
  label: string;
  children: ReactNode;
}

interface TabsProps {
  valueTab: number; 
  children: ReactNode;
  onTabClick: (index: number) => void;
}

const Tab: React.FC<TabProps> = ({ children }) => {
  return <>{children}</>;
};

const Tabs: React.FC<TabsProps> = ({ children, valueTab, onTabClick }) => {
  
  return (
    <div className="w-full max-w-2xl">
      {/* Tab Buttons */}
      <div className="fixed z-[41] pt-4 w-full flex item-center justif-start px-6">
        {React.Children.map(children, (child, index) => {
          if (!React.isValidElement(child)) return null;
          return (
            <button
              className={`py-1 px-2 transition-colors font-semibold rounded-lg text-sm leading-5 ${
                index === valueTab
                  ? "text-white rounded-lg bg-green500"
                  : "text-gray"
              }`}
              onClick={() => onTabClick(index)}>
              {child.props.label}
            </button>
          );
        })}
      </div>

      {/* Tab Content */}
      <div className="py-4">{React.Children.toArray(children)[valueTab]}</div>
    </div>
  );
};

export { Tabs, Tab };
