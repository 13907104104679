import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_HOME_PAGE, getHomePageFail, getHomePageSuccess } from '../../actions/HomePageActions/HomePage';
import { getQuickReportApi } from '../../../api/HomePage';
import { GetHomePageAction, HomePageFailRespone, HomePageResponseData } from '../../../types/HomeTypes';
import { RESPONSE_STAUS_CODE } from '../../../appConstants';

function* fetchHomePage(action: GetHomePageAction) {   
    try {
        const res: HomePageResponseData = yield call(getQuickReportApi, action.payload);        
        if (res?.status === RESPONSE_STAUS_CODE.SUCCESS.name && res?.data) {
            yield put(getHomePageSuccess(res?.data))
        } else {
            const errorData = res?.error as HomePageFailRespone;
            yield put(getHomePageFail(errorData));
        }
    } catch (error) {
        throw error;
    }
}


export default function* HomePageSaga() {
    yield takeEvery(GET_HOME_PAGE, fetchHomePage);
}
