import React from 'react'
import bankIcon from "../../../assets/indexBank"

const getBankIcon = (bankCode: string) => {
    switch (bankCode) {
        case "ABBANK":
          return bankIcon.imgABBANK;
        case "ACB":
          return bankIcon.imgACB;
        case "ANZ":
          return bankIcon.imgANZ;
        case "BANGKOK":
          return bankIcon.imgBANGKOK;
        case "BIDC":
          return bankIcon.imgBIDC;
        case "BIDV":
          return bankIcon.imgBIDV;
        case "BNP PARIBAS":
        case "BNP PARIBAS HCM":
          return bankIcon.imgBNP_PARIBAS;
        case "BUSAN":
          return bankIcon.imgBUSAN;
        case "BVB":
          return bankIcon.imgBVB;
        case "CCB":
          return bankIcon.imgCCB;
        case "CCF":
          return bankIcon.imgCCF;
        case "CHINA":
          return bankIcon.imgCHINA;
        case "CHINATRUST":
          return bankIcon.imgCHINATRUST;
        case "CHINESE":
          return bankIcon.imgCHINESE;
        case "CIMB":
          return bankIcon.imgCIMB;
        case "CITIBANK":
          return bankIcon.imgCITIBANK;
        case "CITIBANKHN":
          return bankIcon.imgCITIBANKHN;
        case "CITIBANKHCM":
          return bankIcon.imgCITIBANKHCM;
        case "COMERCIAL":
          return bankIcon.imgCOMERCIAL;
        case "COMMONWEALTH":
          return bankIcon.imgCOMMONWEALTH;
        case "COMMUNICATION":
          return bankIcon.imgCOMMUNICATION;
        case "CSXH":
          return bankIcon.imgCSXH;
        case "CTBC":
          return bankIcon.imgCTBC;
        case "CUBHCM":
          return bankIcon.imgCUBHCM;
        case "DAB":
          return bankIcon.imgDAB;
        case "DBS":
          return bankIcon.imgDBS;
        case "DEUTSCHE":
          return bankIcon.imgDEUTSCHE;
        case "EIB":
          return bankIcon.imgEIB;
        case "ESUN":
          return bankIcon.imgESUN;
        case "GPB":
          return bankIcon.imgGPB;
        case "HANA":
          return bankIcon.imgHANA;
        case "HDB":
          return bankIcon.imgHDB;
        case "HLB":
          return bankIcon.imgHLB;
        case "HSBC":
          return bankIcon.imgHSBC;
        case "HSBCHN":
          return bankIcon.imgHSBCHN;
        case "HUA NAN":
          return bankIcon.imgHUA_NAN;
        case "IBK HN":
          return bankIcon.imgIBK_HN;
        case "IBK":
          return bankIcon.imgIBK;
        case "IBK HCM":
          return bankIcon.imgIBK_HCM;
        case "ICBC":
          return bankIcon.imgICBC;
        case "INDIA":
          return bankIcon.imgINDIA;
        case "IVB":
          return bankIcon.imgIVB;
        case "KLB":
          return bankIcon.imgKLB;
        case "KOOKMIN CN HCM":
          return bankIcon.imgKOOKMIN_CN_HCM;
        case "KOOKMIN CN HN":
          return bankIcon.imgKOOKMIN_CN_HN;
        case "KOOKMIN":
          return bankIcon.imgKOOKMIN;
        case "KOREA":
          return bankIcon.imgKOREA;
        case "LPB":
          return bankIcon.imgLPB;
        case "MALAYAN":
          return bankIcon.imgMALAYAN;
        case "MAYBANK":
          return bankIcon.imgMAYBANK;
        case "MB":
          return bankIcon.imgMB;
        case "MBS":
          return bankIcon.imgMBS;
        case "MCB":
          return bankIcon.imgMCB;
        case "MEGA":
          return bankIcon.imgMEGA;
        case "MHB":
          return bankIcon.imgMHB;
        case "MIZUHO":
          return bankIcon.imgMIZUHO;
        case "MSB":
          return bankIcon.imgMSB;
        case "NAMABANK":
          return bankIcon.imgNAMABANK;
        case "NASB":
          return bankIcon.imgNASB;
        case "NATIXIS":
          return bankIcon.imgNATIXIS;
        case "NCB":
          return bankIcon.imgNCB;
        case "NONG HYUP":
          return bankIcon.imgNONG_HYUP;
        case "OCB":
          return bankIcon.imgOCB;
        case "OJB":
          return bankIcon.imgOJB;
        case "PGB":
          return bankIcon.imgPGB;
        case "PVC":
          return bankIcon.imgPVC;
        case "SCB":
          return bankIcon.imgSCB;
        case "SEAB":
          return bankIcon.imgSEAB;
        case "SGB":
          return bankIcon.imgSGB;
        case "SHANGHAI":
          return bankIcon.imgSHANGHAI;
        case "SHB":
          return bankIcon.imgSHB;
        case "SHBVN":
          return bankIcon.imgSHBVN;
        case "SIAM":
          return bankIcon.imgSIAM;
        case "SINOPAC":
          return bankIcon.imgSINPAC;
        case "SMBC":
          return bankIcon.imgSMBC;
        case "STANDARDCHARTERED":
          return bankIcon.imgSTANDARDCHARTERED;
        case "STB":
          return bankIcon.imgSTB;
        case "SVFC":
          return bankIcon.imgSVFC;
        case "TAIPEI":
          return bankIcon.imgTAIPEI;
        case "TCB":
          return bankIcon.imgTCB;
        case "TOKYOMITSUBISHI":
        case "TOKYOMITSUBISHIHCM":
          return bankIcon.imgTokyoMitsubishi;
        case "TPB":
          return bankIcon.imgTPB;
        case "TRUSTBANK":
          return bankIcon.imgTRUSTBANK;
        case "UOB":
          return bankIcon.imgUOB;
        case "VAB":
          return bankIcon.imgVAB;
        case "VBA":
          return bankIcon.imgVBA;
        case "VCB":
          return bankIcon.imgVCB;
        case "VCCB":
          return bankIcon.imgVCCB;
        case "VDB":
          return bankIcon.imgVDB;
        case "VIB":
          return bankIcon.imgVIB;
        case "VID":
          return bankIcon.imgVID;
        case "VIETBANK":
          return bankIcon.imgVIETBANK;
        case "VIETINBANK":
          return bankIcon.imgVIETINBANK;
        case "VIETTELPAY":
          return bankIcon.imgVIETTELPAY;
        case "VPB":
          return bankIcon.imgVPB;
        case "VRB":
          return bankIcon.imgVRB;
        case "WOORI":
          return bankIcon.imgWoori;
        case "MIZUHOHN":
        case "MIZUHOHCM":
          return bankIcon.imgMIZUHOHN;
        case "MBB":
          return bankIcon.imgMBB;
        case "CAKEVPB":
          return bankIcon.imgCAKEVPB;
        case "CBB":
          return bankIcon.imgCBB;
        case "UBANKVPB":
          return bankIcon.imgUBANKVPB;
        case "VNPTM":
          return bankIcon.imgVNPTM;
        case "VIETTELM":
          return bankIcon.imgVIETTELM;
        case "COMERCIAL HCM":
          return bankIcon.imgCOMERCIAL_HCM;
        case "COMERCIAL HN":
          return bankIcon.imgCOMERCIAL_HN;
        case "UMEE":
          return bankIcon.imgUMEE;
        case "KEB HANA HN":
          return bankIcon.imgKEB_HANA_HN;
        case "KEB HANA HCM":
          return bankIcon.imgKEB_HANA_HCM;
        case "AGRICULTURALCHINA":
          return bankIcon.imgAGRICULTURALCHINA;
        case "KASIKORNBANK CNHCM":
          return bankIcon.imgKASIKORNBANK_CNHCM;
        case "LIOBANK":
          return bankIcon.imgLIOBANK;
        case "TIMO":
          return bankIcon.imgTIMO;
        case "MAFC":
          return bankIcon.imgMAFC;
        case "VIKKI BY HDBANK":
          return bankIcon.imgVikki_by_HDBank;
        case "VIETQR_NAPAS":
          return bankIcon.imgVietQR_NAPAS;
        case "ic_bank_default":
        default:
          return bankIcon.bankDefault;
      }
}

interface MappingBankLogoType {
    bankCode: string;
    className?: string;
}

const MappingBankLogo: React.FC<MappingBankLogoType> = ({bankCode, className}) => {
    const IconBank = getBankIcon(bankCode.toUpperCase())
  return (
    <>
      <div>
        <img className={`object-contain ${className || "w-8"}`} src={IconBank} alt="" />
      </div>
    </>
  )
}

export default MappingBankLogo
