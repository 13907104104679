import { takeEvery, call, put } from "redux-saga/effects";
import {
  GET_LIST_GROUP,
  // getListGroupFail,
  getListGroupSuccess,
} from "../../actions/ListGroupActions/ListGroup";
import { getListGroup } from "../../../api/Customer";
import { setLoading } from "../../actions/LoadingAction";
import { ResponseGroupData } from "../../../types/Customer";

function* fetchListGroup() {
  try {
    yield put(setLoading(true));
    const res: ResponseGroupData = yield call(getListGroup);
    yield put(getListGroupSuccess(res?.data));
  } catch (error) {
    // yield put(getListGroupFail(error));
    throw error;
  } finally {
    yield put(setLoading(false));
  }
}

export default function* ListGroupSaga() {
  yield takeEvery(GET_LIST_GROUP, fetchListGroup);
}
