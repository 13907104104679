// redux/loadingActions.ts
export const SET_LOADING = "SET_LOADING";

interface SetLoadingAction {
  type: typeof SET_LOADING;
  payload: boolean;
}

export const setLoading = (isLoading: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: isLoading,
});

export type LoadingActionTypes = SetLoadingAction;
