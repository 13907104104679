import React, { useEffect } from "react";
import { type DotProps } from "recharts";

type TPosition = {
    x: number;
    y: number;
}

type TCustomActiveDotProp = {
    setActiveDotPos: (position: TPosition) => void | null
}

export const CustomActiveDot: React.FC<TCustomActiveDotProp & DotProps> = (props) => {
    const { setActiveDotPos, cx = 0, cy = 0 } = props; // Cung cấp giá trị mặc định cho cx và cy

    useEffect(() => {
        if (cx && cy) {
            setActiveDotPos({ x: cx, y: cy });
        }
    }, [cx, cy]); // Bao gồm cx và cy trong mảng phụ thuộc

    return (
        <>
            {cx && cy && (
                <svg
                    x={cx - 19}
                    y={cy - 16}
                    width="40"
                    height="40"
                    viewBox="0 0 39 38"
                    stroke='#06BD7D'
                    strokeWidth={1}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <defs>
                        <filter id="filter0_d_2239_157550" x="0" y="0" width="39" height="38" filterUnits="userSpaceOnUse">
                            <feFlood floodColor="rgba(255, 255, 255, 0.3)" />
                            <feComposite in2="SourceAlpha" operator="in" />
                            <feGaussianBlur stdDeviation="5" />
                            <feOffset dy="4" />
                            <feMerge>
                                <feMergeNode />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                    <g filter="url(#filter0_d_2239_157550)">
                        <circle cx="19" cy="16" r="8" fill="white" />
                    </g>
                </svg>
            )}
        </>
    );
};
