import { format } from "date-fns";
import React, { useEffect, useCallback, useState, useRef } from "react";
import Picker from "react-mobile-picker";

// Lấy ngày hôm nay
const today = new Date();

function getDayArray(year: number, month: number, isFromDate?: boolean) {
  const dayCount = new Date(year, month, 0).getDate();
  const days = Array.from({ length: dayCount }, (_, i) =>
    String(i + 1).padStart(2, "0")
  );

    const currentDay = today.getDate();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();

    if (year === currentYear && month === currentMonth) {
      if (isFromDate) {
        return days.filter(day => Number(day) <= (currentDay - 4));
      }
      return days.filter(day => Number(day) <= currentDay);
    }
  
  return days;
}
interface DatePickerProps {
  onNext?: () => void;
  isFinal: boolean;
  isDisabled: boolean;
  onSearch?: () => void;
  onPrev: () => void;
  onValue: (value: string) => void;
  maxDate?: any; 
  isFromDate?: boolean
  defaultValue?: any
}

type PickerValue = {
  year: string;
  month: string;
  day: string;
};

const DatePicker: React.FC<DatePickerProps> = ({
  onNext,
  onSearch,
  onPrev,
  onValue,
  isDisabled,
  isFinal,
  maxDate,
  isFromDate,
  defaultValue,
}) => {  
  const prevDefaultValue = useRef<Date | null>(null);
  const prevPickerValue = useRef<PickerValue | null>(null);
  const [pickerValue, setPickerValue] = useState<PickerValue>(() => {
    if (defaultValue) {
      const year = defaultValue.getFullYear();
      const month = (defaultValue.getMonth() + 1).toString().padStart(2, '0');
      const day = defaultValue.getDate().toString().padStart(2, '0');
      
      return { year: year.toString(), month, day: day };
    }
    
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const dayArray = getDayArray(year, Number(month), isFromDate);
    const day = dayArray[dayArray.length - 1];
    
    return { year: year.toString(), month, day };
  }); 
  
  useEffect(() => {
    if (defaultValue) {
      // So sánh với giá trị defaultValue trước đó
      if (!prevDefaultValue.current || prevDefaultValue.current.getTime() !== defaultValue.getTime()) {
        prevDefaultValue.current = defaultValue;
        
        const year = defaultValue.getFullYear();
        const month = (defaultValue.getMonth() + 1).toString().padStart(2, '0');
        const day = defaultValue.getDate().toString().padStart(2, '0');
        
        const newPickerValue = { year: year.toString(), month, day: day };
        prevPickerValue.current = newPickerValue;
        setPickerValue(newPickerValue);
      } else {
        // Nếu defaultValue không đổi và có giá trị picker trước đó, giữ nguyên giá trị đó
        if (prevPickerValue.current) {
            setPickerValue(prevPickerValue.current);
        }
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    const { day, month, year } = pickerValue;
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    const formatted = date.toString();

    onValue(formatted);
  }, [pickerValue]);
  
  const handlePickerChange = useCallback(
    (newValue: PickerValue, key: string) => {
      const { year, month, day } = newValue;
      const selectedYear = Number(year);
      const selectedMonth = Number(month);
      const selectedDay = Number(day);
      
      // Nếu năm, tháng, ngày vượt quá hôm nay, điều chỉnh về giá trị hợp lệ
      if (selectedYear > today.getFullYear()) {
        setPickerValue({
          year: today.getFullYear().toString(),
          month: today.getMonth() + 1 + "",
          day: today.getDate() + "",
        });
        return;
      }

      if (
        selectedYear === today.getFullYear() &&
        selectedMonth > today.getMonth() + 1
      ) {
        setPickerValue({
          year,
          month: (today.getMonth() + 1).toString(),
          day: today.getDate().toString(),
        });
        return;
      }

      if (
        selectedYear === today.getFullYear() &&
        selectedMonth === today.getMonth() + 1 &&
        selectedDay > today.getDate()
      ) {
        setPickerValue({
          year,
          month,
          day: today.getDate().toString(),
        });
        return;
      }

      // Kiểm tra nếu ngày hợp lệ, cập nhật state
      if (key === "day") {
        setPickerValue(newValue);
        return;
      }

      const newDayArray = getDayArray(Number(year), Number(month), isFromDate);
      const newDay = newDayArray.includes(day as string)
        ? day
        : newDayArray[newDayArray.length - 1]; // Chọn ngày cuối cùng của mảng
      
      setPickerValue({ 
        ...newValue, 
        day: newDay 
      });
    },
    
    [maxDate, isFromDate]
  );

  return (
    <>
      <div className="mb-4">
        <Picker
          value={pickerValue}
          onChange={handlePickerChange}
          wheelMode="natural"
        >
          <Picker.Column name="day">
            {getDayArray(
              Number(pickerValue.year),
              Number(pickerValue.month),
              isFromDate
            )
              .map((day) => (
                <Picker.Item key={day} value={day}>
                  {({ selected }) => (
                    <div
                      className={
                        selected
                          ? "text-base font-bold"
                          : "text-base  text-[#C2C8D1]"
                      }
                    >
                      {day}
                    </div>
                  )}
                </Picker.Item>
              ))}
          </Picker.Column>
          <Picker.Column name="month">
            {Array.from({ length: 12 }, (_, i) =>
              String(i + 1).padStart(2, "0")
            )
              .filter((month) => {
                // Chỉ hiển thị tháng <= tháng hôm nay nếu cùng năm
                const selectedYear = Number(pickerValue.year);
                return !(
                  selectedYear === today.getFullYear() &&
                  Number(month) > today.getMonth() + 1
                );
              })
              .map((month) => (
                <Picker.Item key={month} value={month}>
                  {({ selected }) => (
                    <div
                      className={
                        selected
                          ? "text-base font-bold"
                          : "text-base  text-[#C2C8D1]"
                      }
                    >
                      {month}
                    </div>
                  )}
                </Picker.Item>
              ))}
          </Picker.Column>
          <Picker.Column name="year">
            {Array.from({ length: 200 }, (_, i) => `${1923 + i}`)
              .filter((year) => Number(year) <= today.getFullYear())
              .map((year) => (
                <Picker.Item key={year} value={year}>
                  {({ selected }) => (
                    <div
                      className={
                        selected
                          ? "text-base font-bold"
                          : "text-base  text-[#C2C8D1]"
                      }
                    >
                      {year}
                    </div>
                  )}
                </Picker.Item>
              ))}
          </Picker.Column>
        </Picker>
      </div>
      <div className="flex item-center justify-center gap-[12px] w-full">
        <button
          type="button"
          className="py-[16px] px-[10px] bg-green200 text-green500 font-bold rounded-2xl w-full"
          onClick={onPrev}
        >
          Quay lại
        </button>
        {!isFinal ? (
          <button
            type="button"
            className="py-[16px] px-[10px] bg-green500 text-white font-bold rounded-2xl w-full"
            disabled={isDisabled}
            onClick={onSearch}
          >
            Lọc kết quả
          </button>
        ) : (
          <button
            type="button"
            className="py-[16px] px-[10px] bg-green500 text-white font-bold rounded-2xl w-full"
            onClick={onNext}
          >
            Tiếp tục
          </button>
        )}
      </div>
    </>
  );
};

export default DatePicker;
