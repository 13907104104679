import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TabsChild, TabChild } from "../TabChild/TabChild";
import { Search } from "../../assets/Icons";
import ListTransaction from "./ListTransaction";
import {
  dateSelector,
  listTransactionSelector,
} from "../../redux/selector/ListTransactionSelector/ListTransaction";
import {
  getListTransaction,
  getListTransactionNewest,
  getListTransactionReset,
  saveTimeSelector,
} from "../../redux/actions/ListTransactionActions/ListTransaction";
import { useDispatch, useSelector } from "react-redux";
import { convertNumber, formatDateReport } from "../../appCommon";
import { DataListTransaction, DateProps } from "../../types/Transaction";
import LoadingPage from "../Loading/Loading";
import { LoadingSelector } from "../../redux/selector/LoadingSelector";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import {
  DEFAULT_LIMIT,
  EXTRACTION,
  TAB_TRANSACTION,
  TODAY,
} from "../../appConstants";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../ScrollPage/ScrollTop";
import { getListCustomerReset, getListStatementCustomer, getListStatementNewest } from "../../redux/actions/CustomerAction/CustomerAction";
import { listCustomerLoading, listStatementCustomer } from "../../redux/selector/CustomerSelector/Customer";
import { useGlobalState } from "../GlobalState/GlobalState";

interface PropsTransationPage {
  contractBank: string;
  contractAccountNbr: string;
  contractCustomerName: string;
  isHidden: boolean;
  scrollContainerRef: React.RefObject<HTMLElement>; 
}

const TransactionPageComponent: React.FC<PropsTransationPage> = ({
  contractBank,
  contractAccountNbr,
  contractCustomerName,
  isHidden,
  scrollContainerRef,
}) => {
  const [type, setType] = useState<string>(TAB_TRANSACTION.ALL.name);
  const defaultDate = useSelector(dateSelector);
  const [selectDate, setSelectDate] = useState<string>(defaultDate);
  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const searchInputRef = useRef(searchInput);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dataListTransaction = useSelector(listTransactionSelector) || {};
  const lisDataStatementCustomer = useSelector(listStatementCustomer);
  const isLoading = useSelector(LoadingSelector);
  const isLoadingCustomer = useSelector(listCustomerLoading);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const informationMerchant = JSON.parse(
    sessionStorage.getItem("informationMerchant") ?? ""
  );
  const formattedDateRange: DateProps = formatDateReport(selectDate, TODAY);
  const { getState, setState } = useGlobalState();
  const resetPage = getState("resetPage");
  
  // Reset all states to initial values
  const resetAllStates = useCallback(() => {
    setType(TAB_TRANSACTION.ALL.name);
    setPage(1);
    searchInputRef.current = "";
  }, []);
  
  useEffect(() => {
    searchInputRef.current = searchInput;
  }, [searchInput]);

  const transactionData = useMemo(() => {
    return isHidden ? lisDataStatementCustomer : dataListTransaction;
  }, [isHidden, dataListTransaction, lisDataStatementCustomer]);
  
  const loadingPage = useMemo(() => {
    return isHidden ? isLoadingCustomer : isLoading;
  }, [isHidden, isLoading, isLoadingCustomer]);

  const fetchCustomerTransaction = useCallback(
    (isNewPage: boolean = false) => {

      if (!isRefreshing) {
        const searchObject = {
          customerAccount: informationMerchant?.merchantAccountNbr,
          toDate: formattedDateRange.toDate,
          fromDate: formattedDateRange.fromDate,
          skip: page,
          limit: DEFAULT_LIMIT,
          transactionType: type,
          extraction: EXTRACTION.NO.name,
          searchInput: searchInputRef.current,
          ...(isHidden && { contractBank, contractAccountNbr, contractCustomerName }),
        };
        if (isHidden) {
          dispatch(getListStatementCustomer(searchObject));
        }else {
          dispatch(getListTransaction(searchObject, isNewPage));
        }
      }
    },

    [selectDate, type, page, dispatch, informationMerchant?.merchantAccountNbr, isHidden]
  );

  useInfiniteScroll({
    isLoading,
    onLoadMore: () => {
      setIsRefreshing(false);
      setPage((prevPage) => prevPage + DEFAULT_LIMIT);
    },
    onRefresh: () => {
      // Reset all states and fetch fresh data
      setIsRefreshing(true);

      resetAllStates();
      dispatch(getListTransactionReset())
      const searchObject = {
        customerAccount: informationMerchant?.merchantAccountNbr,
        toDate: formattedDateRange.toDate,
        fromDate: formattedDateRange.fromDate,
        skip: 1,
        limit: DEFAULT_LIMIT,
        transactionType: type,
        extraction: EXTRACTION.NO.name,
        searchInput: searchInputRef.current,
        ...(isHidden && { contractBank, contractAccountNbr, contractCustomerName }),
      };
      if (isHidden) {
        dispatch(getListStatementNewest(searchObject, selectDate));
      } else {
        dispatch(getListTransactionNewest(searchObject, selectDate))
      }
    },
    containerRef: scrollContainerRef,
    offset: 100,
  });

  useEffect(() => {
    if (resetPage) {
      // Chặn componet mount lần đầu , xóa state cho lần chạy thứ 2
      setState("resetPage", false);
    } else {
      if (isFirstRender) {        
        // setSelectDate(isChangeTab ? TODAY : selectDate);;
        dispatch(getListTransactionReset());
        dispatch(getListCustomerReset());
        fetchCustomerTransaction(false);
        setIsFirstRender(false);
      } else {    
        fetchCustomerTransaction(true);
      }
    }
  }, [selectDate, page, type, isFirstRender]);

  const handleTabs = (value: number) => {
    setSelectDate(TODAY);
    setIsFirstRender(true);
    switch (value) {
      case 0:
        setType(TAB_TRANSACTION.ALL.name);
        break;
      case 1:
        setType(TAB_TRANSACTION.INCOME.name);
        break;
      case 2:
        setType(TAB_TRANSACTION.EXPRNDITURE.name);
        break;
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchInput(value);
    setPage(1);
    setIsRefreshing(false);

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    const delay = value === "" ? 300 : 800;
    debounceTimeoutRef.current = setTimeout(() => {
      dispatch(getListTransactionReset());
      fetchCustomerTransaction(false);
    }, delay);
  };

  const handleSelect = (value: string) => {
    setSelectDate(value);
    dispatch(saveTimeSelector(value));
    setPage(1);
    setIsRefreshing(false);
  };

  const handleNavigate = (item: DataListTransaction) => {
    navigate("/detail-transaction-page", {
      state: {
        statementId: item.statementId,
        name: item.contractCustomerName,
        stk: item.contraAccountNbr,
        bankName: item.contractBank,
        content: item.note,
        sign: item.sign,
        money: convertNumber(item.amount),
        time: item.txnTime,
        isHidden: isHidden ? isHidden : false
      },
    });
  };
  
  return (
    <div>
      <ScrollToTop/>
      <div className="sticky top-[77px] z-30 bg-white px-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Tìm kiếm giao dịch"
            value={searchInput}
            onChange={handleSearchChange}
            className="px-4 py-3 bg-green200 w-full rounded-2xl pl-12 focus:outline-none"
          />
          <Search className="absolute top-3 left-4" />
        </div>
      </div>
      <TabsChild onTabClick={handleTabs}>
        <TabChild label={TAB_TRANSACTION.ALL.lable}>
          <ListTransaction
            transactions={transactionData}
            onSelectValue={handleSelect}
            handleNavigate={handleNavigate}
            defaultDate={selectDate}
          />
        </TabChild>
        <TabChild label={TAB_TRANSACTION.INCOME.lable}>
          <ListTransaction
            transactions={transactionData}
            onSelectValue={handleSelect}
            handleNavigate={handleNavigate}
            defaultDate={selectDate}
          />
        </TabChild>
        <TabChild label={TAB_TRANSACTION.EXPRNDITURE.lable}>
          <ListTransaction
            transactions={transactionData}
            onSelectValue={handleSelect}
            handleNavigate={handleNavigate}
            defaultDate={selectDate}
          />
        </TabChild>
      </TabsChild>
      <LoadingPage isLoading={loadingPage} />
    </div>
  );
};

export default TransactionPageComponent;
