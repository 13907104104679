export const ENVIRONMENT = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
  TEST: "test",
};

export const RESPONSE_STAUS_CODE = {
  SUCCESS: { code: 200, name: "success" },
  CREATE: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  INTERNAL_SERVER_ERROR: 500,
};

export const DATE_SELECTOR = [
  { code: 1, name: "7 ngày" },
  { code: 2, name: "14 ngày" },
  { code: 3, name: "30 ngày" },
  { code: 4, name: "3 tháng" },
  { code: 5, name: "6 tháng" },
];

export const DATE_SELECTOR_TRANSACTION = [
  { code: 1, name: "Hôm nay" },
  { code: 2, name: "7 ngày" },
  { code: 3, name: "30 ngày" },
];

export const FILTER_SELECT = [
  { code: 1, name: "Hôm nay", unti: "day" },
  { code: 2, name: "Tuần này", unti: "week" },
  { code: 3, name: "Tháng này", unti: "month" },
];

export const TAB_REPORT = {
  REVENUE: { code: 0, name: "Doanh thu" },
  ORDER: { code: 1, name: "Đơn hàng" },
};

export const CUSTOM_BAR_CHART = [
  { code: "profitAmount", name: "Doanh thu" },
  { code: "lossAmount", name: "Chi phí" },
];

export const STATUS_TOKEN = {
  TOKEN_EXPIRATION: { code: "MER010", message: "Token hết hạn" },
};

export const TIME_SELECTOR = {
  DAY: { code: 1, type: "day" },
  MONTHS: { code: 2, type: "month" },
};

export const CUSTOMER = "customer";
export const DEFAULT_TIME = "day";
export const TODAY = "Hôm nay";
export const DEFAULT_LIMIT = 10; // giới hạn 10 phần tử
export const TAB_ALL = "all";
export const DEFAULT_SKIP = 1;
export const VALUE_UNKNOWN = "Không xác định";
export const NOT_AVAILABLE = "Không có";
export const DEFAULT_DATE_REPORT = "30 ngày";

export const TAB_TRANSACTION = {
  ALL: {code: 0, name: "all", lable: "Tất cả"},
  INCOME: {code: 1, name: "income", lable: "Tiền vào"},
  EXPRNDITURE: {code: 2, name: "expenditure", lable: "Tiền ra"}
}

export const EXTRACTION = {
  YES: {code: 0, name: "Y", lable: "Đồng ý"},
  NO: {code: 1, name: "N", lable: "Từ chối"}
}
