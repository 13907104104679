import { takeEvery, call, put } from "redux-saga/effects";
import {
  GET_LIST_CUSTOMER,
  GET_LIST_CUSTOMER_DETAIL,
  GET_LIST_STATEMENT_CUSTOMER,
  GET_LIST_STATEMENT_CUSTOMER_NEWEST,
  getListCustomerFail,
  getListCustomerFailDetail,
  getListCustomerSuccess,
  getListCustomerSuccessDetail,
  getListStatementCustomerFail,
  getListStatementCustomerSuccess,
  getListStatementNewestSuccess,
} from "../../actions/CustomerAction/CustomerAction";
import { getListCustomer, getListStatementOfCustomer, getListStatementOfCustomerNewest } from "../../../api/Customer";
import { FailRespone, GetListCustomerAction, ResponseData } from "../../../types/Customer";
import { RESPONSE_STAUS_CODE } from "../../../appConstants";

function* fetchListCustomer(action: GetListCustomerAction) {
  try {
    const res: ResponseData = yield call(getListCustomer, action.payload);
    
    if (res?.status === RESPONSE_STAUS_CODE.SUCCESS.name && res?.data) {
      yield put(getListCustomerSuccess(res?.data, action.isNewPage));
    } else {
      const errorData = res?.error as FailRespone;
      yield put(getListCustomerFail(errorData))
    }
  } catch (error: any) {
    const errorData = error?.response?.data?.error as FailRespone;
    yield put(getListCustomerFail(errorData))
  }
}

function* fetchListCustomerDetail(action: GetListCustomerAction) {
  try {
    const res: ResponseData = yield call(getListCustomer, action.payload);
    if (res?.status === RESPONSE_STAUS_CODE.SUCCESS.name && res?.data) {
      yield put(getListCustomerSuccessDetail(res?.data));
    } else {
      const errorData = res?.error as FailRespone;
      yield put(getListCustomerFailDetail(errorData))
    }
  } catch (error) {
    throw error;
  }
}

function* fetchListStatementCustomer(action: any) {
  try {
    const apiToCall = 
      action.type === GET_LIST_STATEMENT_CUSTOMER 
        ? getListStatementOfCustomer 
        : getListStatementOfCustomerNewest;
    const res: ResponseData = yield call(apiToCall, action.payload);
    
    if (res?.status === RESPONSE_STAUS_CODE.SUCCESS.name && res?.data) {
      if (action.type === GET_LIST_STATEMENT_CUSTOMER) {
        yield put(getListStatementCustomerSuccess(res?.data, action?.selectDate));
      } else {
        yield put(getListStatementNewestSuccess(res?.data, action?.selectDate));
      }
    } else {
      const errorData = res?.error as FailRespone;
      yield put(getListStatementCustomerFail(errorData))
    }
  } catch (error) {
    throw error;
  }
}

export default function* CustomerSaga() {
  yield takeEvery(GET_LIST_CUSTOMER, fetchListCustomer);
  yield takeEvery(GET_LIST_CUSTOMER_DETAIL, fetchListCustomerDetail);
  yield takeEvery(GET_LIST_STATEMENT_CUSTOMER, fetchListStatementCustomer);
  yield takeEvery(GET_LIST_STATEMENT_CUSTOMER_NEWEST, fetchListStatementCustomer);
}
