import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import RootReducer from "./reducers/RootReducer";
import { rootSaga } from "./saga/RootSaga";

// Khởi tạo saga middleware
const sagaMiddleware = createSagaMiddleware();

// Tạo store

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  RootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

export default store;
