import { UpdateInforCustomerAction, UpdateInforCustomerActionFail } from "../../../types/InforCustomer";

export const UPDATE_INFORCUSTOMER = "UPDATE_INFORCUSTOMER";
export const UPDATE_INFORCUSTOMER_SUCCESS = "UPDATE_INFORCUSTOMER_SUCCESS";
export const UPDATE_INFORCUSTOMER_FAIL = "UPDATE_INFORCUSTOMER_FAIL";

export const updateInforCustomer = (payload: UpdateInforCustomerAction) => {
    return {
      type: UPDATE_INFORCUSTOMER,
      payload,
    };
  };
  export const updateInforCustomerSuccess = (payload: string) => {
    return {
      type: UPDATE_INFORCUSTOMER_SUCCESS,
      payload,
    };
  };
  export const updateInforCustomerFail = (payload: UpdateInforCustomerActionFail) => {
    return {
      type: UPDATE_INFORCUSTOMER_FAIL,
      payload,
    };
  };