import React from 'react'
import { IconLeft, RightIcon, Facebook, Instagram, Mail, Twiter} from "../../assets/Icons/index";
import { GooglePlay, AppStore } from "../../assets/Images/index";
import { useNavigate } from 'react-router-dom';

const StoreSetup: React.FC = () => {
    const navigate = useNavigate();

    const handleNavigate = (path: string) => {
        navigate(path);
    };

  return (
    <div className='p-6 not-italic'>
        <header>
            <a href="/home-page"><IconLeft/></a>
        </header>
        <main>
            <section className='mt-4'>
                <h2 className='text-2xl font-extrabold leading-8'>Cài đặt</h2>
                <div className='flex flex-col space-y-4 mt-6'>
                    <div 
                        className='flex items-center justify-between' 
                        onClick={() => handleNavigate("/store")}
                    >
                        <button className='text-xl font-bold leading-8' >
                            Thông tin cửa hàng
                        </button>
                        <RightIcon/>
                    </div>
                    <div 
                        className='flex items-center justify-between' 
                        onClick={() => handleNavigate("/terms-policy")}
                    >
                        <button className='text-xl font-bold leading-8'>
                            Điều khoản & chính sách
                        </button>
                        <RightIcon/>
                    </div>
                    <div 
                        className='flex items-center justify-between'
                        onClick={() => handleNavigate("")}
                    >
                        <button className='text-xl font-bold leading-8'>
                            Liên hệ hỗ trợ
                        </button>
                        <RightIcon/>
                    </div>
                </div>
            </section>
            <section className='mt-32 flex flex-col text-center space-y-4'>
                <h4 className='text-lg font-bold leading-7 text-black'>Theo dõi chúng tôi tại</h4>
                <div className='flex justify-between'>
                    <div className='bg-green200 rounded-2xl p-4'><Facebook/></div>
                    <div className='bg-green200 rounded-2xl p-4'><Instagram/></div>
                    <div className='bg-green200 rounded-2xl p-4'><Mail/></div>
                    <div className='bg-green200 rounded-2xl p-4'><Twiter/></div>
                </div>
                <p className='text-sm text-gray text-center font-medium leading-5 px-4'>
                    Miniapp mSeller là ứng dụng quản lý dòng tiền thông minh dành cho các hộ kinh doanh
                </p>
                <div className='space-y-3'>
                    <p className='text-sm font-medium leading-5 text-gray'>Trải nghiệm đầy đủ tính năng tại đây</p>
                    <div className='flex items-center justify-center gap-3'>
                        <img src={AppStore} alt="" />
                        <img src={GooglePlay} alt="" />
                    </div>
                </div>
                <span className='text-xs font-normal leading-4'>© Một sản phẩm của MB Bank</span>
                <span className='text-sm text-gray500 leading-5 font-semibold'>
                    Phiên bản hiện tại: <span className='text-black'>v30.25</span>
                </span>
            </section>
        </main>
        <footer className='mt-6'>
            <button className='text-base text-red400 font-bold leading-6 w-full text-center'>Xóa cửa hàng</button>
        </footer>
    </div>
  )
}

export default StoreSetup
