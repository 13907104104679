import { TODAY } from "../../../appConstants";
import {
  ListStatementAction,
} from "../../../types/Transaction";
import {
  SAVE_TIME_SELECTOR,
} from "../../actions/ListTransactionActions/ListTransaction";

interface ListTransactionCustomerState {
  timeSelector: string
}

const initialState: ListTransactionCustomerState = {
  timeSelector: TODAY
};

const SelectorDateReducer = (
  state = initialState,
  action: ListStatementAction
): ListTransactionCustomerState => {
  switch (action.type) {
    case SAVE_TIME_SELECTOR: 
      return {
        ...state,
        timeSelector: action.payload
      }
    default:
      return state;
  }
};

export default SelectorDateReducer;