import React, { useEffect } from "react";
import MenuItem from "./MenuItem";
import {
  ChartIcon,
  HomeIcon,
  ReceiptIcon,
  UserIcon,
} from "../../assets/Icons/IconFooterSVG";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveTimeSelector } from "../../redux/actions/ListTransactionActions/ListTransaction";
import { TODAY } from "../../appConstants";

const Footer: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  
  const path = location.pathname;

  useEffect(() => {
    if (path !== "/transaction") {
      dispatch(saveTimeSelector(TODAY));
    }
  }, [location.pathname, path, dispatch]);
  
  return (
    <div className="fixed bottom-0 start-0 px-6 py-3 w-full not-italic bg-white z-30">
      <ul className="flex items-center justify-between">
        <MenuItem
          path="/home-page"
          label="Trang chủ"
          icon={<HomeIcon isActive={false} />}
        />
        <MenuItem
          path="/report"
          label="Báo cáo"
          icon={<ChartIcon isActive={false} />}
        />
        <MenuItem
          path="/transaction"
          label="Lịch sử"
          icon={<ReceiptIcon isActive={false} />}
        />
        <MenuItem
          path="/customer"
          label="Khách hàng"
          icon={<UserIcon isActive={false} />}
        />
      </ul>
    </div>
  );
};

export default Footer;
