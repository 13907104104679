import { TODAY } from "../../../appConstants";
import {
  ListStatementAction,
  TransactionProps,
} from "../../../types/Transaction";
import {
  GET_LIST_TRANSACTION_FAIL,
  GET_LIST_TRANSACTION_SUCCESS,
  GET_LIST_TRANSACTION_RESET,
  GET_LIST_TRANSACTION_NEWEST_SUCCESS,
  SAVE_TIME_SELECTOR,
} from "../../actions/ListTransactionActions/ListTransaction";

interface ListTransactionCustomerState {
  data: TransactionProps[];
  loading: boolean;
  timeSelector: string
}

const initialState: ListTransactionCustomerState = {
  data: [],
  loading: false,
  timeSelector: TODAY
};

const ListTransactionReducer = (
  state = initialState,
  action: ListStatementAction
): ListTransactionCustomerState => {
  switch (action.type) {
    case GET_LIST_TRANSACTION_SUCCESS:
      return {
        ...state,
        data: action.isNewPage ? [...state.data, ...action.payload] : action.payload,
        timeSelector: action?.selectDate
      };
    case GET_LIST_TRANSACTION_NEWEST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        timeSelector: action?.selectDate
      };
    case SAVE_TIME_SELECTOR: 
      return {
        ...state,
        timeSelector: action.payload
      }
    case GET_LIST_TRANSACTION_FAIL:
      return {
        ...state,
      };
    case GET_LIST_TRANSACTION_RESET:
      return initialState;
    default:
      return state;
  }
};

export default ListTransactionReducer;