import {
  GetListGroupActionType,
  GroupCustomerProp,
} from "../../../types/Customer";
import {
  GET_LIST_GROUP,
  GET_LIST_GROUP_FAIL,
  GET_LIST_GROUP_SUCCESS,
} from "../../actions/ListGroupActions/ListGroup";

interface ListGroupState {
  data: GroupCustomerProp[];
  loading: boolean
}

const initialState: ListGroupState = {
  data: [],
  loading: true
};

const ListGroupReducer = (
  state = initialState,
  action: GetListGroupActionType
): ListGroupState => {
  switch (action.type) {
    case GET_LIST_GROUP:
      return {
        ...state,
      }
    case GET_LIST_GROUP_SUCCESS:
      return {
        ...state,
        data: action?.payload,
        loading: false
      };
    case GET_LIST_GROUP_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default ListGroupReducer;
