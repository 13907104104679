import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { LeftBlack, Person, Payment, Phone } from "../../assets/Icons";
import { LightBulb } from "../../assets/Icons/IconSVG";
import IconInput from "../../components/IconInput/IconInput";
import { useDispatch } from "react-redux";
import { updateInforCustomer } from "../../redux/actions/InforCustomerActions/InforCustomer";
import { getBankTitle } from "../../components/Mapping/MappingBankName/MappingBankName";
import MappingBankLogo from "../../components/Mapping/MappingBankLogo/MappingBankLogo";

const InforCustomer: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const customer = JSON.parse(localStorage.getItem("customer") ?? "");
  
  const {
    customerName, 
    customerPhoneNumber, 
    customerAccountNbr, 
    customerId, 
    customerBank
  } = customer;
  
  const [value, setValue] = useState<string>(location?.state?.customerNickname);
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };
  const getIconClassName = (value: string) => {
    return `absolute top-3.5 left-3 ${
      value ? "fill-green500" : "fill-gray"
    } peer-focus:fill-green500`;
  };

  const handleFormSubmit = () => {
    const formData = {
      customerId: customerId,
      customerNickname: value,
      customerPhoneNumber: customerPhoneNumber
    }

    dispatch(updateInforCustomer(formData));
    navigate("/detail-customer", {state: {customerId: customerId}})
  }

  return (
    <>
      <div className="relative h-screen">
        <header className="flex justify-start items-center p-4 ">
          <div
            className="px-2 flex justify-center items-center "
            onClick={() => {
              navigate("/detail-customer");
            }}
          >
            <LeftBlack />
          </div>
          <div className="text-xl font-extrabold flex justify-center content-center text-center w-full mr-9">
            Thông tin khách hàng
          </div>
        </header>
        <form action="">
          <main className="px-6 py-4">
            <div className="relative  ">
              <Person className="absolute top-3.5 left-3" />
              <input
                type="text"
                value={customerName}
                readOnly
                className="rounded-2xl bg-green200 pl-12 p-4 w-full mb-4 text-base font-semibold focus:outline-1 focus:outline-green500"
              />
            </div>
            <div className="relative ">
              <IconInput
                placeholder="Tên gợi nhớ..."
                icon={<LightBulb className={getIconClassName(value)} />}
                value={value}
                name=""
                type="text"
                onChange={handleChange}
              />
            </div>
            <div className="relative ">
              <Phone className="absolute top-3.5 left-3 " />
              <input
                type="number"
                readOnly
                value={customerPhoneNumber}
                className="rounded-2xl bg-green200 pl-12 p-4 w-full mb-4 text-base font-semibold focus:outline-1 focus:outline-green500"
              />
            </div>

            <div className="rounded-2xl bg-green200 p-4 w-full mb-4">
              <div className="flex gap-2 items-center text-base font-semibold pb-4 mb-4 border-b border-gray100">
                <Payment className="w-[24px] h-[24px]" />
                <p>Tài khoản ngân hàng</p>
              </div>
              <div>
                <div className="">
                  <div className="flex gap-2.5 items-center">
                    <div className="flex justify-center items-center">
                      <MappingBankLogo bankCode={customerBank} className="w-7"/>
                    </div>
                    <div className="flex justify-between item-start border-b border-gray100">
                      <div>
                        <p className="font-bold ">{getBankTitle(customerBank, "vi")} | STK: {customerAccountNbr}</p>
                        <p className="text-gray">{customerName}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <footer className="absolute bottom-6 px-6 w-full">
            <div className="flex item-center justify-center gap-[12px] w-full">
              <button
                type="button"
                className="py-[16px] px-[10px] bg-green200 text-green500 font-bold rounded-2xl w-full"
                onClick={() => {
                  navigate("/detail-customer");
                }}
              >
                Quay lại
              </button>

              <button
                type="button"
                className="py-[16px] px-[10px] bg-green500 text-white font-bold rounded-2xl w-full"
                onClick={handleFormSubmit}
              >
                Lưu
              </button>
            </div>
          </footer>
        </form>
      </div>
    </>
  );
};

export default InforCustomer;
