import React, { useCallback, useEffect, useState } from "react";
import { LeftWhite } from "../../assets/Icons";
import { DATE_SELECTOR, DEFAULT_LIMIT, DEFAULT_SKIP, DEFAULT_DATE_REPORT, EXTRACTION, TAB_ALL, TODAY } from "../../appConstants";

import { differenceInDays, parse } from "date-fns";

import { useNavigate } from "react-router-dom";
import CustomDropdown from "../../components/FilterSelect/FilterSelect";
import CustomChart from "../../components/chart/ColumnsChart/CustomChart";
import { convertNumber, formatDateReport, generateRefNo, openBrowser } from "../../appCommon";

import { useDispatch, useSelector } from "react-redux";

import LoadingPage from "../../components/Loading/Loading";
import { subMonths } from "date-fns";
import { DateProps, TransactionProps } from "../../types/Transaction";
import { customerReportSelector } from "../../redux/selector/CustomerSelector/CustomerReport";
import { getCustomerReport } from "../../redux/actions/CustomerAction/CustomerReportAction";
import { formatDateParams } from "../../common/date";
import { exportCustomerTranscation } from "../../api/Transaction";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import InformationCustomerDetail from "./InformationCustomerDetail";
import { getListStatementCustomer } from "../../redux/actions/CustomerAction/CustomerAction";
import { listStatementCustomerDetail } from "../../redux/selector/CustomerSelector/Customer";
import { LoadingSelector } from "../../redux/selector/LoadingSelector";
import { saveTimeSelector } from "../../redux/actions/ListTransactionActions/ListTransaction";

const DetailCustomer: React.FC = () => {
  const [selectDate, setSelectDate] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lisDataStatementCustomer = useSelector(listStatementCustomerDetail);
  const informationMerchant = JSON.parse(
    sessionStorage.getItem("informationMerchant") ?? ""
  );
  const customer = JSON.parse(localStorage.getItem("customer") ?? "");
  const [lastStatementDate, setLastStatementDate] = useState<number>(0);
  const dataCustomerReport = useSelector(customerReportSelector);
  const isLoading = useSelector(LoadingSelector);
  const { customerAccountNbr, customerBank, customerName,} = customer || {}; 

  const fetchCustomerTransaction = useCallback(() => {
    const today = formatDateParams(new Date());
    const fiveMonths = formatDateParams(subMonths(new Date(), 5));
    const searchObject = {
      customerAccount: informationMerchant?.merchantAccountNbr,
      contractBank: customerBank,
      contractAccountNbr: customerAccountNbr,
      contractCustomerName: customerName,
      fromDate: fiveMonths,
      toDate: today,
      skip: DEFAULT_SKIP,
      limit: DEFAULT_LIMIT - 5, // Lấy 5 giao dịch gần nhất
      transactionType: TAB_ALL,
      extraction: EXTRACTION.YES.name,
    };
    dispatch(getListStatementCustomer(searchObject));
  }, [customerAccountNbr]);

  const fetchCustomerReport = () => {
    const fromToDate: DateProps = formatDateReport(
      selectDate,
      DEFAULT_DATE_REPORT
    );

    const searchObject = {
      customerAccount: informationMerchant?.merchantAccountNbr,
      contractBank: customerBank,
      contractAccountNbr: customerAccountNbr,
      contractCustomerName: customerName,
      fromDate: fromToDate.fromDate,
      toDate: fromToDate.toDate,
      skip: DEFAULT_SKIP,
    };
    dispatch(getCustomerReport(searchObject));
  };

  useEffect(() => {
    fetchCustomerTransaction();
    fetchCustomerReport();
  }, [selectDate]);
  
  useEffect(() => {
    if (lisDataStatementCustomer?.length > 0) {
      const days = calculateLastStatementDate(lisDataStatementCustomer);
      setLastStatementDate(days);
    }
  }, [lisDataStatementCustomer]);

  const calculateLastStatementDate = (transactions: TransactionProps[]) => {
    if (!transactions || transactions?.length === 0) return 0;
    try {
      // get the first transaction time
      const firstTransaction = transactions[0];
      const txnTime = firstTransaction.txnTime;

      const transactionDate = parse(txnTime, "dd/MM/yyyy HH:mm:ss", new Date());

      const daysDifference = differenceInDays(new Date(), transactionDate);

      return daysDifference;
    } catch (error) {
      return 0;
    }
  };

  const handleExportExcel = async () => {
    const fromToDate: DateProps = formatDateReport(selectDate, TODAY);

    const searchObject = {
      customerAccount: informationMerchant?.merchantAccountNbr,
      fromDate: fromToDate.fromDate,
      toDate: fromToDate.toDate,
      skip: DEFAULT_SKIP,
      limit: DEFAULT_LIMIT,
      transactionType: TAB_ALL,
      extraction: EXTRACTION.YES.name,
      contractBank: customerBank,
      contractAccountNbr: customerAccountNbr,
      contractCustomerName: customerName,
    };

    const { config } = await exportCustomerTranscation(searchObject);
    const stringifiedSearchObject = Object.fromEntries(
      Object.entries(searchObject).map(([key, value]) => [key, String(value)])
    );
    
    const token = typeof config?.headers?.Authorization === 'string'
      ? config?.headers?.Authorization.split(' ')[1]
      : undefined;

    const refNo = generateRefNo();
    
    const url = `${config.url}?${new URLSearchParams(stringifiedSearchObject).toString()}&token=${token}&refNo=${refNo}`;
  
    openBrowser(url); 
  };

  return (
    <>
      <div className="bg-green500">
        <header className="flex justify-start items-center mx-6 h-[100px] ">
          <div
            className="px-2 flex justify-center items-center "
            onClick={() => {
              navigate("/customer", {state: {isBackCustomer: true}});
            }}
          >
            <LeftWhite />
          </div>
          <div className="text-xl text-white font-extrabold flex justify-center content-center text-center w-full mr-9">
            Chi tiết khách hàng
          </div>
        </header>
        <main className="bg-white rounded-tl-[32px] rounded-tr-[32px]">
          <section>
            <InformationCustomerDetail 
              customerId={customer?.customerId}
              lastStatementDate={lastStatementDate}
              handleExportExcel={handleExportExcel}
            />
          </section>
          <section className="px-6">
            <p className="text-lg font-extrabold mb-[16px]">
              Báo cáo dòng tiền
            </p>
            <div className="flex justify-between items-start">
              <div>
                <p>Tổng doanh thu</p>
                <p className="flex gap-1 items-start ">
                  <span className="font-bold">đ</span>

                  <span className="text-2xl font-extrabold">
                    {convertNumber(dataCustomerReport?.totalIncome)}
                  </span>
                </p>
              </div>
              <CustomDropdown
                valueSelect={(value: string) => setSelectDate(value)}
                minDay={true}
                isSelectTime={true}
                filterOption={DATE_SELECTOR}
              />
            </div>
            <div className="w-full overflow-x-auto scrollbar scrollbar-thumb-white">
              <CustomChart
                customerReport={dataCustomerReport?.incomes}
                type={dataCustomerReport?.type}
              />
            </div>
          </section>
          <section className="px-3">
            <div className="shadow-custom p-4 rounded-2xl">
              <div className="flex justify-between items-center mb-[24px]">
                <p className="text-lg font-extrabold">Giao dịch gần đây</p>
                <p
                  className="text-green500"
                  onClick={() => {
                    dispatch(saveTimeSelector(TODAY));
                    navigate("/transaction", { 
                      state: { 
                        isHidden: true,
                        contractBank: customerBank,
                        contractAccountNbr: customerAccountNbr,
                        contractCustomerName: customerName,
                      }
                    })
                  }}
                >
                  Xem tất cả
                </p>
              </div>
              <div>
                <InfiniteScroll
                  listData={lisDataStatementCustomer} 
                  isNickName={true}
                  isIcons={true}
                  isDetailCustomer={true}
                /> 
              </div>
            </div>
          </section>
        </main>
      </div>
      <LoadingPage isLoading={isLoading} />
    </>
  );
};

export default DetailCustomer;
