import { takeEvery, call, put } from 'redux-saga/effects';
import { 
    GET_REPORT, 
    GET_TOP_CUSTOMER, 
    getReportFail, 
    getReportSuccess, 
    getTopCustomerFail, 
    getTopCustomerSuccess 
} from '../../actions/ReportActions/Report';
import { getReport, getTopCustomer } from '../../../api/ReportApi';
import { GetReportAction, GetTopCustomerAction, ReportActionFail, ReportResponseData, TopCustomerResponseData } from '../../../types/ReportType';
import { RESPONSE_STAUS_CODE } from '../../../appConstants';

function* fetchReport(action: GetReportAction) {    
    try {
        const res:ReportResponseData = yield call(getReport, action.payload);

        if (res?.status === RESPONSE_STAUS_CODE.SUCCESS.name && res?.data) {
            yield put(getReportSuccess(res?.data))
        } else {
            const errorData = res?.error as ReportActionFail;
            yield put(getReportFail(errorData))
        }
    } catch (error) {
        throw error;
    }
}

function* fetchTopCustomer(action: GetTopCustomerAction) {    
    try {
        const res:TopCustomerResponseData = yield call(getTopCustomer, action.payload)    
        if (res?.status === RESPONSE_STAUS_CODE.SUCCESS.name && res?.data) {
            yield put(getTopCustomerSuccess(res?.data))
        } else {
            const errorData = res?.error as ReportActionFail;
            yield put(getTopCustomerFail(errorData))
        }
    } catch (error) {
        throw error;
    }
}

export default function* ReportgeSaga() {
    yield takeEvery(GET_REPORT, fetchReport);
    yield takeEvery(GET_TOP_CUSTOMER, fetchTopCustomer);
}
