import React from "react";
import FromStatement from "../FromStatement/FromStatement";
import CustomDropdown from "../FilterSelect/FilterSelect";
import { DATE_SELECTOR_TRANSACTION } from "../../appConstants";
import { convertNumber } from "../../appCommon";
import { TransactionProps } from "../../types/Transaction";
import InfinteScrollStatement from "../InfiniteScroll/InfinteScrollStatement";

interface StatementProp {
  onSelectValue: (value: string) => void;
  transactions: TransactionProps[];
}

const ListStatement: React.FC<StatementProp> = ({
  onSelectValue,
  transactions,
}) => {
  const isTransactions = Array.isArray(transactions) && transactions.length > 0;
  
  return (
    <div className="py-4 mx-4 px-4 bg-white rounded-2xl shadow-custom">
      <div className="flex justify-between items-center mb-4">
        <p className="text-lg font-extrabold">Tất cả sao kê</p>
        <CustomDropdown
          valueSelect={(value: string) => onSelectValue(value)}
          minDay={true}
          isSelectTime={true}
          filterOption={DATE_SELECTOR_TRANSACTION}
        />
      </div>
      <div>
        {isTransactions ? (
          <>
            <InfinteScrollStatement listData={transactions}/>
          </>
        ) : (
          <>
            <div className="flex w-full justify-center text-gray500 font-medium leading-5">
              Chưa có giao dịch nào
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ListStatement;
