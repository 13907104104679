import { takeEvery, call, put } from 'redux-saga/effects';
import { RESPONSE_STAUS_CODE } from '../../../appConstants';
import { 
    GET_INFORMATION_MERCHANT, 
    getInformationMerchantFail, 
    getInformationMerchantSuccess, 
    updateInformationMerchantFail, 
    updateInformationMerchantSuccess, 
    UPDTAE_INFORMATION_MERCHANT
} from '../../actions/StoreSetupActions/StoreSetup';
import { getInformationMerchant, updateInformationMerchant } from '../../../api/StoreSetup';
import { InformationMerchantFailureResponse, Response, UpdateInformationMerchant } from '../../../types/StoreInfor';

function* fetchInformationMerchant() {    
    try {
        const res:Response | undefined = yield call(getInformationMerchant);

        if (res?.status === RESPONSE_STAUS_CODE.SUCCESS.name) {
            if (res?.data) {
                yield put(getInformationMerchantSuccess(res?.data));
            } else {
                const errorMessage = res?.error as InformationMerchantFailureResponse;
                yield put(getInformationMerchantFail(errorMessage))
            }
        }
    } catch (error) {
        throw error;
    }
}

function* editInformationMerchant(action: UpdateInformationMerchant) {   
    try {
        const res:Response | undefined = yield call(updateInformationMerchant, action.payload) 

        if (res?.status === RESPONSE_STAUS_CODE.SUCCESS.name && res?.data) {
            yield put(updateInformationMerchantSuccess(res?.data));
        } else {
            const errorData = res?.error as InformationMerchantFailureResponse;
            yield put(updateInformationMerchantFail(errorData));
        }
    } catch (error) {
        throw error;
    }
}

export default function* StoreSetupSaga() {
    yield takeEvery(GET_INFORMATION_MERCHANT, fetchInformationMerchant);
    yield takeEvery(UPDTAE_INFORMATION_MERCHANT, editInformationMerchant);
}
