import { InforCustomerActions } from "../../../types/InforCustomer";
import { 
  UPDATE_INFORCUSTOMER, 
  UPDATE_INFORCUSTOMER_FAIL, 
  UPDATE_INFORCUSTOMER_SUCCESS 
} from "../../actions/InforCustomerActions/InforCustomer";

interface InforCustomerState {
  inforCustomer: string
  loading: boolean
}

const initialState: InforCustomerState = {
  inforCustomer: "",
  loading: true,
};

const InforCustomerReducer = (
  state = initialState,
  action: InforCustomerActions
): InforCustomerState => {
  switch (action.type) {
    case UPDATE_INFORCUSTOMER: 
      return {
        ...state,
        loading: true,
      }
    case UPDATE_INFORCUSTOMER_SUCCESS:
      return {
        ...state,
        inforCustomer: action.payload,
        loading: false
      };
    case UPDATE_INFORCUSTOMER_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default InforCustomerReducer;
