import React from 'react';
import { ErrorLaptop } from "../../assets/Images/index";
import { useNavigate } from 'react-router-dom';

const NoDataFound: React.FC = () => {
    const navigate = useNavigate();

    const handleBack = () => {
      navigate(-1);  // Quay lại trang trước
    };

  return (
    <div className='px-6 py-8 not-italic flex flex-col justify-center'>
      <div className='relative'>
        <img src={ErrorLaptop} alt="" />
        <div className='flex flex-col justify-center text-center absolute top-[90%]'>
          <div className='px-6'>
            <h3 className='text-2xl font-extrabold leading-8 pb-1'>Lỗi truy cập</h3>
            <p className='text-base font-medium leading-6 text-gray500'>
                Đã xảy ra lỗi trong quá trình truy cập. Bạn vui lòng thử lại nhé
            </p>
          </div>    
          <button
            onClick={handleBack}
            className='mt-8 py-3.5 text-center bg-green500 text-white w-full text-base font-bold rounded-2xl leading-6'
          >
            Thử lại
          </button>
        </div>
      </div>
    </div>
  )
}

export default NoDataFound
