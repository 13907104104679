import { combineReducers } from "redux";
import StoreSetupReducer from "./StoreSetupReducer/StoreSetup";
import HomePageReducer from "./HomePage/HomePage";
import ListGroupReducer from "./ListGroupReducer/ListGroup";
import ListTransactionReducer from "./ListTransacitonReducer/ListTransaction";
import ReportReducer from "./ReportReducer/Report";
import OnboardReducer from "./OnboardReducer/Onboard";
import CustomerReducer from "./CustomerReducer/Customer";
import loadingReducer from "./LoadingReducer";
import getCustomerReportReducer from "./CustomerReducer/CustomerReport";
import InforCustomerReducer from "./InforCustomerReducer/InforCustomer";
import IntroInformationReducer from "./IntroInformationReducer/IntroInformation";
import SelectorDateReducer from "./ListTransacitonReducer/SelectorDate";

const RootReducer = combineReducers({
  storeSetup: StoreSetupReducer,
  customer: CustomerReducer,
  listGroup: ListGroupReducer,
  customerReport: getCustomerReportReducer,
  listTransaction: ListTransactionReducer,
  homePage: HomePageReducer,
  report: ReportReducer,
  onboard: OnboardReducer,
  loading: loadingReducer,
  inforCustomer: InforCustomerReducer,
  introInformation: IntroInformationReducer,
  dateSelector: SelectorDateReducer
});

export default RootReducer;
