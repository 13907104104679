const bankIcon = {
    imgABBANK: require("./Images/bank/img_abbank.png"),
    imgACB: require("./Images/bank/img_acb.png"),
    imgANZ: require("./Images/bank/img_anz.png"),
    imgBANGKOK: require("./Images/bank/img_bangkok.png"),
    imgBIDC: require("./Images/bank/img_bidc.png"),
    imgBIDV: require("./Images/bank/img_bidv.png"),
    imgBNP_PARIBAS: require("./Images/bank/img_bnp_paribas.png"),
    imgBUSAN: require("./Images/bank/img_busan.png"),
    imgBVB: require("./Images/bank/img_bvb.png"),
    imgCCB: require("./Images/bank/img_ccb.png"),
    imgCCF: require("./Images/bank/img_ccf.png"),
    imgCHINA: require("./Images/bank/img_china.png"),
    imgCHINATRUST: require("./Images/bank/img_chinatrust.png"),
    imgCHINESE: require("./Images/bank/img_chinese_ocbc.png"),
    imgCIMB: require("./Images/bank/img_cimb.png"),
    imgCITIBANK: require("./Images/bank/img_citibank.png"),
    imgCITIBANKHN: require("./Images/bank/img_citibank.png"),
    imgCITIBANKHCM: require("./Images/bank/img_citibank.png"),
    imgCOMERCIAL: require("./Images/bank/img_comercial.png"),
    imgCOMMONWEALTH: require("./Images/bank/img_commonwealth.png"),
    imgCOMMUNICATION: require("./Images/bank/img_communication.png"),
    imgCSXH: require("./Images/bank/img_csxh.png"),
    imgCTBC: require("./Images/bank/img_tcb.png"),
    imgCUBHCM: require("./Images/bank/img_cathay.png"),
    imgDAB: require("./Images/bank/img_dab.png"),
    imgDBS: require("./Images/bank/img_dbs.png"),
    imgDEUTSCHE: require("./Images/bank/img_deutsche.png"),
    imgEIB: require("./Images/bank/img_eib.png"),
    imgESUN: require("./Images/bank/img_esun.png"),
    imgGPB: require("./Images/bank/img_gpb.png"),
    imgHANA: require("./Images/bank/img_hana.png"),
    imgHDB: require("./Images/bank/img_hdb.png"),
    imgHLB: require("./Images/bank/img_hlb.png"),
    imgHSBC: require("./Images/bank/img_hsbc.png"),
    imgHSBCHN: require("./Images/bank/img_hsbc.png"),
    imgHUA_NAN: require("./Images/bank/img_hua_nan.png"),
    imgIBK_HN: require("./Images/bank/img_ibk.png"),
    imgIBK: require("./Images/bank/img_ibk.png"),
    imgIBK_HCM: require("./Images/bank/img_ibk.png"),
    imgICBC: require("./Images/bank/img_icbc.png"),
    imgINDIA: require("./Images/bank/img_india.png"),
    imgIVB: require("./Images/bank/img_ivb.png"),
    imgKLB: require("./Images/bank/img_klb.png"),
    imgKOOKMIN_CN_HCM: require("./Images/bank/img_kookmin.png"),
    imgKOOKMIN_CN_HN: require("./Images/bank/img_kookmin.png"),
    imgKOOKMIN: require("./Images/bank/img_kookmin.png"),
    imgKOREA: require("./Images/bank/img_hana.png"),
    imgLPB: require("./Images/bank/img_lpb.png"),
    imgMALAYAN: require("./Images/bank/img_malayan.png"),
    imgMAYBANK: require("./Images/bank/img_maybank.png"),
    imgMB: require("./Images/bank/img_mb.png"),
    imgMBS: require("./Images/bank/img_msb.png"),
    imgMCB: require("./Images/bank/img_mcb.png"),
    imgMEGA: require("./Images/bank/img_mega.png"),
    imgMHB: require("./Images/bank/img_mhb.png"),
    imgMIZUHO: require("./Images/bank/img_mizuho.png"),
    imgMSB: require("./Images/bank/img_msb.png"),
    imgNAMABANK: require("./Images/bank/img_namabank.png"),
    imgNASB: require("./Images/bank/img_nasb.png"),
    imgNATIXIS: require("./Images/bank/img_natixis.png"),
    imgNCB: require("./Images/bank/img_ncb.png"),
    imgNONG_HYUP: require("./Images/bank/img_nong_huyp.png"),
    imgOCB: require("./Images/bank/img_ocb.png"),
    imgOJB: require("./Images/bank/img_ojb.png"),
    imgPGB: require("./Images/bank/img_pgb.png"),
    imgPVC: require("./Images/bank/img_pvc.png"),
    imgSCB: require("./Images/bank/img_scb.png"),
    imgSEAB: require("./Images/bank/img_seab.png"),
    imgSGB: require("./Images/bank/img_sgb.png"),
    imgSHANGHAI: require("./Images/bank/img_shanghai.png"),
    imgSHB: require("./Images/bank/img_shb.png"),
    imgSHBVN: require("./Images/bank/img_shbvn.png"),
    imgSIAM: require("./Images/bank/img_siam.png"),
    imgSINPAC: require("./Images/bank/img_sinopac.png"),
    imgSMBC: require("./Images/bank/img_smbc.png"),
    imgSTANDARDCHARTERED: require("./Images/bank/img_standard_chartered.png"),
    imgSTB: require("./Images/bank/img_stb.png"),
    imgSVFC: require("./Images/bank/img_shbvn.png"),
    imgTAIPEI: require("./Images/bank/img_taipei.png"),
    imgTCB: require("./Images/bank/img_tcb.png"),
    imgTokyoMitsubishi: require("./Images/bank/img_tokyomitsubishi.png"),
    imgTPB: require("./Images/bank/img_tpb.png"),
    imgTRUSTBANK: require("./Images/bank/img_cbbank.png"),
    imgUOB: require("./Images/bank/img_uob.png"),
    imgVAB: require("./Images/bank/img_vab.png"),
    imgVBA: require("./Images/bank/img_vba.png"),
    imgVCB: require("./Images/bank/img_vcb.png"),
    imgVCCB: require("./Images/bank/img_vccb.png"),
    imgVDB: require("./Images/bank/img_vdb.png"),
    imgVIB: require("./Images/bank/img_vib.png"),
    imgVID: require("./Images/bank/img_vib.png"),
    imgVIETBANK: require("./Images/bank/img_vietbank.png"),
    imgVIETINBANK: require("./Images/bank/img_vietinbank.png"),
    imgVIETTELPAY: require("./Images/bank/img_viettelpay.png"),
    imgVPB: require("./Images/bank/img_vpb.png"),
    imgVRB: require("./Images/bank/img_vrb.png"),
    imgWoori: require("./Images/bank/img_woori.png"),
    imgMIZUHOHN: require("./Images/bank/img_mizuho.png"),
    imgMBB: require("./Images/bank/img_mb.png"),
    imgCAKEVPB: require("./Images/bank/img_cake_by_vpbank.png"),
    imgCBB: require("./Images/bank/img_cbbank.png"),
    imgUBANKVPB: require("./Images/bank/img_u_bank.png"),
    imgVNPTM: require("./Images/bank/img_vnpt_money.png"),
    imgVIETTELM: require("./Images/bank/img_viettel_money.png"),
    imgCOMERCIAL_HCM: require("./Images/bank/img_commercial.png"),
    imgCOMERCIAL_HN: require("./Images/bank/img_commercial.png"),
    imgUMEE: require("./Images/bank/img_umee.png"),
    imgKEB_HANA_HN: require("./Images/bank/img_hana.png"),
    imgKEB_HANA_HCM: require("./Images/bank/img_hana.png"),
    imgAGRICULTURALCHINA: require("./Images/bank/img_algricutural_china.png"),
    imgKASIKORNBANK_CNHCM: require("./Images/bank/img_kasikornbank.png"),
    imgLIOBANK: require("./Images/bank/img_liobank.png"),
    imgTIMO: require("./Images/bank/img_timo.png"),
    imgMAFC: require("./Images/bank/img_mafc.png"),
    imgVikki_by_HDBank: require("./Images/bank/img_vikki.png"),
    imgVietQR_NAPAS: require("./Images/bank/img_vietqr_napas.png"),
    bankDefault: require("./Images/bank/bank_fail.png"),
  };
  
  export default bankIcon;