import { takeEvery, call, put } from 'redux-saga/effects';
import { RESPONSE_STAUS_CODE } from '../../../appConstants';
import { GET_AUTHEN_TOKEN, getAuthenTokenFail, getAuthenTokenSuccess } from '../../actions/OnbroadActions/Onbroad';
import { authenToken } from '../../../api/OnboardSevices';
import { AuthTokenFailureResponse, GetAuthenTokenAction, Response } from '../../../types/OnboardTypes';

function* fetchAuthenToken(action: GetAuthenTokenAction) { 
    try {
        const res:Response = yield call(authenToken, action.payload);

        if (res?.status === RESPONSE_STAUS_CODE.SUCCESS.name && res?.data) {
            yield put(getAuthenTokenSuccess(res?.data));
        } else {
            const errorData = res?.error as AuthTokenFailureResponse;
            yield put(getAuthenTokenFail(errorData));
        }
    } catch (error) {
        throw error;
    }
}

export default function* OnboardSaga() {
    yield takeEvery(GET_AUTHEN_TOKEN, fetchAuthenToken);
}
