import React from "react";
import { RouterProvider } from "react-router-dom";
import router from "./RootRoutes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/toastStyles.css';

const App: React.FC = () => {
  return (
    <div>
      <ToastContainer position="top-right" autoClose={1000} hideProgressBar={true} closeButton={false}  limit={1}/>
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
};

export default App;
