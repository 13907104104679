import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChartStaticcal } from "../../assets/Images/index";
import { useSelector } from 'react-redux';
import { IntroInformationSelector } from '../../redux/selector/IntroInformationSelector/IntroInformation';
import { IconInCome, IconOutCome } from '../../assets/Icons';
import { convertNumber } from '../../appCommon';

const StoreInitiation: React.FC = () => {
    const IntroInformation = useSelector(IntroInformationSelector);
    const navigate = useNavigate();

    const handleNext = () => {
        navigate("/store", {state: {isBackDisabled : true}});
    };

  return (
    <div className='bg-white min-h-screen w-full px-6 py-10 flex flex-col justify-between not-italic'>
       <section>
            <p className='pt-7 leading-4 text-[#A2A0A8] text-sm font-medium mb-3'>👌 Miniapp mSeller giúp bạn</p>
            <div className='pr-10'>
                <span 
                    className='text-3xl not-italic leading-10 font-extrabold text-[#181C26]'
                >
                    Biết ngay doanh thu hôm nay, tháng này chỉ với 1 chạm.
                </span>
            </div>
            <section className='mt-8 flex flex-col justify-center bg-green100 rounded-2xl'>
                <div className='flex justify-between px-6 pt-10'>
                    <div>
                        <span className='text-gray500 text-xs font-medium leading-6'>Tiền vào</span>
                        <div className='flex items-center'>
                            <IconInCome className="w-5 h-5"/>
                            <span className='font-semibold text-sm leading-6 pl-1'>
                                {convertNumber(IntroInformation?.merchantRevenue) || 0}đ
                            </span>
                        </div>
                    </div>
                    <div>
                        <span className='text-gray500 text-xs font-medium leading-6'>Tiền ra</span>
                        <div className='flex items-center'>
                            <IconOutCome className="w-5 h-5"/>
                            <span className='font-semibold text-sm leading-6 pl-1'>
                                {convertNumber(IntroInformation?.merchantExpense) || 0}đ
                            </span>
                        </div>
                    </div>
                </div>
                <img src={ChartStaticcal} alt="" />
            </section>
       </section>
        <button 
            className='bg-green500 text-white py-3 rounded-2xl font-bold text-sm leading-6 not-italic'
            onClick={handleNext}
        >
            Bắt đầu
        </button>
    </div>
  )
}

export default StoreInitiation
