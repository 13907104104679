import { generateRefNo } from '../appCommon';
import { ReportAction, TopCustomerAction } from '../types/ReportType';
import api from './AxiosInterceptor';
const config = require('../appConfig');
const patchApi = config.API_ENPOINT + "/report/v1.0";
const patchApiCustomer = config.API_ENPOINT + "/customer/v1.0";

export const getReport = async (searchObject: ReportAction) => {
    const refNo = generateRefNo();
    const searchObjectRefNo = { ...searchObject, refNo }; 

    const url = patchApi + "/pnl-report";
    const response = await api.get(url, {params: searchObjectRefNo});
    
   return response.data; 
}

export const getTopCustomer = async (searchObject: TopCustomerAction) => {
    const refNo = generateRefNo();
    const searchObjectRefNo = { ...searchObject, refNo }; 

    const url = patchApiCustomer + "/list-customer";
    const response = await api.get(url, {params: searchObjectRefNo});

    return response.data;
}
