interface BankInfo {
    title: string;
    name: string;
  }
  
  interface BankData {
    [key: string]: {
      [lang: string]: BankInfo;
    };
  }
  
const BANK_DATA: BankData = {
    "MB": {
        "vi": {
        "title": "MBBank (MB)",
        "name": "Ngân hàng TMCP Quân đội"
        },
        "en": {
        "title": "MBBank (MB)",
        "name": "Military Commercial Joint Stock Bank (MB)"
        },
        "ko": {
        "title": "MBBank (MB)",
        "name": "군대 상업주식은행(MB)"
        }
    },
    "VBA": {
        "vi": {
        "title": "Agribank (VBA)",
        "name": "Ngân hàng Nông nghiệp và Phát triển Nông thôn Việt Nam"
        },
        "en": {
        "title": "Agribank (VBA)",
        "name": "Vietnam Bank for Agriculture and Rural Development"
        },
        "ko": {
        "title": "Agribank (VBA)",
        "name": "베트남 농업 및 농촌개발 은행"
        }
    },
    "VCB": {
        "vi": {
        "title": "Vietcombank (VCB)",
        "name": "Ngân hàng TMCP Ngoại thương Việt Nam"
        },
        "en": {
        "title": "Vietcombank (VCB)",
        "name": "Joint Stock Commercial Bank for Foreign Trade of Vietnam"
        },
        "ko": {
        "title": "Vietcombank (VCB)",
        "name": " 베트남 베트남 대외 무역 상업주식은행"
        }
    },
    "BIDV": {
        "vi": {
        "title": "BIDV",
        "name": "Ngân hàng TMCP Đầu tư và phát triển Việt Nam"
        },
        "en": {
        "title": "BIDV",
        "name": "Bank for Investment and Development of Vietnam"
        },
        "ko": {
        "title": "BIDV",
        "name": "베트남 투자 및 개발 상업주식은행"
        }
    },
    "VIETINBANK": {
        "vi": {
        "title": "Vietinbank (CTG)",
        "name": "Ngân hàng TMCP Công thương Việt Nam"
        },
        "en": {
        "title": "Vietinbank (CTG)",
        "name": "Vietnam bank for industry and trade"
        },
        "ko": {
        "title": "Vietinbank (CTG)",
        "name": "베트남 공상 상업주식은행"
        }
    },
    "VPB": {
        "vi": {
        "title": "VPBank (VPB)",
        "name": "Ngân hàng TMCP Việt Nam Thịnh Vượng"
        },
        "en": {
        "title": "VPBank (VPB)",
        "name": "Vietnam Prosperity Joint Stock Commercial Bank (VPBANK)"
        },
        "ko": {
        "title": "VPBank (VPB)",
        "name": "베트남 번영 상업주식은행 (VPBANK)"
        }
    },
    "VIB": {
        "vi": {
        "title": "VIB",
        "name": "Ngân hàng TMCP Quốc tế Việt Nam"
        },
        "en": {
        "title": "VIB",
        "name": "Vietnam International Commercial Joint Stock Bank"
        },
        "ko": {
        "title": "VIB",
        "name": "베트남 국제상업주식은행"
        }
    },
    "VNPTM": {
        "vi": {
        "title": "VNPT Money",
        "name": "Ví điện tử VNPT Money"
        },
        "en": {
        "title": "VNPT Money",
        "name": "VNPT Money E-Wallet"
        },
        "ko": {
        "title": "VNPT Money",
        "name": "VNPT 전자지갑"
        }
    },
    "VIETTELM": {
        "vi": {
        "title": "Viettel Money",
        "name": "Ví điện tử Viettel Money"
        },
        "en": {
        "title": "Viettel Money",
        "name": "Viettel Money E-Wallet"
        },
        "ko": {
        "title": "Viettel Money",
        "name": "Viettel 전자지갑"
        }
    },
    "EIB": {
        "vi": {
        "title": "Eximbank (EIB)",
        "name": "Ngân hàng TMCP Xuất nhập khẩu Việt Nam"
        },
        "en": {
        "title": "Eximbank (EIB)",
        "name": "Vietnam Export Import Bank"
        },
        "ko": {
        "title": "Eximbank (EIB)",
        "name": "베트남 수출입 상업주식은행"
        }
    },
    "SHB": {
        "vi": {
        "title": "SHB",
        "name": "Ngân hàng TMCP Sài Gòn - Hà Nội"
        },
        "en": {
        "title": "SHB",
        "name": "SaiGon - HaNoi Commercial Joint Stock Bank"
        },
        "ko": {
        "title": "SHB",
        "name": "사이공 - 하노이 상업주식은행"
        }
    },
    "TPB": {
        "vi": {
        "title": "TPBank (TPB)",
        "name": "Ngân hàng TMCP Tiên Phong"
        },
        "en": {
        "title": "TPBank (TPB)",
        "name": "Tien Phong Commercial Joint Stock Bank"
        },
        "ko": {
        "title": "TPBank (TPB)",
        "name": "티엔퐁 상업주식은행"
        }
    },
    "TCB": {
        "vi": {
        "title": "Techcombank (TCB)",
        "name": "Ngân hàng TMCP Kỹ Thương Việt Nam"
        },
        "en": {
        "title": "Techcombank (TCB)",
        "name": "Vietnam Technological and Commercial Joint Stock Bank"
        },
        "ko": {
        "title": "Techcombank (TCB)",
        "name": "베트남 기술 및 상업 상업주식은행"
        }
    },
    "MSB": {
        "vi": {
        "title": "Maritime Bank (MSB)",
        "name": "Ngân hàng TMCP Hàng Hải Việt Nam"
        },
        "en": {
        "title": "Maritime Bank (MSB)",
        "name": "Vietnam Maritime Commercial Joint Stock Bank"
        },
        "ko": {
        "title": "Maritime Bank (MSB)",
        "name": "베트남 해상 상업주식은행"
        }
    },
    "LPB": {
        "vi": {
        "title": "LPBank (LPB)",
        "name": "Ngân hàng TMCP Lộc Phát Việt Nam"
        },
        "en": {
        "title": "LPBank (LPB)",
        "name": "Fortune Vietnam Joint Stock Commercial Bank"
        },
        "ko": {
        "title": "LPBank (LPB)",
        "name": "베트남 록팟 상업주식은행"
        }
    },
    "DAB": {
        "vi": {
        "title": "DongA Bank (DAB)",
        "name": "Ngân hàng TMCP Đông Á"
        },
        "en": {
        "title": "DongA Bank (DAB)",
        "name": "East Asia Commercial Joint Stock Bank"
        },
        "ko": {
        "title": "DongA Bank (DAB)",
        "name": "동아시아 상업주식은행"
        }
    },
    "NASB": {
        "vi": {
        "title": "Bac A Bank (NASB)",
        "name": "Ngân hàng TMCP Bắc Á"
        },
        "en": {
        "title": "Bac A Bank (NASB)",
        "name": "North Asia Commercial Joint Stock Bank"
        },
        "ko": {
        "title": "Bac A Bank (NASB)",
        "name": "북아시아 상업주식은행"
        }
    },
    "SGB": {
        "vi": {
        "title": "Sai Gon Bank (SGB)",
        "name": "Ngân hàng TMCP Sài Gòn Công Thương"
        },
        "en": {
        "title": "Sai Gon Bank (SGB)",
        "name": "Saigon Bank for Industry and Trade"
        },
        "ko": {
        "title": "Sai Gon Bank (SGB)",
        "name": "사이공 공상 상업주식은행"
        }
    },
    "VIETBANK": {
        "vi": {
        "title": "Vietbank (VB)",
        "name": "Ngân hàng TMCP Việt Nam Thương tín"
        },
        "en": {
        "title": "Vietbank (VB)",
        "name": "Vietnam Thuong Tin Commercial Joint Stock Bank"
        },
        "ko": {
        "title": "Vietbank (VB)",
        "name": "베트남 트엉띤 상업주식은행"
        }
    },
    "VCCB": {
        "vi": {
        "title": "BVBank - Ngân hàng TMCP Bản Việt",
        "name": "Ngân hàng TMCP Bản Việt"
        },
        "en": {
        "title": "BVBank - Ngân hàng TMCP Bản Việt",
        "name": "Viet Capital Commercial Joint Stock Bank"
        },
        "ko": {
        "title": "BVBank - Ngân hàng TMCP Bản Việt",
        "name": "반비엣 상업주식은행"
        }
    },
    "KLB": {
        "vi": {
        "title": "KienlongBank (KLB)",
        "name": "Ngân hàng TMCP Kiên Long"
        },
        "en": {
        "title": "KienlongBank (KLB)",
        "name": "Kien Long Commercial Joint Stock Bank"
        },
        "ko": {
        "title": "KienlongBank (KLB)",
        "name": "끼엔롱 상업주식은행"
        }
    },
    "UMEE": {
        "vi": {
        "title": "UMEE",
        "name": "Ngân hàng số UMEE by KienlongBank"
        },
        "en": {
        "title": "UMEE",
        "name": "UMEE by KienlongBank"
        },
        "ko": {
        "title": "UMEE",
        "name": "끼엔롱 은행의 UMEE 디지털은행"
        }
    },
    "KEB HANA HN": {
        "vi": {
        "title": "KEB Hana HN (KHHN)",
        "name": "Ngân hàng KEB Hana Bank CN Hà Nội"
        },
        "en": {
        "title": "KEB Hana HN (KHHN)",
        "name": "KEB Hana Bank Hanoi Branch"
        },
        "ko": {
        "title": "KEB Hana HN (KHHN)",
        "name": "KEB하나은행 하노이지점"
        }
    },
    "KEB HANA HCM": {
        "vi": {
        "title": "KEB Hana HCM (KHHCM)",
        "name": "Ngân hàng KEB Hana Bank CN TP HCM"
        },
        "en": {
        "title": "KEB Hana HCM (KHHCM)",
        "name": "KEB Hana Bank HCM Branch"
        },
        "ko": {
        "title": "KEB Hana HCM (KHHCM)",
        "name": "KEB하나은행 호치민지점"
        }
    },
    "AGRICULTURALCHINA": {
        "vi": {
        "title": "Agriculture Bank of China (ABCHN)",
        "name": "Ngân hàng Nông nghiệp Trung Quốc chi nhánh Hà Nội"
        },
        "en": {
        "title": "Agriculture Bank of China (ABCHN)",
        "name": "Agricultural Bank Of China LTD Hanoi"
        },
        "ko": {
        "title": "Agriculture Bank of China (ABCHN)",
        "name": "중국농업은행 하노이지점"
        }
    },
    "KASIKORNBANK CNHCM": {
        "vi": {
        "title": "KasikornBank HCM (KBANK)",
        "name": "Ngân hàng Đại Chúng TNHH Kasikorn bank chi nhánh HCM"
        },
        "en": {
        "title": "KasikornBank HCM (KBANK)",
        "name": "KasikornBank HCM Branch"
        }
    }
  };
  
  export const getBankTitle = (bankCode: string, language: string = 'en'): string | null => {
    try {
        return BANK_DATA[bankCode.toUpperCase()]?.[language]?.title || null;
    } catch (error) {
      console.error(`Error getting bank title for code: ${bankCode}`, error);
      return null;
    }
  };