import { useEffect, useRef, useState } from "react";

interface InfiniteScrollOptions {
  isLoading: boolean;
  onLoadMore: () => void;
  onRefresh?: (() => void) | null;
  offset?: number;
  containerRef?: React.RefObject<HTMLElement>;
}

const useInfiniteScroll = ({
  isLoading,
  onLoadMore,
  onRefresh = null,
  offset = 100,
  containerRef
}: InfiniteScrollOptions) => {
  const [scrollUpCount, setScrollUpCount] = useState(0);
  const isRefreshing = useRef(false);

  useEffect(() => {
    const container = containerRef?.current;

    const handleScroll = () => {
      if (container) {
        // Container-specific scroll logic
        const isScrollTop = container.scrollTop === 0;
        
        if (isScrollTop && !isLoading && !isRefreshing.current) {
          if (scrollUpCount === 1) {
            isRefreshing.current = true;
            onRefresh?.();
            setTimeout(() => {
              isRefreshing.current = false;
              setScrollUpCount(0);
            }, 1000);
          } else {
            setScrollUpCount(prevCount => prevCount + 1);
          }
        }

        const isScrollBottom = 
          container.scrollHeight - container.scrollTop - container.clientHeight <= offset;
        
        if (isScrollBottom && !isLoading) {
          onLoadMore();
        }
      } else {
        // Fallback to window-based scrolling
        const currentScrollY = window.scrollY;

        if (
          window.innerHeight + currentScrollY >= document.documentElement.scrollHeight - offset &&
          !isLoading
        ) {
          onLoadMore();
        }
      }
    };

    // Add event listener based on container or window
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    } else {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [isLoading, onLoadMore, onRefresh, offset, scrollUpCount, containerRef]);
};

export default useInfiniteScroll;
