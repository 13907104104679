import { generateRefNo } from "../appCommon";
import { SearchStatementProp } from "../types/Transaction";
import api from "./AxiosInterceptor";
const config = require("../appConfig");
const patchApi = config.API_ENPOINT;

export const getListTranscation = async (searchObject: SearchStatementProp) => {
  const refNo = generateRefNo();
  const searchObjectRefNo = { ...searchObject, refNo }; 

  const url: string = patchApi + "/statement/v1.0/list-statements";
  const response = await api.get(url, { params: searchObjectRefNo });

  return response.data;
};

export const getListTranscationNewest = async (searchObject: SearchStatementProp) => {
  const refNo = generateRefNo();
  const searchObjectRefNo = { ...searchObject, refNo }; 

  const url: string = patchApi + "/statement/v1.0/merchant/newest-statements";
  const response = await api.get(url, { params: searchObjectRefNo });

  return response.data;
};

export const exportTranscation = (params: any) => {
  const token = JSON.parse(sessionStorage.getItem("accessToken") || "");
  const url = `${patchApi}/excel/v1.0/excel-report-merchant`;
  const refNo = generateRefNo();
  
  const queryParams = { ...params, token, refNo };

  return api({
    url: url,
    method: "GET",
    responseType: "blob",
    params: queryParams, 
  });
};


export const exportCustomerTranscation = (params: any) => {
  const token = JSON.parse(sessionStorage.getItem("accessToken") || "");
  const url = `${patchApi}/excel/v1.0/excel-report-customer`;
  const refNo = generateRefNo();

  const queryParams = { ...params, token, refNo };
  return api({
      url: url,
      method: "GET",
      responseType: "blob",
      params: queryParams,
  });
};
