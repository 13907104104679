import { all } from "redux-saga/effects";
import OnboardSaga from "./OnboardSaga/Onboard";
import StoreSetupSaga from "./StoreSetupSaga/StoreSetup";
import CustomerSaga from "./CustomerSaga/Customer";
import HomePageSaga from "./HomePageSaga/HomePage";
import ListTransactionSaga from "./ListTransactionSaga/ListTransaction";
import ListGroupSaga from "./ListGroupSaga/ListGroup";
import ReportgeSaga from "./ReportSaga/Report";
import CustomerReportSaga from "./CustomerSaga/CustomerReport";
import inforCustomerSaga from "./InforCustomerSaga/InforCustomer";
import IntroInformationSaga from "./IntroInformationSaga/IntroInformation";

export function* rootSaga() {
  yield all([
    OnboardSaga(),
    StoreSetupSaga(),
    CustomerSaga(),
    ListGroupSaga(),
    ListTransactionSaga(),
    HomePageSaga(),
    ReportgeSaga(),
    CustomerReportSaga(),
    inforCustomerSaga(),
    IntroInformationSaga(),
  ]);
}
