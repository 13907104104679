import React, { useState } from 'react'
import Footer from '../../components/Footer/Footer';
import { Tab, Tabs } from "../../components/Tabs/TabReport";
import ReportNoData from './ReportNoData';
import RevenueReport from './RevenueReport';

const Report: React.FC = () => {
    const [valueTab, setValueTab] = useState(0);
    const [respone, setRespone] = useState(true);
    const handleTabValue = (value: number) => {
        setValueTab(value);
    } 
  return (
    <div className=' min-h-screen py-6 not-italic flex flex-col space-y-4'>
      <header className='fixed top-0 py-4 w-full z-40 bg-white text-lg px-6 font-extrabold leading-7'>
        Báo cáo dòng tiền
      </header>
      <main className='flex-grow'>
        <Tabs valueTab={valueTab} onTabClick={handleTabValue}>
          <Tab label="Doanh thu">
            {!respone ?
                <ReportNoData 
                    title="Tổng doanh thu"
                    value={valueTab}
                />
                :
                <RevenueReport
                    title="Tổng doanh thu"
                    value={valueTab}
                />
            }
          </Tab>
          <Tab label="Giao dịch">
            {!respone ?
                <ReportNoData 
                    title="Tổng doanh thu"
                    value={valueTab}
                />
                :
                <RevenueReport
                    title="Tổng doanh thu"
                    value={valueTab}
                />
            }
          </Tab>
        </Tabs>
      </main>
      <footer className='px-6'><Footer/></footer>
    </div>
  )
}

export default Report
