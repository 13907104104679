import React, { useEffect } from "react";
import { Card, MB } from "../../assets/Images/index";
import { CardIcon } from "../../assets/Icons/IconSVG";
interface BankSelectionProps {
  handleChecker: (e: any) => void;
  isCheck: boolean;
  isStoreSetup: boolean;
  merchantAccountNbr: string,
  merchantName: string
}

const BankSelection: React.FC<BankSelectionProps> = (props) => {
  const { handleChecker, isCheck, merchantAccountNbr, merchantName } = props;
  const isInformation = merchantAccountNbr && merchantName;

  useEffect(() => {
    // Nếu cả merchantAccountNbr và merchantName đều tồn tại, cập nhật isCheck bằng true
    if (isInformation && !isCheck) {
      handleChecker({ target: { checked: true, name: "customer" } } as React.ChangeEvent<HTMLInputElement>);
    }
  }, [merchantAccountNbr, merchantName, isCheck, handleChecker]);

  return (
    <div>
      <div className="bg-green200 p-4 rounded-2xl">
        <div className="flex pb-4 items-center border-b border-gray100">
          <CardIcon className={isCheck ? 'fill-green500' : 'fill-gray'}/>
          <span className="text-gray text-base leading-5 not-italic font-normal pl-2">
            Tài khoản ngân hàng
          </span>
        </div>
        {isInformation && (
          <div className="mt-4 flex items-center justify-between mb-5">
            <div className="flex items-center">
              <img className="w-6 h-6 rounded-full" src={MB} alt="" />
              <span className="pl-2 max-w-[200px]">
                <p className="text-sm font-bold leading-5">{merchantAccountNbr}</p>
                <p className="tesx-sm font-normal uppercase text-gray500 break-all">
                  {merchantName}
                </p>
              </span>
            </div>
            <div>
              <input
                type="radio"
                className="custom-radio w-5 h-5 "
                id="customer-checkbox"
                name="customer"
                checked={isCheck}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BankSelection;
