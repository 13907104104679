import React from 'react';
import { convertNumber } from '../../../appCommon';
import { format } from 'date-fns';

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
  isMonth?: boolean
}

// Custom Tooltip Component
const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label, isMonth }) => {
  const dataTooltip = payload?.[0]?.payload || {};
  const dayName = ["CN", "Th.2", "Th.3", "Th.4", "Th.5", "Th.6", "Th.7"][new Date(dataTooltip?.txnDate).getDay()];

  if (active && payload && payload.length) {
    return (
      <div className="flex flex-col space-y-4 bg-white p-3 rounded-xl border border-gray100 shadow-lg not-italic leading-4">
        <p className="font-medium text-xs text-gray500">
          {isMonth 
            ? `T ${format(new Date(dataTooltip?.txnDate), 'MM/yyyy')}`
            : `${dayName} ${format(new Date(dataTooltip?.txnDate), 'dd/MM/yyyy')}`
          }
          </p>
        <p className="intro flex flex-col">
          <span className='text-xs text-gray500 font-medium'>Tổng doanh thu</span>
          <span className='text-black font-bold text-base flex'>
            <span className='text-xs font-medium pr-0.5'>đ</span>
            {convertNumber(payload[0]?.value)}
          </span>
        </p>
        <p className="intro flex flex-col">
          <span className='text-xs text-gray500 font-medium'>Tổng chi phí</span>
          <span className='text-black font-bold text-base flex'>
            <span className='text-xs font-medium pr-0.5'>đ</span>
            {convertNumber(payload[1]?.value)}
          </span>
        </p>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
