import React from "react";
import {
  Chart,
  ReceiptGray,
  PersonGreen,
  RightIcon,
} from "../../assets/Icons/index";
import { useNavigate } from "react-router-dom";

const Features: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div>
      {/* <h4 className="text-lg text-[#181C26] font-extrabold leading-7">
        Tất cả tính năng
      </h4> */}
      <div className="flex flex-col mt-4">
        <button
          className="flex items-center justify-between rounded-2xl border-[1px] border-gray100 bg-white shadow-customBox
            px-4 py-3 mb-3"
          onClick={() => navigate("/transaction")}
        >
          <div className="flex">
            <ReceiptGray className="w-5 h-5 mr-2" />
            <span>Lịch sử giao dịch</span>
          </div>
          <RightIcon />
        </button>
        <button
          className="flex items-center justify-between rounded-2xl border-[1px] border-gray100 bg-white shadow-customBox
            px-4 py-3 mb-3"
          onClick={() => navigate("/report")}
        >
          <div className="flex">
            <Chart className="w-5 h-5 mr-2" />
            <span>Xem báo cáo</span>
          </div>
          <RightIcon />
        </button>
        <button
          className="flex items-center justify-between rounded-2xl border-[1px] border-gray100 bg-white shadow-customBox
            px-4 py-3"
          onClick={() => navigate("/customer")}
        >
          <div className="flex">
            <PersonGreen className="w-5 h-5 mr-2" />
            <span>Khách hàng</span>
          </div>
          <RightIcon />
        </button>
      </div>
    </div>
  );
};

export default Features;
