import React, { useEffect } from "react";
import CustomDropdown from "../FilterSelect/FilterSelect";
import { DATE_SELECTOR_TRANSACTION } from "../../appConstants";
import { DataListTransaction, TransactionProps } from "../../types/Transaction";
import InfiniteScroll from "../InfiniteScroll/InfiniteScroll";
interface TransactionProp {
  onSelectValue: (value: string) => void;
  transactions: TransactionProps[];
  handleNavigate: (item: DataListTransaction) => void;
  defaultDate?: string;
}

const ListTransaction: React.FC<TransactionProp> = ({
  onSelectValue,
  transactions,
  handleNavigate,
  defaultDate
}) => {
  const isTransactions = Array.isArray(transactions) && transactions.length > 0;
  
  return (
    <div className="mx-4 px-4 shadow-custom bg-white rounded-2xl mb-20">
      <div className="pt-4 flex justify-between items-center mb-6">
        <p className="text-lg font-extrabold">Tất cả giao dịch</p>
        <CustomDropdown
          valueSelect={(value: string) => onSelectValue(value)}
          minDay={true}
          isSelectTime={true}
          filterOption={DATE_SELECTOR_TRANSACTION}
          defaultDate={defaultDate}
        />
      </div>
      {isTransactions ? (
        <InfiniteScroll
          listData={transactions} 
          isNickName={true}
          isIcons={true}
          handleNavigate={handleNavigate}
        /> 
      ) :  (
        <div className="flex pb-4 mt- justify-center text-gray500 font-medium leading-5">
          Chưa có giao dịch nào
        </div>
      )}
    </div>
  );
};

export default ListTransaction;
