import { takeEvery, call, put } from "redux-saga/effects";
import { RESPONSE_STAUS_CODE } from "../../../appConstants";
import { UPDATE_INFORCUSTOMER, updateInforCustomerFail, updateInforCustomerSuccess } from "../../actions/InforCustomerActions/InforCustomer";
import { updateInforCustomerApi } from "../../../api/InforCustomer";
import { ResponseUpdateInforCustomer, UpdateInforCustomerActionFail } from "../../../types/InforCustomer";

function* updateInforCustomer(action: any) {
  try {
    const res: ResponseUpdateInforCustomer = yield call(updateInforCustomerApi, action.payload);
    
    if (res?.status === RESPONSE_STAUS_CODE.SUCCESS.name && res?.data) {
        yield put(updateInforCustomerSuccess(res?.data));
    } else {
        const errorData = res?.error as UpdateInforCustomerActionFail;
        yield put(updateInforCustomerFail(errorData));
    }
  } catch (error) {
    throw error;
  }
}

export default function* inforCustomerSaga() {
  yield takeEvery(UPDATE_INFORCUSTOMER, updateInforCustomer);
}
