import React, { useEffect, useRef, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { convertNumber } from "../../../appCommon";
import { TIME_SELECTOR } from "../../../appConstants";
import { format } from "date-fns";
import CustomXAxisTick from "./CustomXAxisTick";

// Custom Tooltip Props Interface
interface CustomTooltipProps {
  active?: boolean;
  payload?: any;
  label?: string;
  isMonth?: boolean;
}
interface CustomerReportProps {
  time: string;
  amount: number;
}
interface ArrayCustomerReport {
  customerReport: CustomerReportProps[];
  type: string;
}
// Custom Tooltip Component
const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  isMonth,
}) => {
  const dataTooltip = payload?.[0]?.payload || {};
  const dayName = ["CN", "Th.2", "Th.3", "Th.4", "Th.5", "Th.6", "Th.7"][
    new Date(dataTooltip?.time).getDay()
  ];
  
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip bg-black rounded-xl p-3">
        <p className="text-xs text-gray100 font-medium leading-4.5 text-center">
          {isMonth
            ? `T ${dataTooltip?.time}`
            : `${dayName} ${format(new Date(dataTooltip?.time), "dd/MM/yyyy")}`}
        </p>
        <p className="text-base text-white font-bold leading-6">
          {convertNumber(dataTooltip?.amount) || 0}đ
        </p>
      </div>
    );
  }
  return null;
};

const CustomChart: React.FC<ArrayCustomerReport> = ({
  customerReport,
  type,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [highlightedDate, setHighlightedDate] = useState<string | null>(null);
  const chartRef = useRef<HTMLDivElement>(null);
  const [chartKey, setChartKey] = useState<number>(0);
  const isMonth = type === TIME_SELECTOR.MONTHS.type;

  useEffect(() => {
    setSelectedIndex(null);
    setHighlightedDate(null);

    if (chartRef.current) {
      chartRef.current.scrollTo({
        left: 0,
        behavior: 'smooth'
      });
    }
  }, [customerReport]);

  const handleScroll = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!chartRef.current) return;

    const chartWidth = chartRef.current.clientWidth;
    const scrollWidth = chartRef.current.scrollWidth;
    const scrollTriggerOffset = 150;

    const clickPosition = event.clientX;

    if (
      clickPosition < scrollTriggerOffset ||
      clickPosition > chartWidth - scrollTriggerOffset
    ) {
      let newScroll =
        clickPosition + chartRef.current.scrollLeft - chartWidth / 2;

      if (newScroll < 0) newScroll = 0;
      if (newScroll > scrollWidth - chartWidth)
        newScroll = scrollWidth - chartWidth;

      chartRef.current.scrollTo({ left: newScroll, behavior: "smooth" });
    }
  };

  const handleChartClick = (state: any) => {
    setSelectedIndex(state?.activeTooltipIndex);

    if (state.activePayload && state.activePayload.length > 0) {
      const { payload } = state.activePayload[0];
      const selectedDate = payload.time; 
      setHighlightedDate(selectedDate); 
    }
  };

  const handleMouseLeave = () => {
    setHighlightedDate(null);
  };

  const resetChartState = () => {
    if (highlightedDate || selectedIndex) {
      setChartKey((prevKey) => prevKey + 1);
      setHighlightedDate(null);
      setSelectedIndex(null);
    }
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (chartRef.current && !chartRef.current.contains(event.target as Node)) {
      resetChartState();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [highlightedDate, selectedIndex]);

  
  const chartWidth = Math.max(customerReport?.length * 55, 320);
  return (
    <div
      className="relative overflow-x-auto scrollbar scrollbar-thumb-white"
      ref={chartRef}
      onClick={handleScroll}
      onMouseLeave={handleMouseLeave}
    >
      <ResponsiveContainer height={300} width={chartWidth} key={chartKey}>
        <BarChart data={customerReport} barGap={20} onClick={handleChartClick}>
          <XAxis
            dataKey="time"
            interval={0}
            axisLine={false}
            tickSize={0}
            tickMargin={16}
            tick={(props) => {
              const { x, y, payload } = props;
          
              const isHighlighted = payload.value === highlightedDate;
              const [month] = payload?.value.split("/") || [];
              const formattedMonth = month ? parseInt(month, 10).toString() : "";
              const displayValue = isMonth
                ? `T ${formattedMonth}`
                : format(payload?.value, "dd/MM");
          
              return (
                <text
                  x={x}
                  y={y} 
                  textAnchor="middle"
                  fill={isHighlighted ? "#06BD7D" : "#8B929C"}
                >
                  {displayValue}
                </text>
              );
            }}
          />
          <Tooltip
            content={<CustomTooltip isMonth={isMonth} />}
            cursor={false}
            trigger="click"
          />
          <Bar
            dataKey="amount"
            radius={[8, 8, 0, 0]}
            barSize={35}
            fill={selectedIndex !== null ? "#EFFBF7" : "#EFFBF7"}
          >
            {customerReport?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={selectedIndex === index ? "#06BD7D" : "#EFFBF7"}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomChart;
