import {
  FailRespone,
  ListCustomerRes,
  SearchParams,
} from "../../../types/Customer";

export const GET_LIST_CUSTOMER = "GET_LIST_CUSTOMER";
export const GET_LIST_CUSTOMER_SUCCESS = "GET_LIST_CUSTOMER_SUCCESS";
export const GET_LIST_CUSTOMER_FAIL = "GET_LIST_CUSTOMER_FAIL";

export const GET_LIST_CUSTOMER_DETAIL = "GET_LIST_CUSTOMER_DETAIL";
export const GET_LIST_CUSTOMER_SUCCESS_DETAIL = "GET_LIST_CUSTOMER_SUCCESS_DETAIL";
export const GET_LIST_CUSTOMER_FAIL_DETAIL = "GET_LIST_CUSTOMER_FAIL_DETAIL";
export const GET_LIST_CUSTOMER_RESET = "GET_LIST_CUSTOMER_RESET";

export const SAVE_CUSTOMER_DETAIL = "SAVE_CUSTOMER_DETAIL";
export const SAVE_CUSTOMER_DETAIL_SUCCESS = "SAVE_CUSTOMER_DETAIL_SUCCESS";
export const SAVE_CUSTOMER_DETAIL_FAIL = "SAVE_CUSTOMER_DETAIL_FAIL";

export const UPDATE_LIST_CUSTOMER = "UPDATE_LIST_CUSTOMER";

export const GET_LIST_STATEMENT_CUSTOMER = "GET_LIST_STATEMENT_CUSTOMER";
export const GET_LIST_STATEMENT_CUSTOMER_SUCCESS = "GET_LIST_STATEMENT_CUSTOMER_SUCCESS";
export const GET_LIST_STATEMENT_CUSTOMER_FAIL = "GET_LIST_STATEMENT_CUSTOMER_FAIL";

export const GET_LIST_STATEMENT_CUSTOMER_NEWEST = "GET_LIST_STATEMENT_CUSTOMER_NEWEST";
export const GET_LIST_STATEMENT_CUSTOMER_NEWEST_SUCCESS = "GET_LIST_STATEMENT_CUSTOMER_NEWEST_SUCCESS";

export const getListCustomer = (payload: SearchParams, isNewPage: boolean = false) => {
  return {
    type: GET_LIST_CUSTOMER,
    payload,
    isNewPage
  };
};
export const getListCustomerSuccess = (payload: ListCustomerRes, isNewPage: boolean = false) => {
  return {
    type: GET_LIST_CUSTOMER_SUCCESS,
    payload,
    isNewPage
  };
};
export const getListCustomerFail = (payload: FailRespone) => {
  return {
    type: GET_LIST_CUSTOMER_FAIL,
    payload,
  };
};

export const getListCustomerDetail = (payload: SearchParams, isNewPage: boolean = false) => {
  return {
    type: GET_LIST_CUSTOMER_DETAIL,
    payload,
    isNewPage
  };
};
export const getListCustomerSuccessDetail = (payload: ListCustomerRes, isNewPage: boolean = false) => {
  return {
    type: GET_LIST_CUSTOMER_SUCCESS_DETAIL,
    payload,
    isNewPage
  };
};
export const getListCustomerFailDetail = (payload: FailRespone) => {
  return {
    type: GET_LIST_CUSTOMER_FAIL_DETAIL,
    payload,
  };
};

export const getListCustomerReset = () => {
  return {
    type: GET_LIST_CUSTOMER_RESET,
  };
};

export const updateListCustomer = (payload: any) => {
  return {
    type: UPDATE_LIST_CUSTOMER,
    payload
  }
}

export const getListStatementCustomer = (payload: any, selectDate?: string) => {
  return {
    type: GET_LIST_STATEMENT_CUSTOMER,
    payload,
    selectDate
  };
};
export const getListStatementCustomerSuccess = (payload: any, selectDate?: string) => {
  return {
    type: GET_LIST_STATEMENT_CUSTOMER_SUCCESS,
    payload,
    selectDate
  };
};
export const getListStatementCustomerFail = (payload: FailRespone) => {
  return {
    type: GET_LIST_STATEMENT_CUSTOMER_FAIL,
    payload,
  };
};

export const getListStatementNewest = (payload: any, selectDate?: string) => {
  return {
    type: GET_LIST_STATEMENT_CUSTOMER_NEWEST,
    payload,
    selectDate
  };
};
export const getListStatementNewestSuccess = (payload: any, selectDate?: string) => {
  return {
      type: GET_LIST_STATEMENT_CUSTOMER_NEWEST_SUCCESS,
      payload,
      selectDate
  };
};