import React from "react";
import InfiniteScrollComponent from "react-infinite-scroll-component";
import MappingBankLogo from "../Mapping/MappingBankLogo/MappingBankLogo";
import { CustomerNavigate } from "../../types/Customer";

interface Props  {
  listData: CustomerNavigate[];
  isNickName?: boolean;
  handleNavigate?: (item?: CustomerNavigate) => void;
};

const InfiniteScrollCustomer: React.FC<Props> = ({ listData, isNickName, handleNavigate }) => { 

return (
    <InfiniteScrollComponent
        dataLength={listData?.length}
        next={() => {}} 
        hasMore={false} 
        loader={""}
    >
        {listData?.map((item: CustomerNavigate) => (
            <div 
                className="mb-4 flex gap-2 items-start" 
                onClick={() =>  handleNavigate && handleNavigate(item)}
                key={item?.customerId}
            >
                <div className="flex justify-center items-start gap-2 self-stretch pt-1">
                <MappingBankLogo bankCode={item?.customerBank ?? "ic_bank_default"}/>
                </div>
                <div className="flex justify-between item-start pb-4 w-full border-b border-gray100">
                    <div>
                        <p className="font-bold">{item?.customerName}</p>
                            {isNickName ? <p>{item?.customerNickname ? `(${item?.customerNickname})` : null} </p> : null}
                        <p className="text-gray flex justify-start items-center gap-1">
                                STK:{item?.customerAccountNbr}
                        </p>
                    </div>
                    <div>
                        <p className="bg-green200 text-green500 rounded flex justify-center items-center p-1 h-fit">
                            {item?.customerGroup}
                        </p>
                    </div>
                </div>
            </div>
        ))}
    </InfiniteScrollComponent>
);
};

export default InfiniteScrollCustomer;
