import { HomePageFailRespone, HomePageSuccessRespone, QuickReportSearchObject } from "../../../types/HomeTypes";

export const GET_HOME_PAGE = "GET_HOME_PAGE";
export const GET_HOME_PAGE_SUCCESS = "GET_HOME_PAGE_SUCCESS";
export const GET_HOME_PAGE_FAIL = "GET_HOME_PAGE_FAIL";

export const getHomePage = (payload: QuickReportSearchObject) => {
    return {
        type: GET_HOME_PAGE,
        payload
    }
}
export const getHomePageSuccess = (payload: HomePageSuccessRespone) => {
    return {
        type: GET_HOME_PAGE_SUCCESS,
        payload
    }
}
export const getHomePageFail = (payload: HomePageFailRespone) => {
    return {
        type: GET_HOME_PAGE_FAIL,
        payload
    }
}

