// redux/loadingReducer.ts

import { LoadingActionTypes, SET_LOADING } from "../actions/LoadingAction";

interface LoadingState {
  isLoading: boolean;
}

const initialState: LoadingState = {
  isLoading: false,
};

const loadingReducer = (
  state = initialState,
  action: LoadingActionTypes
): LoadingState => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

export default loadingReducer;
