import React, { useEffect, useRef, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { LeftBlack } from "../../assets/Icons";

import { UserIcon } from "../../assets/Icons/IconFooterSVG";
import { useDispatch, useSelector } from "react-redux";
import { listCustomerDetailSelector, listCustomerLoading } from "../../redux/selector/CustomerSelector/Customer";
import { getListCustomerDetail } from "../../redux/actions/CustomerAction/CustomerAction";

import { SearchParams } from "../../types/Customer";
import LoadingPage from "../../components/Loading/Loading";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import InfiniteScrollCustomer from "../../components/InfiniteScroll/InfiniteScrollCustomer";

const DetailGroupCustomer: React.FC = () => {
  const [page, setPage] = useState(1);
  const location = useLocation();
  const { name } = location.state || "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listCustomerDetail = useSelector(listCustomerDetailSelector) || [];
  const isLoading = useSelector(listCustomerLoading);
  const isFirstLoad = useRef(true);
  
  const fetchListCustomer = (isNewPage: boolean = false) => {
    const searchObject: SearchParams = {
      skip: page,
      limit: 10,
      searchInput: name,
    }; 
    dispatch(getListCustomerDetail(searchObject, isNewPage));
  };
  
  useInfiniteScroll({
    isLoading,
    onLoadMore: () => {
      if (listCustomerDetail?.result?.length === 10){
        setPage((prevPage) => prevPage + 1);
      }
    },
    offset: 100, 
  });
  
  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
      fetchListCustomer(false);
    } else {
      setPage(1);
      fetchListCustomer(true);
    }
  }, []);

  useEffect(() => {
    if (!isFirstLoad.current && page > 1) {
      fetchListCustomer(true);
    }
  }, [page]);

  return (
    <>
    <LoadingPage isLoading={isLoading} />
      <header className="flex justify-start items-center mx-6 h-[60px] ">
        <div
          className="px-2 flex justify-center items-center "
          onClick={() => {
            navigate("/customer");
          }}
        >
          <LeftBlack />
        </div>
        <div className="text-xl font-extrabold flex justify-center content-center text-center w-full mr-9">
          Chi tiết nhóm khách
        </div>
      </header>
      <main className="bg-white">
        <div className="rounded-2xl p-4 bg-green200 m-[12px] mb-4">
          <p className="flex justify-start items-center gap-3  text-base font-semibold">
            <UserIcon isActive={true} />
            {name}
          </p>
        </div>
        <section className="shadow-custom  mx-3">
          <div className="p-4 rounded-2xl">
            <div className="mb-6">
              <p className="text-lg text-base font-bold">Tất cả khách hàng</p>
              <span className="text-gray">{listCustomerDetail?.result?.length} khách hàng</span>
            </div>
            <div>
              <InfiniteScrollCustomer 
                listData={listCustomerDetail?.result || []} 
                isNickName={true}
              />
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default DetailGroupCustomer;
