import React from "react";
import { Banner } from "../../assets/Images/index";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const PromotionProgram: React.FC = () => {
  return (
    <div className="ml-6">
      <h4 className='text-lg text-[#181C26] font-extrabold leading-7'>Chương Trình Ưu Đãi</h4>
      <div className="mt-4">
        <Swiper
          spaceBetween={10}
          slidesPerView={1.5}
          loop={true}
        >
          <SwiperSlide>
            <img className="w-[248px] h-[92px] object-contain" src={Banner} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="w-[248px] h-[92px] object-contain" src={Banner} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="w-[248px] h-[92px] object-contain" src={Banner} alt="" />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  )
}

export default PromotionProgram
