import React, {useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Search } from "../../assets/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listCustomerLoading, listCustomerSelector, updateListCustomerSelector } from "../../redux/selector/CustomerSelector/Customer";
import { getListCustomer, getListCustomerReset } from "../../redux/actions/CustomerAction/CustomerAction";
import LoadingPage from "../Loading/Loading";
import { SearchParams } from "../../types/Customer";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import { DEFAULT_LIMIT } from "../../appConstants";
import InfiniteScrollCustomer from "../InfiniteScroll/InfiniteScrollCustomer";
import ScrollToTop from "../ScrollPage/ScrollTop";

const Customer: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listCustomer = useSelector(listCustomerSelector) || {};
  const isLoading = useSelector(listCustomerLoading);
  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const searchInputRef = useRef(searchInput);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const listCustomerUpdate = useSelector(updateListCustomerSelector);
  const location = useLocation();

  useEffect(() => {
    searchInputRef.current = searchInput;
  }, [searchInput]);

  const displayCustomers = useMemo(() => 
    location?.state?.isBackCustomer ? listCustomerUpdate : listCustomer?.result || []
  , [listCustomerUpdate, listCustomer?.result]);
  
  const fetchListCustomer = (isNewPage: boolean = false) => {    
    const searchObject: SearchParams = {
      skip: page,
      limit: DEFAULT_LIMIT,
      searchInput: searchInputRef.current,
    };
      dispatch(getListCustomer(searchObject, isNewPage));
  }; 

  useInfiniteScroll({
    isLoading,
    onLoadMore: () => {
      if (!isLoading) {  
        setPage(prev => prev + DEFAULT_LIMIT);
      }
    },
    offset: 100,
  });

  useEffect(() => {
    if (location?.state) {
      // Chặn componet mount lần đầu , xóa state cho lần chạy thứ 2
      navigate(location.pathname, { replace: true });
    } else {
      if (isFirstRender) {
        dispatch(getListCustomerReset());
        fetchListCustomer(false);
        setIsFirstRender(false);
      } else {
        fetchListCustomer(true);
      }
    }
  }, [page]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchInput(value);
    setPage(1);
  
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
  
    const delay = value === "" ? 300 : 800;
    debounceTimeoutRef.current = setTimeout(() => {
      fetchListCustomer();
    }, delay);
  };

  const handleNavigate = useCallback((customer: any) => {
    localStorage.setItem("customer", JSON.stringify(customer));
    localStorage.setItem("listCustomer", JSON.stringify(listCustomer));
    navigate("/detail-customer");
  }, [navigate, listCustomer]);

  return (
    <>
      <div className="relative mb-4 px-3">
      <ScrollToTop/>
        <input
          type="text"
          placeholder="Tìm kiếm khách hàng"
          value={searchInput}
          onChange={handleSearchChange}
          className="px-4 py-3 bg-green200 w-full rounded-2xl pl-12 focus:outline-none"
        />
        <Search className="absolute top-3 left-6" />
      </div>
      <div className="p-4 shadow-custom bg-white">
        <div className="flex flex-col mb-6">
          <p className="text-lg text-base font-bold">Tất cả khách hàng</p>
          <span className="text-gray">{listCustomer?.result?.length} khách hàng</span>
        </div>
        <div className="h-[calc(100vh-58px - 61px -55px - 64px] overflow-y-scroll">
          <InfiniteScrollCustomer 
            listData={displayCustomers} 
            handleNavigate={handleNavigate}
            isNickName={true}
          />
        </div>
      </div>
      <LoadingPage isLoading={isLoading} />
    </>
  );
};

export default Customer;