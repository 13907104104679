import React from "react";

interface HomeIconProps {
    isActive: boolean;
}

export const HomeIcon: React.FC<HomeIconProps> = ({ isActive }) => { 
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="25" 
            height="24" 
            viewBox="0 0 25 24" 
            fill="none"
        >
            <path 
            d="M21.7343 8.36985L14.8043 2.82985C13.7343 1.96985 12.0043 1.96985 10.9443 2.81985L4.01429 
            8.36985C3.23429 8.98985 2.73429 10.2998 2.90429 11.2798L4.23429 19.2398C4.47429 20.6598 5.83429 
            21.8098 7.27429 21.8098H18.4743C19.9043 21.8098 21.2743 20.6498 21.5143 19.2398L22.8443 
            11.2798C23.0043 10.2998 22.5043 8.98985 21.7343 8.36985ZM12.8743 15.4998C11.4943 15.4998 10.3743 
            14.3798 10.3743 12.9998C10.3743 11.6198 11.4943 10.4998 12.8743 10.4998C14.2543 10.4998 15.3743 
            11.6198 15.3743 12.9998C15.3743 14.3798 14.2543 15.4998 12.8743 15.4998Z"
            fill={isActive ? '#06BD7D' : '#C2C8D1'}
            />
        </svg>
    )   
}

export const ChartIcon: React.FC<HomeIconProps> = ({ isActive }) => { 
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="25" 
            height="24" 
            viewBox="0 0 25 24" 
            fill="none"
        >
            <path d="M22.625 22H2.625C2.215 22 1.875 21.66 1.875 21.25C1.875 20.84 2.215 20.5 2.625 20.5H22.625C23.035 20.5 23.375 20.84 23.375 21.25C23.375 21.66 23.035 22 22.625 22Z" fill={isActive ? '#06BD7D' : '#C2C8D1'}/>
            <path d="M10.375 4V22H14.875V4C14.875 2.9 14.425 2 13.075 2H12.175C10.825 2 10.375 2.9 10.375 4Z" fill={isActive ? '#06BD7D' : '#C2C8D1'}/>
            <path d="M3.625 10V22H7.625V10C7.625 8.9 7.225 8 6.025 8H5.225C4.025 8 3.625 8.9 3.625 10Z" fill={isActive ? '#06BD7D' : '#C2C8D1'}/>
            <path d="M17.625 15V22H21.625V15C21.625 13.9 21.225 13 20.025 13H19.225C18.025 13 17.625 13.9 17.625 15Z" fill={isActive ? '#06BD7D' : '#C2C8D1'}/>
        </svg>
    )   
}

export const ReceiptIcon: React.FC<HomeIconProps> = ({ isActive }) => { 
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="25" 
            height="24" 
            viewBox="0 0 25 24" 
            fill="none"
        >
            <path 
                d="M3.375 5.25C3.375 4.00736 4.38236 3 5.625 3H15.125C16.3676 3 17.375 4.00736 17.375 5.25V14H21.375V17.75C21.375 
                19.5449 19.9199 21 18.125 21H6.625C4.83007 21 3.375 19.5449 3.375 17.75V5.25ZM17.375 19.5H18.125C19.0915 19.5 
                19.875 18.7165 19.875 17.75V15.5H17.375V19.5ZM6.875 7.75C6.875 8.16421 7.21079 8.5 7.625 8.5H13.125C13.5392 8.5 
                13.875 8.16421 13.875 7.75C13.875 7.33579 13.5392 7 13.125 7H7.625C7.21079 7 6.875 7.33579 6.875 7.75ZM7.625 
                11C7.21079 11 6.875 11.3358 6.875 11.75C6.875 12.1642 7.21079 12.5 7.625 12.5H13.125C13.5392 12.5 13.875 12.1642 
                13.875 11.75C13.875 11.3358 13.5392 11 13.125 11H7.625ZM6.875 15.75C6.875 16.1642 7.21079 16.5 7.625 16.5H10.625C11.0392 
                16.5 11.375 16.1642 11.375 15.75C11.375 15.3358 11.0392 15 10.625 15H7.625C7.21079 15 6.875 15.3358 6.875 15.75Z"
                fill={isActive ? '#06BD7D' : '#C2C8D1'}
            />
        </svg>
    )   
}

export const UserIcon: React.FC<HomeIconProps> = ({ isActive }) => { 
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="25" 
            height="24" 
            viewBox="0 0 25 24" 
            fill="none"
        >
            <path d="M12.125 12C14.8864 12 17.125 9.76142 17.125 7C17.125 4.23858 14.8864 2 12.125 2C9.36358 2 7.125 4.23858 7.125 7C7.125 9.76142 9.36358 12 12.125 12Z" fill={isActive ? '#06BD7D' : '#C2C8D1'}/>
            <path d="M12.1252 14.5C7.11516 14.5 3.03516 17.86 3.03516 22C3.03516 22.28 3.25516 22.5 3.53516 22.5H20.7152C20.9952 22.5 21.2152 22.28 21.2152 22C21.2152 17.86 17.1352 14.5 12.1252 14.5Z" fill={isActive ? '#06BD7D' : '#C2C8D1'}/>
        </svg>
    )   
}