import React, { createContext, useContext, useState, ReactNode } from "react";

// Định nghĩa kiểu dữ liệu cho GlobalState
interface GlobalState {
  [key: string]: any; // Có thể lưu trữ các trạng thái với key là string và value có thể là bất kỳ kiểu nào
}

// Định nghĩa kiểu dữ liệu cho GlobalStateContext
interface GlobalStateContextType {
  globalState: GlobalState;
  setState: (key: string, value: any) => void;
  getState: (key: string) => any;
}

// Tạo context chung với giá trị mặc định
const GlobalStateContext = createContext<GlobalStateContextType | undefined>(undefined);

// Tạo provider để quản lý và cung cấp state
interface GlobalStateProviderProps {
  children: ReactNode; // Các component con được bọc bởi GlobalStateProvider
}

export const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({ children }) => {
  // Sử dụng state chung để lưu trữ nhiều trạng thái
  const [globalState, setGlobalState] = useState<GlobalState>({});

  // Cập nhật trạng thái toàn cục bằng key và value
  const setState = (key: string, value: any) => {
    setGlobalState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // Lấy trạng thái toàn cục theo key
  const getState = (key: string) => {
    return globalState[key];
  };

  return (
    <GlobalStateContext.Provider value={{ globalState, setState, getState }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

// Custom hook để dễ dàng sử dụng context
export const useGlobalState = (): GlobalStateContextType => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider");
  }
  return context;
};
