import React, { useEffect, useState } from "react";
import InfiniteScrollComponent from "react-infinite-scroll-component";
import MappingBankLogo from "../Mapping/MappingBankLogo/MappingBankLogo";
import { ArrowGreen, ArrowRed } from "../../assets/Icons";
import { convertNumber, formatTime } from "../../appCommon";
import { DataListTransaction } from "../../types/Transaction";
import { VALUE_UNKNOWN } from "../../appConstants";

type Props = {
  listData: DataListTransaction[];
  isNickName: boolean;
  isIcons: boolean;
  handleNavigate?: (item: DataListTransaction) => void;
  isDetailCustomer?: boolean
};

const InfiniteScroll: React.FC<Props> = ({ 
  listData = [], 
  isNickName, 
  isIcons, 
  handleNavigate,
  isDetailCustomer 
}) => { 
  const [dataCustomer, setDataCustomer] = useState<DataListTransaction[]>([]);
  const [key, setKey] = useState(0);
  const admit = (moveAway: string | undefined) => {
    return moveAway === "D" ? <ArrowRed /> : <ArrowGreen />;
  };
  const admitPlus = (moveAway: string | undefined) => {
    return moveAway === "D" ? "-" : "+";
  };

  useEffect(() => {
    setDataCustomer(listData);
    setKey(prev => prev + 1);
  }, [listData]);
  
  return (
    <InfiniteScrollComponent
      dataLength={dataCustomer?.length}
      next={() => {}} 
      hasMore={false} 
      key={isDetailCustomer ? key : null} // Lấy 1 lần bản ghi mới nhất từ detailCustomer
      loader={""}
    >
        {dataCustomer?.map((item: DataListTransaction) => (
          <div 
            className="mb-4 flex gap-2 items-start justify-center"
            key={item.statementId}
            onClick={() => handleNavigate && handleNavigate(item)}
          >
            <div className="flex justify-center items-start gap-2 self-stretch pt-1">
              <MappingBankLogo bankCode={item?.contractBank ?? "ic_bank_default"}/>
            </div>
            <div className="flex justify-between items-start pb-4 w-full border-b border-gray100">
              {/* Cột bên trái */}
              <div className="flex flex-col justify-start">
                <p className="font-bold">
                  {item?.contractCustomerName ? item?.contractCustomerName : VALUE_UNKNOWN}
                </p>
                {isNickName ? (
                  <p>{item?.nickname ? `(${item?.nickname})` : null}</p>
                ) : null}
                <p className="text-gray flex items-center gap-1">
                  {isIcons ? admit(item?.sign) : null}
                  STK: {item?.contraAccountNbr ? item?.contraAccountNbr : VALUE_UNKNOWN}
                </p>
              </div>

              {/* Cột bên phải */}
              <div className="flex flex-col justify-start text-end">
                <p className="font-bold mb-2">
                  {admitPlus(item?.sign)} {convertNumber(item?.amount)}đ
                </p>
                <p className="text-xs text-[#A2A0A8]">{formatTime(item?.txnTime)}</p>
              </div>
            </div>
          </div>
        ))}
    </InfiniteScrollComponent>
  );
};

export default InfiniteScroll;
