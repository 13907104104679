
export const GET_INTRO_INFORMATION = "GET_INTRO_INFORMATION";
export const GET_INTRO_INFORMATION_SUCCESS = "GET_INTRO_INFORMATION_SUCCESS";
export const GET_INTRO_INFORMATION_FAIL = "GET_INTRO_INFORMATION_FAIL";

export const getIntroInformation = () => {
  return {
    type: GET_INTRO_INFORMATION,
  };
};
export const getIntroInformationSuccess = (payload: any) => {
  return {
    type: GET_INTRO_INFORMATION_SUCCESS,
    payload,
  };
};
export const getIntroInformationFail = (payload: any) => {
  return {
    type: GET_INTRO_INFORMATION_FAIL,
    payload,
  };
};

