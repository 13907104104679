import React, { useEffect, useRef, useState } from "react";
import { Chevron } from "../../assets/Icons";
import { Calendar } from "../../assets/Icons/IconSVG";
import DateTimePicker from "../DatePicker/DateTimePicker";

interface DropdownProp {
  isSelectTime: boolean;
  filterOption: { code: number; name: string }[];
  minDay: boolean;
  valueSelect: (value: string) => void;
  defaultDate?: string
}

const CustomDropdown: React.FC<DropdownProp> = ({
  isSelectTime,
  filterOption,
  minDay,
  valueSelect,
  defaultDate
}) => {
  const [selectedValue, setSelectedValue] = useState<string>(defaultDate ? defaultDate : filterOption[0].name);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenCalendar, setIsOpenCalendar] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    setSelectedValue(defaultDate ? defaultDate : filterOption[0].name);
  }, [defaultDate, filterOption]);

  useEffect(() => {
    valueSelect(selectedValue);
  }, [selectedValue]);

  const handleSelect = (value: string) => {
    setSelectedValue(value);
    setIsOpen(false);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false); // Close dropdown if clicked outside
    }
  };

   // Vô hiệu hóa cuộn trang khi modal mở
   useEffect(() => {
    if (isOpenCalendar) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpenCalendar]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  return (
    <div ref={dropdownRef} className="relative inline-block text-left">
      <div
        onClick={toggleDropdown}
        className="text-green500 font-semibold rounded-lg cursor-pointer flex justify-end items-center "
      >
        <p className="ml-2 max-w-[120px] whitespace-nowrap overflow-hidden text-ellipsis">
          {selectedValue}
        </p>
        <Chevron />
      </div>
      {isOpen && (
        <ul className="absolute right-1 z-10 bg-white border border-gray100 rounded-xl px-4 pt-2 pb-4">
          {filterOption?.map((option) => (
            <li
              key={option.code}
              onClick={() => handleSelect(option.name)}
              className={`cursor-pointer hover:bg-gray-100 py-2 border-b border-gray100 text-nowrap ${
                selectedValue === option.name ? "bg-gray-100 font-bold" : ""
              }`}
            >
              {option.name}
            </li>
          ))}
          {isSelectTime ? (
            <li
              onClick={() => {
                setIsOpen(false);
                setIsOpenCalendar(true);
              }}
              className="flex gap-2 items-center pt-2 cursor-pointer hover:bg-gray-100 text-nowrap"
            >
              <Calendar isActive={false} /> Chọn thời gian
            </li>
          ) : null}
        </ul>
      )}
      <DateTimePicker
        isOpen={isOpenCalendar}
        onClose={() => setIsOpenCalendar(false)}
        onTimeValue={(value: string) => setSelectedValue(value)}
        minPickDay={minDay}
      />
    </div>
  );
};

export default CustomDropdown;
