import React, { useEffect, useRef, useState } from "react";
import DatePicker from "./Date";
import { Close, ArrowRight } from "../../assets/Icons";
import { Calendar } from "../../assets/Icons/IconSVG";
import { differenceInDays, subDays } from "date-fns";
import { formatDateSelector } from "../../appCommon";

interface Filter {
  isOpen: boolean;
  onClose: () => void;
  onTimeValue: (value: string) => void;
  minPickDay: boolean;
  initialStartDate?: string;
  initialEndDate?: string;
}

const DateTimePicker: React.FC<Filter> = ({
  minPickDay,
  isOpen,
  onClose,
  onTimeValue,
  initialStartDate,
  initialEndDate,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [startDate, setStartDate] = useState<string>(initialStartDate || "");
  const [endDate, setEndDate] = useState<string>(initialEndDate || "");
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isNextComponent, setIsNextComponent] = useState<boolean>(true);
  const [isFocusedFrom, setIsFocusedFrom] = useState<boolean>(false);
  const [isFocusedTo, setIsFocusedTo] = useState<boolean>(false);
  const [errorsText, setErrorsText] = useState<boolean>(false);
  const inputFromRef = useRef<HTMLInputElement>(null);
  const inputToRef = useRef<HTMLInputElement>(null);
  const [isDateVisible, setIsDateVisible] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      setShowModal(true);
      setTimeout(() => inputFromRef.current?.focus(), 300);
      
      if (!initialStartDate && !startDate) {
        const defaultStart = subDays(new Date(), 4);
        setStartDate(defaultStart.toString());
      }
    } else {
      setTimeout(() => setShowModal(false), 300);
    }
  }, [isOpen]);

  useEffect(() => {
    if (startDate && endDate) {
      validateDates(endDate);
    }
  }, [startDate, endDate]);

  if (!showModal && !isOpen) return null;

  const handleStart = (value: string) => {
    setStartDate(value);
  };

  const validateDates = (endDateValue: string) => {
    const fromDate = new Date(startDate);
    const toDate = new Date(endDateValue);
    
    const diffDays = differenceInDays(toDate, fromDate);
    if (minPickDay) {
      if (diffDays >= 4 && diffDays <= 29) {
        setErrorsText(false);
        setIsDateVisible(true);
        setIsDisabled(false);
        
        return true;
      } else {
        setErrorsText(true);
        setIsDateVisible(false);

        return false;
      }
    }
    return true;
  };

  const handleEnd = (value: string) => {
    setEndDate(value);
  };

  const handleSearch = () => {
    if (endDate.trim() === "" || errorsText) {
      setIsDisabled(true);
      return;
    }
    
    const selectTime: string = `${formatDateSelector(startDate)}-${formatDateSelector(endDate)}`;
    onTimeValue(selectTime);
    setIsNextComponent(true);
    setIsDisabled(false);
    onClose();
  };


  return (
    <div
      className={`fixed inset-0 z-50 w-full h-screen flex items-end justify-center bg-opacity-50 bg-[#A2A0A8] transition-opacity duration-300 ${
        isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className={`py-[24px] px-[20px] w-screen bg-white rounded rounded-tl-[32px] rounded-tr-[32px] transform transition-transform duration-300 ease-out ${
          isOpen ? "animate-slideUp" : "animate-slideDown"
        }`}
      >
        <div className="relative mb-[24px]">
          <p className="px-[24px] text-xl font-extrabold text-center">
            Lọc kết quả
          </p>
          <button className="absolute top-1 right-0" onClick={onClose}>
            <Close />
          </button>
        </div>

        <div className="mb-[24px] flex items-center justify-between gap-2">
          <div className="relative">
            <div className="absolute top-3.5 left-3 w-fit">
              <Calendar isActive={isFocusedFrom || startDate !== ""} />
            </div>
            <input
              type="text"
              ref={inputFromRef}
              placeholder="Thời gian"
              value={formatDateSelector(startDate)}
              onFocus={() => {
                setIsFocusedFrom(true);
                setIsNextComponent(true);
              }}
              onBlur={() => setIsFocusedFrom(false)}
              readOnly
              className="w-[150px] p-3 input-calendar rounded-2xl bg-green200 pl-8 focus:outline-none focus:ring-2 focus:ring-green500"
            />
          </div>
          <div>
            <ArrowRight />
          </div>
          <div className="relative">
            <div className="absolute top-3.5 left-3">
              <Calendar isActive={isFocusedTo || endDate !== ""} />
            </div>
            <input
              type="text"
              ref={inputToRef}
              placeholder="Thời gian"
              value={formatDateSelector(endDate)}
              onFocus={() => {
                setIsFocusedTo(true);
                setIsNextComponent(false);
              }}
              onBlur={() => setIsFocusedTo(false)}
              readOnly
              className={`p-3 input-calendar rounded-2xl bg-green200 pl-8 ${
                errorsText
                  ? "focus:outline-1 focus:outline-[#DC0105]"
                  : "focus:outline-1 focus:outline-green500"
              } w-[150px]`}
            />
          </div>
        </div>

        {errorsText && (
          <p className="text-[#DC0105]">
            Vui lòng chọn ngày kết thúc lớn hơn ngày bắt đầu tối thiểu là 5 ngày
            và tối đa 30 ngày
          </p>
        )}
        
        {isDateVisible && (
          <p>
            Từ {formatDateSelector(startDate)} đến {formatDateSelector(endDate)}
          </p>
        )}

        <div className="mb-[24px]">
          <p className="text-lg font-extrabold">Chọn ngày giờ</p>
        </div>

        <div>
          {isNextComponent ? (
            <DatePicker
              isFinal={isNextComponent}
              isDisabled={false}
              onPrev={onClose}
              onValue={handleStart}
              onNext={() => setIsNextComponent(false)}
              isFromDate={true}
              defaultValue={startDate ? new Date(startDate) : undefined}
            />
          ) : (
            <DatePicker
              isDisabled={isDisabled}
              isFinal={isNextComponent}
              onPrev={() => setIsNextComponent(true)}
              onValue={handleEnd}
              onSearch={handleSearch}
              defaultValue={endDate ? new Date(endDate) : undefined}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DateTimePicker;