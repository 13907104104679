import { GetListActionTypes, ListCustomerRes } from "../../../types/Customer";
import {
  GET_LIST_CUSTOMER,
  GET_LIST_CUSTOMER_DETAIL,
  GET_LIST_CUSTOMER_FAIL,
  GET_LIST_CUSTOMER_RESET,
  GET_LIST_CUSTOMER_SUCCESS,
  GET_LIST_CUSTOMER_SUCCESS_DETAIL,
  GET_LIST_STATEMENT_CUSTOMER,
  GET_LIST_STATEMENT_CUSTOMER_NEWEST,
  GET_LIST_STATEMENT_CUSTOMER_NEWEST_SUCCESS,
  GET_LIST_STATEMENT_CUSTOMER_SUCCESS,
  UPDATE_LIST_CUSTOMER,
} from "../../actions/CustomerAction/CustomerAction";

interface CustomerState {
  dataListCustomer: ListCustomerRes;
  dataListCustomerDetail: ListCustomerRes;
  loading: boolean;
  listDataCustomerUpdate: any;
  listStatemenCustomer: any;
  listStatementCustomerOnDetail: any
  selectorDate: string;
}

const initialState: CustomerState = {
  dataListCustomer: {
    result: [],
    size: 0,
  },
  dataListCustomerDetail: {
    result: [],
    size: 0,
  },
  loading: true,
  listDataCustomerUpdate: [],
  listStatemenCustomer: [],
  listStatementCustomerOnDetail: [],
  selectorDate: ""
};

const CustomerReducer = (
  state = initialState,
  action: GetListActionTypes
): CustomerState => {
  switch (action.type) {
    
    case GET_LIST_CUSTOMER_DETAIL:
    case GET_LIST_CUSTOMER:  
    case GET_LIST_STATEMENT_CUSTOMER:
    case GET_LIST_STATEMENT_CUSTOMER_NEWEST: 
      return {
        ...state,
        loading: true,
      }
    case GET_LIST_CUSTOMER_SUCCESS:
      return {
        ...state,
        dataListCustomer: {
          result: 
            action.isNewPage ? [...state.dataListCustomer?.result, ...action.payload?.result] : action.payload?.result,
          size: action.payload.size,
        },
        loading: false
      };
    case GET_LIST_CUSTOMER_SUCCESS_DETAIL:
      return {
        ...state,
        dataListCustomerDetail: {
          result: action.payload?.result,
          size: action.payload.size,
        },
        loading: false
      };
    case GET_LIST_STATEMENT_CUSTOMER_SUCCESS: 
      return {
        ...state,
        selectorDate: action?.selectDate,
        listStatemenCustomer: state?.selectorDate !== action?.selectDate 
          ? action?.payload
          : [...state.listStatemenCustomer, ...action?.payload],
        loading: false,
        listStatementCustomerOnDetail: action.payload
      }
    case GET_LIST_STATEMENT_CUSTOMER_NEWEST_SUCCESS: 
      return {
        ...state,
        listStatemenCustomer: action?.payload,
        loading: false
      }
    case UPDATE_LIST_CUSTOMER:
      return {
        ...state,
        listDataCustomerUpdate: action?.payload
      }
    case GET_LIST_CUSTOMER_RESET:
      return initialState;
    case GET_LIST_CUSTOMER_FAIL:
      return {
        ...state,
        dataListCustomer: {
          result: [],
          size: 0,
        },
        loading: false
      };
    default:
      return state;
  }
};

export default CustomerReducer;
