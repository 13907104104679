import { generateRefNo } from "../appCommon";
import api from "./AxiosInterceptor";
const config = require("../appConfig");
const patchApi = config.API_ENPOINT + "/merchant/v1.0";

export const IntroInfomaitionApi = async () => {  
    const refNo = generateRefNo();
    const url = patchApi + "/intro-info";
    const response = await api.get(url, { params: { refNo } });       

    return response.data; 

}