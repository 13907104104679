import { takeEvery, call, put } from "redux-saga/effects";
import { setLoading } from "../../actions/LoadingAction";
import {
  GET_REPORT_CUSTOMER,
  getCustomerReportFail,
  getCustomerReportSuccess,
} from "../../actions/CustomerAction/CustomerReportAction";
import { getReportCustomer } from "../../../api/Customer";
import {
  CustomerReportActionReducer,
  ResponseCustomerReportData,
} from "../../../types/Customer";

function* fetchCustomerReportGroup(action: CustomerReportActionReducer) {
  try {
    yield put(setLoading(true));
    const res: ResponseCustomerReportData = yield call(
      getReportCustomer,
      action.payload
    );
    yield put(getCustomerReportSuccess(res?.data));
  } catch (error: any) {
    yield put(getCustomerReportFail(error));
    throw error;
  } finally {
    yield put(setLoading(false));
  }
}

export default function* CustomerReportSaga() {
  yield takeEvery(GET_REPORT_CUSTOMER, fetchCustomerReportGroup);
}
