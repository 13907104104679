import React, { useEffect, useState } from "react";
import { Close } from "../../assets/Icons";

interface Filter {
  isOpen: boolean;
  onClose: () => void;
  onComplete: (option: Option | null) => void;
}

interface Option {
  id: string;
  label: string;
}

const ProfessionDropDown: React.FC<Filter> = ({ isOpen, onClose, onComplete }) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const options: Option[] = [
    { id: "4", label: "Dịch vụ giáo dục" },
    { id: "6", label: "Dịch vụ lưu trú và ăn uống" },
    { id: "25", label: "Sản xuất kinh doanh hàng tiêu dùng đồ gia dụng thiết bị văn phòng" },
    { id: "4543300", label: "Đại lý phụ tùng của moto xe máy" },
    { id: "4711100", label: "Bán lẻ trong siêu thị lớn" },
    { id: "4711200", label: "Bán lẻ trong cửa hàng tiện lợi" },
    { id: "4719100", label: "Bán lẻ trong siêu thị trung tâm thương mại" },
    { id: "4722900", label: "Bán lẻ thực phẩm khác trong các cửa hàng chuyên doanh" },
    { id: "4773900", label: "Bán lẻ hàng hóa khác" },
    { id: "4773200", label: "Bán lẻ vàng bạc đá quý đồ trang sức" },
    { id: "4771100", label: "Bán lẻ hàng may mặc" },
    { id: "4772100", label: "Bán lẻ dược phẩm dụng cụ y tế" },
    { id: "4781200", label: "Bán lẻ thực phẩm tại chỗ" },
    { id: "5610100", label: "Nhà hàng quán ăn ăn uống" },
    { id: "5610200", label: "Cửa hàng ăn uống thuộc chuỗi cửa hàng ăn nhanh" },
    { id: "5630200", label: "Quán cà phê giải khát" },
    { id: "7420000", label: "Hoạt động nhiếp ảnh" },
    { id: "7500000", label: "Hoạt động thú y" },
    { id: "7911000", label: "Đại lý du lịch" },
    { id: "8790900", label: "Hoạt động chăm sóc tập trung khác" },
    { id: "9631000", label: "Cắt tóc gội đầu" },
    { id: "9511000", label: "Sửa chữa máy vi tính và thiết bị ngoại vi" },
    { id: "9521000", label: "Sửa chữa thiết bị nghe nhìn điện tử gia dụng" },
    { id: "9200100", label: "Hoạt động xổ số" },
    { id: "9329000", label: "Hoạt động vui chơi giải trí khác" },
    { id: "4931200", label: "Vận tải hành khách bằng taxi" },
    { id: "4932100", label: "Vận tải hành khách bằng xe khách" },
    { id: "4933100", label: "Vận tải hành khách bằng oto chuyên dụng" },
    { id: "4931300", label: "Vận tải hành khách bằng xe lam, xe lôi, xe máy" },
    { id: "4933200", label: "Vận tải hành khách bằng oto loại khác" },
    { id: "4933400", label: "Vận tải hành khách bằng xe thô sơ" },
    { id: "5320000", label: "Chuyển phát nhanh" },
    { id: "5229900", label: "Khác" }
  ];

  const toggleOption = (option: Option) => {
    if (selectedOption?.id === option.id) {
      setSelectedOption(null);
    } else {
      setSelectedOption(option);
    }
  };

  const handleComplete = () => {
    onComplete(selectedOption);
    onClose();
  };

  // Vô hiệu hóa cuộn trang khi modal mở
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  return (
    <div
      className={`fixed inset-0 z-50 w-full h-screen flex items-end justify-center bg-opacity-50 bg-[#A2A0A8] transition-opacity duration-300 ${
        isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className={`py-[24px] px-[20px] w-screen bg-white rounded rounded-tl-[32px] rounded-tr-[32px] transform transition-transform duration-300 ease-out ${
          isOpen ? "animate-slideUp" : "animate-slideDown"
        }`}
      >
        <div className="relative mb-[24px]">
          <p className="px-[24px] text-xl font-extrabold text-center">Chọn ngành nghề</p>
          <button className="absolute top-1 right-0" onClick={onClose}>
            <Close />
          </button>
        </div>

        <div className="mb-6 flex items-center gap-2 flex-col py-4 h-[220px] overflow-y-auto">
          {options.map((option) => (
            <div
              key={option.id}
              className="flex items-center justify-between w-full border-gray100 border-b cursor-pointer"
              onClick={() => toggleOption(option)}
            >
              <div className="text-base font-semibold pb-4 leading-6">{option.label}</div>
              {selectedOption?.id === option.id && (
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                  <path
                    d="M3.37371 10.6678C3.19025 10.4614 2.87421 10.4428 2.66782 10.6263C2.46143 10.8098 2.44284 
                    11.1258 2.6263 11.3322L6.6263 15.8322C6.81743 16.0472 7.15013 16.057 7.35356 15.8536L17.8536 
                    5.35355C18.0488 5.15829 18.0488 4.84171 17.8536 4.64645C17.6583 4.45118 17.3417 4.45118 17.1465 
                    4.64645L7.02141 14.7715L3.37371 10.6678Z"
                    fill="#181C26"
                  />
                </svg>
              )}
            </div>
          ))}
        </div>

        <div className="flex item-center justify-center gap-[12px] w-full">
          <button
            type="button"
            className="py-[16px] px-[10px] bg-green200 text-green500 font-bold rounded-2xl w-full"
            onClick={onClose}
          >
            Quay lại
          </button>
          <button
            type="button"
            className="py-[16px] px-[10px] bg-green500 text-white font-bold rounded-2xl w-full"
            onClick={handleComplete}
          >
            Hoàn thành
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfessionDropDown;
