import React from 'react';
import { NoDataFoundImageReport } from '../../assets/Images';

const NoDataFoundReport: React.FC = () => {
  return (
    <div className='not-italic flex flex-col justify-center items-center w-full'>
      <img className='w-44 h-40' src={NoDataFoundImageReport} alt="" />
      <p className='leading-6 text-base text-gray500 font-medium'>Chưa có dữ liệu để hiển thị</p>
    </div>
  );
};

export default NoDataFoundReport;
