 const { ENVIRONMENT } = require("./appConstants");

module.exports = Object.freeze({
  ROOT_PATH: process.env.REACT_APP_API_APPLICATION_PATH,
  API_ENPOINT: process.env.REACT_APP_API_API_ENPOINT,
  PILOT_LIVE: {
    IS_ACTIVE: process.env.REACT_APP_PILOT_LIVE === "true",
    FEATURE_FLAG: process.env.REACT_APP_PILOT_FEATURE_FLAG || "",
  },
  ENVIRONMENT: process.env.REACT_APP_ENV || ENVIRONMENT.DEVELOPMENT,
});
