import { takeEvery, call, put } from 'redux-saga/effects';
import { RESPONSE_STAUS_CODE } from '../../../appConstants';
import { Response } from '../../../types/OnboardTypes';
import { 
    GET_INTRO_INFORMATION, 
    getIntroInformationFail, 
    getIntroInformationSuccess 
} from '../../actions/IntroInfomaitionActions/IntroInformation';
import { IntroInfomaitionApi } from '../../../api/IntroInformationApi';

function* IntroInformation() { 
    try {
        const res:Response = yield call(IntroInfomaitionApi);

        if (res?.status === RESPONSE_STAUS_CODE.SUCCESS.name && res?.data) {
            yield put(getIntroInformationSuccess(res?.data));
        } else {
            yield put(getIntroInformationFail(res?.error));
        }
    } catch (error) {
        throw error;
    }
}

export default function* IntroInformationSaga() {
    yield takeEvery(GET_INTRO_INFORMATION, IntroInformation);
}
