import { IntroInformationData } from "../../../types/IntroInformationType";
import { 
    GET_INTRO_INFORMATION, 
    GET_INTRO_INFORMATION_FAIL, 
    GET_INTRO_INFORMATION_SUCCESS 
} from "../../actions/IntroInfomaitionActions/IntroInformation";
  
  interface ListGroupState {
    data: IntroInformationData;
    loading: boolean
  }
  
  const initialState: ListGroupState = {
    data: {
        merchantRevenue: 0,
        merchantExpense: 0,
        merchantProfit: 0,
        merchantOrderNbr: 0,
        merchantCustomerNbr: 0
    },
    loading: true
  };
  
  const IntroInformationReducer = (state = initialState,action: any): ListGroupState => {
    switch (action.type) {
      case GET_INTRO_INFORMATION:
        return {
          ...state,
        }
      case GET_INTRO_INFORMATION_SUCCESS:
        return {
          ...state,
          data: action?.payload,
          loading: false
        };
      case GET_INTRO_INFORMATION_FAIL:
        return {
          ...state,
        };
      default:
        return state;
    }
  };
  
  export default IntroInformationReducer;
  