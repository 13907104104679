import React, { useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "../../components/Tabs/Tabs";
import Footer from "../../components/Footer/Footer";
import TransactionPageComponent from "../../components/TransactionPage/TransactionPage";
import StatementPageComponent from "../../components/StatementPageComponent/StatementPageComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { LeftBlack } from "../../assets/Icons";
import { saveTimeSelector } from "../../redux/actions/ListTransactionActions/ListTransaction";
import { TODAY } from "../../appConstants";
import { useDispatch } from "react-redux";

const TransactionPage: React.FC = () => {
  const location = useLocation();
  const {
    isHidden, 
    contractBank, 
    contractAccountNbr, 
    contractCustomerName
  } = location?.state || {};
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const scrollContainerRef = useRef(null);
  const previousTabValue = useRef(tabValue);

  useEffect(() => {
    if (tabValue !== previousTabValue.current) {
      dispatch(saveTimeSelector(TODAY));
      previousTabValue.current = tabValue;
    }
  }, [tabValue, dispatch]);
  
  return (
    <div className="fixed inset-0 flex flex-col">
      {isHidden && (
        <div
          className="sticky top-0 z-20 px-2 flex items-center w-full bg-white" 
          onClick={() => {
            navigate("/detail-customer");
          }}
        >
          <span className="mr-2"> 
            <LeftBlack />
          </span>
          <span className="text-black text-lg font-extrabold leading-7 flex-1 text-center py-4">
            Chi tiết giao dịch
          </span>
        </div>
      )}

      {/* Tabs Container with Scrollable Content */}
      <div 
        className="flex-1 overflow-auto"
        ref={scrollContainerRef} 
      >
        <Tabs setTabValue={setTabValue}>
          <Tab label="Giao dịch">
            <div className="">
              <TransactionPageComponent 
                contractBank={contractBank} 
                contractAccountNbr={contractAccountNbr} 
                contractCustomerName={contractCustomerName}
                isHidden={isHidden}
                scrollContainerRef={scrollContainerRef}
              />
            </div>
          </Tab>
          <Tab label="Sao kê thông minh">
            <div className="">
              <StatementPageComponent 
                contractBank={contractBank} 
                contractAccountNbr={contractAccountNbr} 
                contractCustomerName={contractCustomerName}
                isHidden={isHidden}
                scrollContainerRef={scrollContainerRef}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
      
      {/* Fixed Footer */}
      <div className="sticky bottom-0 w-full px-3 pt-3 bg-white z-20">
        <Footer />
      </div>
    </div>
  );
};

export default TransactionPage;
