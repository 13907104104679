import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "../../components/Tabs/Tabs";
import Customer from "../../components/Customer/Customer";
import GroupCustomer from "../../components/GroupCustomer/GroupCustomer";
import Footer from "../../components/Footer/Footer";

const CustomerManagement: React.FC = () => {

  return (
    <>
      <div className="p-3">
        <Tabs>
          <Tab label="Khách hàng">
            <Customer/>
          </Tab>
          <Tab label="Nhóm khách hàng">
            <GroupCustomer />
          </Tab>
        </Tabs>
      </div>
      <Footer />
    </>
  );
};

export default CustomerManagement;
