import React, { useState, useEffect } from "react";
import InfiniteScrollComponent from "react-infinite-scroll-component";
import MappingBankLogo from "../Mapping/MappingBankLogo/MappingBankLogo";
import { ArrowGreen, ArrowRed, UpRight } from "../../assets/Icons";
import { convertNumber, formatTime, formatTransactionTime, truncateNote } from "../../appCommon";
import { getBankTitle } from "../Mapping/MappingBankName/MappingBankName";
import { NOT_AVAILABLE, VALUE_UNKNOWN } from "../../appConstants";
import { useLocation } from "react-router-dom";

interface DataStatements {
    amount: number;
    contraAccountNbr: string
    contractBank: string
    contractCustomerName: string
    currency: string
    nickname: string
    note: string
    sign: string
    statementId: string
    txnTime: string;
    nickName?: string;
    sdt?: string
    phone?: string;
    entityExtraction?: any
}

type Props = {
  listData: DataStatements[];
};

const InfinteScrollStatement: React.FC<Props> = ({ listData }) => {
  const location = useLocation();
  const { isHidden } = location?.state || {};

  return (
    <InfiniteScrollComponent
      dataLength={listData.length}
      next={() => {}} 
      hasMore={false} 
      loader={""}
    >
        {listData.map((item: DataStatements) => (
             <div className="rounded-2xl border border-[#F0F1F5] mb-4 px-4 w-full">
             <div className="w-full flex items-center border-b border-gray100 py-4">
               <div className="">
                 <p className="text-base font-bold text-nowrap">
                  {item?.contractCustomerName ? item?.contractCustomerName : VALUE_UNKNOWN}
                </p>
                 <p className="flex justify-start items-center text-gray text-nowrap gap-1">
                   {item?.sign === "D" ? <ArrowRed /> : <ArrowGreen />}
                   STK:{item?.contraAccountNbr ? item?.contraAccountNbr : VALUE_UNKNOWN}
                 </p>
               </div>
               <div className="flex-1 text-right" id="transaction-detail-time">
                 <span className="flex justify-end items-center text-xs text-green500 font-semibold">
                   <UpRight />
                 </span>
                 <span className="text-xs text-[#A2A0A8]">
                   {formatTime(item?.txnTime)}
                 </span>
               </div>
             </div>
             <div className="border-b border-[#F0F1F5] pt-4">
               <div className="mb-4 flex justify-between items-center text-sm">
                 <p className="text-gray font-medium leading-5">Ngân hàng</p>
                 <p className="flex justify-center items-center gap-[6px] font-semibold">
                   <MappingBankLogo
                     bankCode={item?.contractBank ?? "ic_bank_default"}
                     className="w-6"
                   />
                   {getBankTitle(item?.contractBank, "vi")}
                 </p>
               </div>
               <div className="mb-4 flex justify-between items-center text-sm">
                 <p className="text-gray font-medium leading-5">STK</p>
                 <p className=" font-semibold">{item?.contraAccountNbr}</p>
               </div>
               {!isHidden && (
                <div className="mb-4 flex justify-between items-center text-sm">
                  <p className="text-gray font-medium leading-5">Tên khác</p>
                  <p className=" font-semibold">
                      {item?.entityExtraction?.name[0] ? item?.entityExtraction?.name[0] : NOT_AVAILABLE}
                  </p>
                </div>
               )}
               <div className="mb-4 flex justify-between items-start text-sm">
                 <p className="text-gray font-medium leading-5">Nội Dung</p>
                 <p className="max-w-[200px] line-clamp-2 text-end font-semibold">
                   {truncateNote(item?.note)}
                 </p>
               </div>
               <div className="mb-4 flex justify-between items-start text-sm">
                 <p className="text-gray font-medium leading-5">Mã GD</p>
                 <p className="max-w-[200px] line-clamp-2 text-end font-semibold">
                   {item?.statementId}
                 </p>
               </div>
             </div>
             <div
               className="flex justify-between items-center my-4 font-semibold"
               id="statement-phone-amount"
             >
              {!isHidden && (
               <p className="text-start">
                  SĐT: {item?.entityExtraction?.phone[0] ? item?.entityExtraction?.phone[0] : NOT_AVAILABLE}
                </p>
              )}
               <div className={`${!isHidden ? 'text-end' : 'w-full text-end'} font-semibold`}>
                 {item?.sign === "D" ? "-" : "+"} {convertNumber(item?.amount)}đ
               </div>
             </div>
           </div>
        ))}
    </InfiniteScrollComponent>
  );
};

export default InfinteScrollStatement;
