import React from 'react';
import { CUSTOM_BAR_CHART } from '../../../appConstants';

const CustomLegend: React.FC<any> = ({ payload }) => {
  return (
    <div className='flex ml-16'>
      {payload?.map((entry: any, index: number) => (
        <div key={`item-${index}`} className='flex'>
          <span className='mr-6 flex items-center text-base leading-6 font-medium'>
            <div 
              className='rounded-full p-2 mr-3'
              style={{ backgroundColor: entry.color }}
            />
            {CUSTOM_BAR_CHART.find((item) => item.code === entry.dataKey)?.name}
          </span>
        </div>
      ))}
    </div>
  );
};

export default CustomLegend;
