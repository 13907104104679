import {
  CustomerReport,
  CustomerReportPayload,
  FailRespone,
} from "../../../types/Customer";

export const GET_REPORT_CUSTOMER = "GET_REPORT_CUSTOMER";
export const GET_REPORT_CUSTOMER_SUCCESS = "GET_REPORT_CUSTOMER_SUCCESS";
export const GET_REPORT_CUSTOMER_FAIL = "GET_REPORT_CUSTOMER_FAIL";

export const getCustomerReport = (payload: CustomerReport) => {
  return {
    type: GET_REPORT_CUSTOMER,
    payload,
  };
};
export const getCustomerReportSuccess = (payload: CustomerReportPayload) => {
  return {
    type: GET_REPORT_CUSTOMER_SUCCESS,
    payload,
  };
};
export const getCustomerReportFail = (payload: FailRespone) => {
  return {
    type: GET_REPORT_CUSTOMER_FAIL,
    payload,
  };
};
