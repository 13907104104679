import React from 'react';
import { convertNumber } from '../../../appCommon';
import { format } from 'date-fns';

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
  isTabRevenue?: boolean;
  isMonth?: boolean
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, isTabRevenue, isMonth }) => {
  const dataTooltip = payload?.[0]?.payload || {};
  const dayName = 
    [ "CN", "Th.2", "Th.3", "Th.4", "Th.5", "Th.6", "Th.7"][new Date(dataTooltip?.txnDate).getDay()]; // lấy thứ
  
  if (active && payload && payload?.length) {
    return (
      <div className="custom-tooltip bg-black rounded-xl p-3" style={{ zIndex: 999 }}>
        <p className='text-xs text-gray100 font-medium leading-4.5 text-center'>
          {isMonth 
            ? `T ${format(new Date(dataTooltip?.txnDate), 'MM/yyyy')}`
            : `${dayName} ${format(new Date(dataTooltip?.txnDate), 'dd/MM/yyyy')}`
          }
        </p>
        <p className='text-base text-white font-bold leading-6'>
          {isTabRevenue 
            ? `${convertNumber(dataTooltip?.profitAmount)}đ` 
            : `${convertNumber(dataTooltip?.totalTransaction)} đơn hàng`
          }
        </p>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
