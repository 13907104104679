import { generateRefNo } from "../appCommon";
import { SearchParams } from "../types/Customer";
import api from "./AxiosInterceptor";
const config = require("../appConfig");
const patchApi = config.API_ENPOINT + "/customer/v1.0";
const patchApiGroup = config.API_ENPOINT;

export const getListCustomer = async (searchObject: SearchParams) => {
  const refNo = generateRefNo();
  const searchObjectRefNo = { ...searchObject, refNo }; 

  const url: string = patchApi + "/list-customer";
  const response = await api.get(url, { params: searchObjectRefNo });

  return response.data;
};

export const getListGroup = async () => {
  const refNo = generateRefNo();

  const url: string = patchApiGroup + "/group/v1.0/list-groups";
  const response = await api.get(url, {params: {refNo}});

  return response.data;
};

export const getInformationCustomer = async (searchObject: object) => {
  const refNo = generateRefNo();
  const searchObjectRefNo = { ...searchObject, refNo }; 

  const url: string = patchApi + "/list-customer";
  const response = await api.get(url, { params: searchObjectRefNo });

  return response.data;
};

export const getReportCustomer = async (searchObject: object) => {
  const refNo = generateRefNo();
  const searchObjectRefNo = { ...searchObject, refNo }; 

  const url: string = patchApi + "/income-report";
  const response = await api.get(url, { params: searchObjectRefNo });

  return response.data;
};

export const getListStatementOfCustomer = async (searchObject: SearchParams) => {
  const refNo = generateRefNo();
  const searchObjectRefNo = { ...searchObject, refNo }; 
  
  const url: string = patchApi + "/statements";
  const response = await api.get(url, { params: searchObjectRefNo });

  return response.data;
};

export const getListStatementOfCustomerNewest = async (searchObject: SearchParams) => {
  const refNo = generateRefNo();
  const searchObjectRefNo = { ...searchObject, refNo }; 

  const url: string = patchApiGroup + "/statement/v1.0/customer/newest-statements";
  const response = await api.get(url, { params: searchObjectRefNo });

  return response.data;
};
